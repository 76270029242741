import React, { Component, useState, useEffect } from "react";
import { NavLink, Redirect, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Container,
  Divider,
  Dropdown,
  Header,
  Icon,
  Image,
  Menu,
} from "semantic-ui-react";
import { logout } from "./../../actions/action_auth";
import "./nav.css";
import { useLocation } from "react-router-dom";
import HireLogo from "../../../src/new-logo.svg";
// import { withRouter } from "./withRouter";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import { getAllClient } from "../../actions/action_client";
import MenuIcon from "../../images/svg/menu-chat-icon.svg";
// import { Navigate } from "react-router-dom";
// import { useNavigation } from '@react-navigation/native';
import AnalyticsImg from "./analytics.svg";
import JobImg from "./job-img.svg";
import TalentPoolImg from "./talentpool.svg";
import InterviewImg from "./interview-img.svg";
import MeImage from "./me.svg";
import MeImageWhite from "./me-boreder.svg";
import InterviewImgWhite from "./interview-border.svg";
import AnalyticsImgWhite from "./analytics-border.svg";
import JobImgWhite from "./job-border.svg";
import TalentPoolImgWhite from "./talent-pool-border.svg";
import NeedHelp from "./NeedHelp";
import MarketIntel from "./market-intel.svg"
import MarketIntel2 from './market-intel2.svg'

class navBar extends Component {
  constructor() {
    super();
  }

  render() {
    const { isAuthenticated, first_name, logout, userRole, clientId } =
      this.props;
    // const navigate = useNavigate()
    // const handleLogout = ()=>{
    // logout();
    // this.props.navigation.navigate('/');
    // console.log("isAuthenticated is : ", isAuthenticated);
    // this.props.navigation.navigate('/');
    // }
    // const navigation = useNavigation();
    // const navigate = useNavigate()
    // const tempporaryLogoutFunction = ()=>{
    //   localStorage.removeItem("accessToken")
    //   navigate("/")
    // }

    console.log("isAuthenticated, logout", isAuthenticated, first_name);
    const handleLogout = () => {
      // console.log("handleLogout", handleLogout)
      this.props.history.push("/signin");
      logout();
    };
  }
}

navBar.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  first_name: PropTypes.string,
  userRole: PropTypes.string,
};

function mapStateToProps(state) {
  if (state.user) {
    return {
      isAuthenticated: state.user.user?.auth || false,
      first_name: state.user.user?.first_name || null,
      userRole: state.user.user?.role || null,
      clientId: state.user.user?.clientId || null,
    };
  }
  return {};
}

// export default connect(mapStateToProps, { logout: actions.logout })((navBar));

function NavBar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user?.user) || null;
  const { auth: isAuthenticated, role: userRole, clientId } = user;
  const Swal = require("sweetalert2");
  const handleLogout = () => {
    console.log("logout handle");
    dispatch(logout()).then(() => {
      Swal.fire({
        title: "Logout Successful",
        text: "Successfully Logged out.",
        icon: "success",
        confirmButtonColor: "#21C8AA",
        confirmButtonText: "OK",
      });
      navigate("/signin");
    });
    // navigate('/signin');
  };
  console.log(window.location.pathname, "pathname");

  // const fetchData = async (clientId) => {
  //   try {
  //     const token = localStorage.getItem("accessToken");
  //     console.log("seee", userInfo);
  //     // const clientId = userInfo.clientId;
  //     const apiUrl = `api/client/getClient/${clientId}`;
  //     const response = await fetch(apiUrl, {
  //       headers: {
  //         'Authorization': `Bearer ${token}`,
  //       },
  //     });
  //     const data = await response.json();
  //     console.log("ye dekh from adding token : ", data[0]);
  //     setClient(data[0]);
  //     return data[0];
  //     // setForm({...form, });
  //   } catch (error) {
  //     console.error(`Error fetching one client data for id :`, error);
  //     return null;
  //   }
  // };

  const userRole1 = useSelector((state) => state.user.user);
  const allClients = useSelector((state) => state.client?.allClient);
  const [client, setClient] = useState();

  useEffect(() => {
    console.log("allClients : ", userRole1);
    const neededClient = allClients.filter(
      (client) => client?._id === userRole1.clientId
    );
    console.log(userRole1?.client, "allClients :2 ", neededClient);
    setClient(neededClient[0]);
  }, [allClients]);

  const { pathname } = useLocation();
  const isClientAboutPage = pathname.endsWith("/client/about");
  console.log("isClientAboutPage", isClientAboutPage);
  const [visible, setVisible] = React.useState(false);

  console.log(userRole, "navUserRole");
  const currentPath = window.location.pathname;
  let filterCss = AnalyticsImgWhite;
  let analyticsTextColor = "white";
  const url = window.location.pathname;
  const searchString = "/job/applications/";
  if (
    currentPath === "/daily-report" ||
    currentPath === "/job-analytics" ||
    currentPath === "/admin-dashboard" ||
    currentPath === "/candidate-report" ||
    currentPath === "/hiring-report" ||
    currentPath === `/job-analytics/${clientId}`
    // currentPath === "/InterviewList"
  ) {
    filterCss = AnalyticsImg;
    analyticsTextColor = "#21c8aa";
  }

  console.log(currentPath.includes(searchString), "dskjhfgjkl;");

  // if (url.includes(searchString)) {
  //   console.log("URL includes 'job/applications/added'.");
  // } else {
  //   console.log("URL does not include 'job/applications/added'.");
  // }
  const currentPathMe = window.location.pathname;
  let filterCss2 = MeImageWhite;
  let analyticsTextColor2 = "white";
  if (
    currentPathMe === "/team-members" ||
    currentPathMe === "/add-client" ||
    currentPathMe === "/add-pod" ||
    currentPathMe === "/candidate-report" ||
    currentPathMe === "/changepassword"
    // currentPath === "/InterviewList"
  ) {
    filterCss2 = MeImage;
    analyticsTextColor2 = "#21c8aa";
  }
  console.log(currentPath, "SDadfadsfsa");
  return (
    <>
      <Menu fixed="top" style={{ background: "#003458" }}>
        <Container
          fluid
          style={{
            display: "flex !important",
            flexDirection: "row !important",
            justifyContent: "center !important",
            padding: "0% 2%",
          }}
        >
          {!isAuthenticated ? (
            <Menu.Item as="a" header>
              <Image src={HireLogo} style={{ width: "70px", height: "auto" }} />
            </Menu.Item>
          ) : (
            <NavLink to="/" style={{ display: "flex", alignItems: "center" }}>
              <Menu.Item header>
                <Image
                  src={HireLogo}
                  style={{ width: "70px", height: "auto" }}
                />
              </Menu.Item>
            </NavLink>
          )}
          {!isAuthenticated ? (
            <Menu.Menu position="right"></Menu.Menu>
          ) : (
            <Menu.Menu icon="labeled" position="right">
              {userRole === "client" ? (
                isClientAboutPage ? (
                  <></>
                ) : (
                  <>
                    <NavLink
                      to={`/job-analytics/${clientId}`}
                      style={{ color: "white" }}
                    >
                      <Menu.Item
                        style={{ color: "white" }}
                        name="Job Analytics"
                        content="Job Analytics"
                        icon={
                          <Image
                            src={filterCss}
                            style={{
                              margin: "auto",
                              height: "30px",
                              width: "30px",
                            }}
                          />
                        }
                        className="navbar-icon"
                      />
                    </NavLink>

                    <NavLink to={`/InterviewList`} style={{ color: "white" }}>
                      <Menu.Item
                        style={{ color: "white" }}
                        name="My Interviews"
                        content="My Interviews"
                        icon={
                          <Image
                            src={
                              window.location.pathname === "/InterviewList"
                                ? InterviewImg
                                : InterviewImgWhite
                            }
                            style={{
                              margin: "auto",
                              height: "30px",
                              width: "30px",
                              // filter:
                              //   window.location.pathname === "/InterviewList"
                              //     ? "none"
                              //     : "brightness(0) invert(1)",
                            }}
                          />
                        }
                        className="navbar-icon"
                      />
                    </NavLink>
                  </>
                )
              ) : userRole !== "partner" && userRole !== "partnerClient" ? (
                <div className="navbar-icon" style={{ color: "white" }}>
                  {/* <Icon
                    name="audio description"
                    className="navbar-icon"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginBottom: "20px",
                    }}
                  >
                  </Icon> */}
                  <Image
                    src={filterCss}
                    style={{
                      margin: "auto",
                      height: "30px",
                      width: "30px",
                    }}
                  />

                  <Dropdown
                    item
                    text="Analytics"
                    style={{
                      color: "white",
                      paddingTop: "0px",
                      paddingBottom: "0px",
                      color: analyticsTextColor,
                    }}
                  >
                    <Dropdown.Menu>
                      {/* <Dropdown.Item as={NavLink} to="/">
                        Dashboard
                      </Dropdown.Item> */}
                      <Dropdown.Item>
                        <NavLink to="/daily-report" style={{ color: "black" }}>
                          Ops Dashboard
                        </NavLink>
                      </Dropdown.Item>
                      <Dropdown.Item as={NavLink} to="/job-analytics">
                        Job Analytics
                      </Dropdown.Item>
                      <Dropdown.Item as={NavLink} to="/admin-dashboard">
                        Admin Dashboard
                      </Dropdown.Item>
                      <Dropdown.Item as={NavLink} to="/candidate-report">
                        Candidate Report
                      </Dropdown.Item>
                      <Dropdown.Item as={NavLink} to="/hiring-report">
                        Hiring Report
                      </Dropdown.Item>
                      {/* <Dropdown.Item as={NavLink} to="/mapping">
                      Market Intel
                    </Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ) : null}

              {userRole !== "client" &&
              userRole !== "partner" &&
              userRole !== "partnerClient" ? (
                <NavLink to="/mapping" style={{ color: "white" }}>
                <Menu.Item
                  style={{ color: "white" }}
                  name="Market Intel"
                  content="Market Intel"
                  icon={
                    <Image
                      src={
                        currentPath === "/mapping" || pathname.startsWith("/marketIntel")
                          ? MarketIntel2
                          : MarketIntel
                      }
                      style={{
                        margin: "auto",
                        height: "30px",
                        width: "30px",
                        // filter:
                        //   window.location.pathname === "/interviewList"
                        //     ? "none"
                        //     : "brightness(0) invert(1)",
                      }}
                    />
                  }
                  className="navbar-icon"
                />
              </NavLink>
              ) : null}

              {isClientAboutPage ? (
                <></>
              ) : userRole !== "partner" && userRole !== "partnerClient" ? (
                <NavLink to="/jobs" style={{ color: "white" }}>
                  <Menu.Item
                    style={{ color: "white" }}
                    name="Jobs"
                    content="Jobs"
                    // active={activeItem === "jobs"}
                    icon={
                      <Image
                        src={
                          currentPath.includes(searchString) ||
                          window.location.pathname === "/jobs"
                            ? JobImg
                            : JobImgWhite
                        }
                        style={{
                          margin: "auto",
                          height: "30px",
                          width: "30px",
                          // filter:
                          //   window.location.pathname === "/jobs"
                          //     ? "none"
                          //     : "brightness(0) invert(1)",
                        }}
                      />
                    }
                    className="navbar-icon"
                  />
                </NavLink>
              ) : null}
              {userRole !== "client" &&
              userRole !== "partner" &&
              userRole !== "partnerClient" ? (
                <NavLink to="/interviewList" style={{ color: "white" }}>
                  <Menu.Item
                    style={{ color: "white" }}
                    name="Interviews"
                    content="Interviews"
                    icon={
                      <Image
                        src={
                          window.location.pathname === "/interviewList"
                            ? InterviewImg
                            : InterviewImgWhite
                        }
                        style={{
                          margin: "auto",
                          height: "30px",
                          width: "30px",
                          // filter:
                          //   window.location.pathname === "/interviewList"
                          //     ? "none"
                          //     : "brightness(0) invert(1)",
                        }}
                      />
                    }
                    className="navbar-icon"
                  />
                </NavLink>
              ) : null}

              {userRole !== "client" &&
              userRole !== "partner" &&
              userRole !== "partnerClient" ? (
                <NavLink
                  to="/talent-pool"
                  style={{
                    color: "white",
                  }}
                >
                  <Menu.Item
                    style={{ color: "white" }}
                    name="Talent Pool"
                    content="Talent Pool"
                    icon={
                      <Image
                        style={{
                          margin: "auto",
                          height: "30px",
                          width: "30px",
                          // filter:
                          //   window.location.pathname === "/talent-pool"
                          //     ? "none"
                          //     : "brightness(0) invert(1)",
                        }}
                        src={
                          window.location.pathname === "/talent-pool"
                            ? TalentPoolImg
                            : TalentPoolImgWhite
                        }
                      />
                    }
                    className="navbar-icon"
                  />
                </NavLink>
              ) : null}

              {userRole === "partner" || userRole === "partnerClient" ? (
                <NavLink to="/partner-talentpool" style={{ color: "white" }}>
                  <Menu.Item
                    style={{ color: "white" }}
                    name="Talent Pool"
                    content="Talent Pool"
                    icon="audio description"
                    className="navbar-icon"
                  />
                </NavLink>
              ) : null}
              <div
                className="navbar-icon"
                style={{ color: "white", padding: "13px 16px" }}
              >
                {/* <Icon
                  name="audio description"
                  className="navbar-icon"
                  style={{
                    height: "20px",
                    width: "20px",
                    marginBottom: "20px",
                  }}
                /> */}
                <Image
                  src={filterCss2}
                  style={{ margin: "auto", height: "30px", width: "30px" }}
                />

                <Dropdown
                  style={{ color: "white", padding: "0px" }}
                  item
                  // simple
                  text={userRole === "Admin" ? "Admin" : user.first_name}
                >
                  <Dropdown.Menu>
                    {userRole === "Admin" ? (
                      <>
                        <Dropdown.Item as={NavLink} to="/team-members">
                          Users
                        </Dropdown.Item>
                        <Dropdown.Item as={NavLink} to="/add-client">
                          Customer & Branding
                        </Dropdown.Item>
                        {/* <Dropdown.Item as={NavLink} to="/add-client">
                          Add Client
                        </Dropdown.Item> */}
                        <Dropdown.Item as={NavLink} to="/add-pod">
                          Pods
                        </Dropdown.Item>
                        {/* <Dropdown.Item as={NavLink} to="/curation">
                          Add Scorecard Template
                        </Dropdown.Item> */}
                        
                        
                      </>
                    ) : null}
            {userRole === "Admin" &&         
                    <Dropdown.Item as={NavLink} to="/changepassword">
                      Change Password
                    </Dropdown.Item> 
                    }
                    {userRole === "client" ? (
                      <>
                        {/* <Dropdown.Divider /> */}
                        <Dropdown.Item as={NavLink} to="/dashboard">
                          Dashboard
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item as={NavLink} to="/client/settings">
                         Account Settings
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        
                          
                      </>
                    ) : null}
                    <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {userRole === "client" ? (
                <>
                  <Divider vertical />
                  <NeedHelp />
                </>
              ) : null}
              {/* <NavLink to="/inbox" style={{ marginTop: "9px", color: "white" }}>
                <Menu.Item>
                  <Icon style={{ color: "white" }} size="large">
                    <Image src={MenuIcon} />
                  </Icon>
                </Menu.Item>
              </NavLink> */}
            </Menu.Menu>
          )}
        </Container>
      </Menu>
    </>
  );
}

export default NavBar;
