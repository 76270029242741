import {
    GET_CLIENT,
    ADD_CLIENT,
    GET_COMPANY_USER,
    EDIT_CLIENT,
    DELETE_CLIENT,
    UPDATE_ALL_CLIENT
} from './../types.js'

let initialState = {
    allClient: [],
    allCompanyUser: []
}

const client = (state = initialState, action) => {

    switch (action.type) {

        case GET_COMPANY_USER:
            return {
                ...state,
                allCompanyUser: action.data
            }

        case GET_CLIENT:
            return Object.assign({}, state, { allClient: action.data });

        case ADD_CLIENT:
            return {
                ...state,
                allClient: [
                    ...state.allClient,
                    action.data
                ]
            }

        case DELETE_CLIENT:
            console.log('deleted data of client', action.data)
            return {
                ...state,
                allClient: state.allClient.filter(client => client._id !== action.data._id)
            }

        case EDIT_CLIENT:
            console.log('edited client', action.data);
            let newClienList = state.allClient.map(client => {
                if (client._id === action.data._id) {
                    return action.data
                }
                return client;
            });
            console.log('after editable client', newClienList)
            return {
                ...state,
                allClient: newClienList
            }
            case UPDATE_ALL_CLIENT:
                return {
                  ...state,
                  allClient: action.payload,
                };
        default:
            return state;
    }
}

export default client;