import {CURRENT_COMPANY,SET_BASIC_COMPANY_INFO,SET_BASIC_COMPANY_ID, GET_COMPANY_DETAIL, SET_TOTAL_NUMBERS_OF_JOBS, ADD_TEAM_MEMBER, SET_TEAM_MEMBERS,
DELETE_MEMBER,
EDIT_MEMBER
} from './../types.js'
const initialState = {
  company_basic_detail : {},
  company :{},
  members: []
};
export default function company(state = initialState, action = {}){
  switch(action.type){
    case CURRENT_COMPANY:
      return Object.assign({}, state, {company : action.data})

    case SET_BASIC_COMPANY_ID:
      return Object.assign({}, state, {company_basic_detail : action.data})

    case GET_COMPANY_DETAIL:
      return Object.assign({}, state, {company : action.data})

    case ADD_TEAM_MEMBER:
    return {
      ...state,
      members: state.members.concat([action.data])
    }

    case SET_TEAM_MEMBERS:
      return {
        ...state,
        members: action.data
      }

    case DELETE_MEMBER:
      console.log('deleted data', action.data) 
    return {
      ...state,
      members : state.members.filter(member => member._id !== action.data._id)
    }
    case EDIT_MEMBER: 
      console.log('edited member', action.data, state.members);
      let newMemberList = state.members.map(member => {
        if(member._id === action.data._id){
            return action.data
        }
        return member;
      });
      console.log('after editable member', newMemberList)
      return {
        ...state,
        members: newMemberList
      }

    default:
        return state;
  }
}
