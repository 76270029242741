import React, { useEffect, useState } from 'react'
import { Icon } from 'semantic-ui-react'
import "./sidebar.css"
import { displayCORSWarning } from 'react-pdf/dist/cjs/shared/utils'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAllClient } from '../../actions/action_client'

const Sidebar = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user.user)
  console.log("user from sidebar : ", user)
  function getLastWordFromCurrentUrl() {
    const url = window.location.href;
    const lastSlashIndex = url.lastIndexOf('/');
    const lastWord = url.substring(lastSlashIndex + 1);
    return lastWord;
}
const lastWord = getLastWordFromCurrentUrl()
const userRole = useSelector((state) => state.user.user);

const [client, setClient] = useState()
const allClients = useSelector((state) => state.client.allClient);
useEffect(() => {
  console.log("allClients : ", allClients);
  const neededClient = allClients.filter(
    (client) => client._id === userRole.clientId
  );
  setClient(neededClient[0]);
}, [allClients, userRole.clientId]);

useEffect(() => {
  dispatch(getAllClient());
  const fetchData = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const clientId = user.clientId;
      // const apiUrl = `api/client/getClient/${clientId}`;
      // const apiUrl2 = `/api/client/getClient/${clientId}`;
      // const response = await fetch(apiUrl2, {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // });
      // const data = await response.json();
      const data = allClients.filter(client => client._id===clientId)
      console.log("ye dekh : ", data);
      // setClient(data[0]);
      console.log("client mila : ", client);
      console.log("see this is the client : ", data[0])
      // if(data[0].client_name!=='')navigate('/jobs')

  //     setForm({
  //       ...form,
  //       clientName: data[0].clientName,
  //       industry: data[0].industry,
  //       logo: data[0]?.logo,
  // logoURL: data[0]?.logoURL,
  // about:data[0]?.about,
  // stage:data[0]?.stage,
  // funding: data[0]?.funding,
  // size:data[0]?.size,
  // location:data[0]?.location,
  // linkedinLink:data[0]?.linkedinLink,
  // websiteLink:data[0]?.websiteLink,
  // benefits:data[0]?.benefits,
  // founded:data[0]?.founded,
  // qrataRecommends:data[0]?.qrataRecommends,
  // otherSocials: data[0]?.otherSocials,
  // moreInformation: data[0]?.moreInformation,
  // teamMembers: data[0]?.team
  //       // client_name: data[0].clientName,
  //       // client_contact_person: data[0].contactPersonName,
  //       // country_code: data[0].countryCode,
  //       // client_contact_no: data[0].contactNo,
  //       // clientIndustry: data[0].industry,
  //       // client_person_designation: data[0].designationOfContactPerson,
  //     });
      // setPhotoURL(data[0].logo)
      // setOtherSocialsCounter(data[0].otherSocials.length)
      // setMoreInformationCounter(data[0].moreInformation.length)
      // setteamMemberCounter(data[0]?.team.length)
      // setForm({...form, });
    } catch (error) {
      // console.error(`Error fetching one client data: `, error);
      console.error(
        `Error fetching one client data for id ${user.clientId} :`,
        error
      );
    }
  };
  fetchData();
}, [user.clientId]);
  return (
    <div style={{ width: "400px", background: "#F6F6F6", height:'100vh', position:'fixed'}}>
   <div style={{ margin: "20px", backgroundColor: "white" }}>
      <div style={{ padding: "10px" }}>
        <div className='div-of-logo-and-name' style={{display:'flex'}}>
            <div className='initial-client-logo'><p className='initial-inside-logo'>{user?.first_name?.substring(0, 1)}</p></div>
            <p className='client-name-text'>{user?.first_name + " " + user?.last_name} </p>
        </div>
        <NavLink to='/client/editprofile'><div className='branding-div' style={{marginBottom:'10px', background: lastWord==='editprofile' ? "#C8EBFF": "white"}}>
            <div style={{padding:'10px', display:'flex'}}>
        <Icon className="branding-icon" name="user circle" size="big" />
           <p className='branding-div-text'>About You</p>
           </div>
        </div>
        </NavLink>

        <NavLink to='/client/passwordandsecurity'><div className='branding-div' style={{ background: lastWord==='passwordandsecurity' ? "#C8EBFF": "white"}}>
            <div style={{padding:'10px', display:'flex'}}>
        <Icon className="branding-icon" name="unlock alternate" size="big" />
           <p className='branding-div-text'>Password & Security</p>
           </div>
        </div>
        </NavLink>
        
        
      </div>
      
    </div>

   <div style={{ margin: "20px", backgroundColor: "white" }}>
      <div style={{ padding: "10px" }}>
        <div className='div-of-logo-and-name' style={{display:'flex'}}>
            <div className='initial-client-logo'><p className='initial-inside-logo'>{client?.clientName?.substring(0,1)}</p></div>
            <p className='client-name-text'>{client?.clientName}</p>
        </div>
        <NavLink to='/client/branding'><div className='branding-div' style={{ background: lastWord==='branding' ? "#C8EBFF": "white"}}>
            <div style={{padding:'10px', display:'flex'}}>
        <Icon className="branding-icon" name="id badge outline" size="big" />
           <p className='branding-div-text'>  Branding</p>
           </div>
        </div>
        </NavLink>
        
        
      </div>
      
    </div>
   
    {/* <div style={{ margin: "20px", backgroundColor: "white" }}>
      <div style={{ padding: "10px" }}>
        <p className="what-do-we-offer-text" style={{ margin: "10px" }}>
          What do we offer?
        </p>
         <div style={{ marginTop: "30px" }}>
          <div style={{ display: "flex" }}>
            <Icon className="left-icons" name="user" size="large" />
            <p className="hire-by-role-text">Hire by Role</p>
          </div>
          <p className="left-grey-text">
            With Qrata, hire a leader. Leadership is an action, not a
            position.{" "}
          </p>
        </div>
        <div style={{ marginTop: "30px" }}>
          <div style={{ display: "flex" }}>
            <Icon className="left-icons" name="users" size="large" />
            <p className="hire-by-role-text">Hire your Team</p>
          </div>
          <p className="left-grey-text">
            Create a team of top talents with us. Built remote global
            teams for over 25+ companies.
          </p>
        </div>
      </div>
      
    </div> */}
   
  </div>
  )
}

export default Sidebar