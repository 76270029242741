import { color } from "chart.js/helpers";
import React, { useState } from "react";
import {
  ModalContent,
  Button,
  Header,
  Modal,
  Grid,
  Divider,
  TextArea,
  Form,
  Icon,
  MenuItem,
} from "semantic-ui-react";
import axios from "axios";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

function NeedHelp() {
  const [open, setOpen] = React.useState(false);
  const [emailText, setEmailText] = useState("");
  const [loading, setLoading] = useState(false);

  const sendMessage = async () => {
    try {
      setLoading(true);
      await axios.post("/api/client/need-help", { message: emailText });
      console.log("Message sent successfully!");
      Swal.fire({
        title: `Thank you for reaching out`,
        text: `We have received your concerns and will support you shortly`,
        showCloseButton: true,
        confirmButtonColor: "#21C8AA",
        confirmButtonText: `Ok`,
      });
    } catch (error) {
      console.error("Error while sending message:", error);
      Swal.fire({
        title: `Need Help?`,
        text: `Some issue occurred try after some time.`,
        showCloseButton: true,
        confirmButtonColor: "#21C8AA",
        confirmButtonText: `Ok`,
      });
    } finally {
      setEmailText("");
      setOpen(false);
      setLoading(false);
    }
  };

  const user = useSelector((state) => state.user?.user);
  console.log(user, "sdlfkanjd");
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      closeIcon
      size="tiny"
      // trigger={user.role === "client" ? <p>Need Help?</p> : "help"}
      trigger={
        window.innerWidth < 768 ? (
          <MenuItem>Need Help</MenuItem>
        ) : (
          <Button
            style={{
              height: "40px",
              background: "#21c8aa",
              color: "white",
              margin: "auto",
              marginLeft: "15px",
              marginBottom: "15px",
            }}
          >
            Need Help?
          </Button>
        )
      }
      className="need-help"
    >
      <Header
        as="h1"
        style={{
          color: "#003458",
          border: "none",
          paddingBottom: "0px",
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
        }}
      >
        Need Help?
      </Header>
      <p style={{ fontSize: "18px", marginTop: "10px", marginBottom: "0px" }}>
        Our support team is here to assist you.
      </p>
      <ModalContent
        style={{
          borderBottomRightRadius: "15px",
          borderBottomLeftRadius: "15px",
        }}
      >
        <Grid divided="vertically">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Button
                basic
                style={{
                  boxShadow: "0px 0px 0px 1px #003458",
                  color: "#003458",
                  background: "#F0F4FF",
                  borderRadius: "8px",
                }}
                fluid
              >
                <Icon name="phone" />
                +91 9137016598
              </Button>
            </Grid.Column>
            <Grid.Column>
              <Button
                basic
                style={{
                  boxShadow: "0px 0px 0px 1px #003458",
                  color: "#003458",
                  background: "#F0F4FF",
                  borderRadius: "8px",
                }}
                fluid
                onClick={() => {
                  window.location.href = "mailto:customer@qrata.ai";
                }}
              >
                <Icon name="mail" />
                customer@qrata.ai
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider horizontal>Or</Divider>
        <Form>
          <label style={{ float: "left" }}>Message</label>
          <TextArea
            placeholder="Let us know how we can assist you..."
            style={{ minHeight: 100 }}
            onChange={(e) => setEmailText(e.target.value)}
          />
          <Button
            style={{ marginTop: "20px", background: "#21c8aa", color: "white" }}
            fluid
            onClick={sendMessage}
            loading={loading}
            disabled={emailText && emailText.length > 5 ? false : true}
          >
            Send Message
          </Button>
        </Form>
      </ModalContent>
    </Modal>
  );
}

export default NeedHelp;
