import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect, useSelector, useDispatch } from "react-redux";
import { Button, Form, Segment, Grid, Dropdown } from "semantic-ui-react";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import InlineError from "./../../messages/InlineError";
import { createJob, editJobByIdStart } from "./../../actions/action_job";
import {
  getAllClient,
  getAllUsers,
  getPodMember,
  getAllPodGroup,
} from "./../../actions/action_client";
import "./editor.css";
import "../../App.css";
import { roleOptionAction } from "../../actions/action_option";
import { useParams } from "react-router";

const options = [
  { value: "Software Engineer", label: "Software Engineer" },
  { value: "Web developer", label: "Web developer" },
  { value: "Product manager", label: "Product manager" },
  { value: "Data Scientist", label: "Data Scientist" },
  { value: "SEO", label: "SEO" },
];

const JobDesc = ({
  JobDescRedirect,
  company_id,
  history,
  goToJobPostingDetail,
}) => {
  const dispatch = useDispatch();
  const job_id = useParams().id;
  const [data, setData] = useState({
    job_title: null,
    job_role: {
      role_type: null,
      subCategory: null,
    },
    user_id: null,
    client_id: null,
    client_name: null,
    reqruiter_Name: null,
    reqruiter_Id: null,
    reqruiter_Email_Id: null,
    podGroup: [],
  });
  const [assignedRecruiter, setAssignedRecruiter] = useState([]);
  const [jobDescription, setJobDescription] = useState("");
  const [errors, setErrors] = useState({});
  const [isEdit, setIsEdit] = useState(true);
  const [loading, setLoading] = useState(false);
  const [podGroup, setPodGroup] = useState([]);
  const [allPodMembers, setAllPodMembers] = useState([]);

  // const company_id = useSelector(state => state.user.company_id);
  const allUsers = useSelector((state) => state.client.allCompanyUser);
  const allClients = useSelector((state) => state.client.allClient);
  const roleDropdown = useSelector((state) => state.options.roleOptions);
  const current_job = useSelector((state) => state.job.current_job);

  const user_id = useSelector((state) => state.user.id);

  // console.log("company id ",company_id, allUsers, allClients, current_job);

  let allPodGroups = [],
    allUsersOption = [],
    allClientOption = [];

  if (allClients.length > 0 && allUsers.length > 0) {
    allClients.map((client) =>
      allClientOption.push({
        id: client._id,
        value: client.clientName,
        label: client.clientName,
      })
    );

    // allUsers.map(client => allUsersOption.push({
    //   id: client._id,
    //   value: client.email,
    //   label: `${client.first_name} ${client.last_name}`
    // }));
  }

  if (allPodMembers.length > 0) {
    allPodMembers.map((client) =>
      allUsersOption.push({
        id: client._id,
        value: client.email,
        label: `${client.first_name} ${client.last_name}`,
        pod_group: client.pod_group,
      })
    );
  }

  if (podGroup.length > 0) {
    podGroup.map((client) =>
      allPodGroups.push({
        // id: client.,
        value: client,
        label: client,
      })
    );
  }

  useEffect(() => {
    if (current_job && job_id) {
      console.log("$$$$$$$$$$4", current_job.assignedRecruiter);
      //temporary if else in dev
      console.log("checking property", current_job.hasOwnProperty("client_id"));
      if (current_job.hasOwnProperty("assignedClient")) {
        setData({
          ...data,
          job_title: current_job.job_title,
          user_id: current_job.user_id,
          client_id: current_job.assignedClient.client_id,
          client_name: current_job.assignedClient.client_name,
          job_role: current_job.job_role ? current_job.job_role : data.job_role,
        });
      } else {
        setData({
          ...data,
          job_title: current_job.job_title,
          user_id: current_job.user_id,
        });
      }
      setAssignedRecruiter(current_job.assignedRecruiter);
      setJobDescription(current_job.job_description);
    }
  }, [JSON.stringify(current_job)]);

  useEffect(() => {
    setLoading(true);
    const getClientThunk = getAllClient(company_id);
    dispatch(getClientThunk);
    const getAllUsersThunk = getAllUsers(company_id);
    dispatch(getAllUsersThunk);
    const roleThunk = roleOptionAction();
    dispatch(roleThunk);

    getAllPodGroup(company_id).then((users) => {
      setPodGroup(users);
    });
    setLoading(false);
  }, [dispatch, company_id]);

  // console.log('useeffect of get user', useEffect2)

  const handleChange = (value, editor) => {
    // console.log('editorrrrr', editor, value)
    setJobDescription(value);
  };

  const onChange = ({ value }) => {
    console.log("handle job title change", value);
    setData({
      ...data,
      job_title: value,
    });
  };

  const onChangeClient = (value) => {
    // console.log('on change client ', value);
    setData({
      ...data,
      client_id: value.id,
      client_name: value.value,
    });
  };

  const onChangeUsers = (value) => {
    let data = {
      reqruiter_Email_Id: value.value,
      reqruiter_Id: value.id,
      reqruiter_Name: value.label,
    };
    setData({
      ...data,
      reqruiter_Email_Id: value.value,
      reqruiter_Id: value.id,
      reqruiter_Name: value.label,
    });
  };

  const validate = (data) => {
    const errors = {};
    console.log("validate error", data, data.job_title);
    if (!data.job_title) errors.job_title = "Job title is required";
    if (!data.job_description)
      errors.job_description = "Job description is required";
    if (data.job_description.length < 10)
      errors.job_description =
        "Job description should be at least 10 characters long.";
    if (!data.client_id) errors.assignedClient = "Client selection is required";
    if (assignedRecruiter.length < 1)
      errors.assignedRecruiter = "Recruiter selection is required";

    return errors;
  };

  const selectPodLead = (value) => {
    console.log("selectPodLead value", value);
    if (data.podGroup.length > 0) {
      data.podGroup.splice(0);
    }
    data.podGroup.push(value.value);

    console.log("selectPodLead value", data);
    getPodMember(company_id, data).then((res) => {
      if (res) {
        console.log(" **********************************", res);
        // JobDescRedirect(res.data._id);
        setAllPodMembers(res);
      }
    });
  };

  const handleSubmit = () => {
    let mergedData = {
      ...data,
      job_description: jobDescription,
      assignedRecruiter,
      user_id,
      company_id,
    };
    const errorValidate = validate(mergedData);
    console.log("err*******handleSubmit*************or", mergedData);
    // return ;
    setErrors({ ...errorValidate });

    if (Object.keys(errors).length === 0) {
      if (!job_id) {
        dispatch(createJob(mergedData)).then((res) => {
          if (res) {
            JobDescRedirect(res.data._id);
          }
        });
      } else {
        dispatch(editJobByIdStart(mergedData, current_job._id)).then((res) => {
          if (res) {
            JobDescRedirect(res.data._id);
          }
        });
      }
    }
  };

  //TEMPORARY CODE
  if (assignedRecruiter !== null) {
    if (
      allUsersOption.length > 0 &&
      assignedRecruiter.length > 0 &&
      assignedRecruiter !== null
    ) {
      if (assignedRecruiter[0].hasOwnProperty("recruiter_id")) {
        console.log(
          "working",
          allUsersOption.find(
            (recruiter) =>
              recruiter.id === current_job.assignedRecruiter[0].recruiter_id
          )
        );
        setAssignedRecruiter([
          allUsersOption.find(
            (recruiter) =>
              recruiter.id === current_job.assignedRecruiter[0].recruiter_id
          ),
        ]);
      }
    }
  }

  const titleValue = {
    id: data.job_title,
    value: data.job_title,
    label: data.job_title,
  };
  const userOptionValue = allUsersOption.filter(
    (user) => user.value === data.reqruiter_Email_Id
  );
  const clientOptionValue = allClientOption.filter(
    (client) => client.value === data.client_name
  );

  console.log("data state", data, roleDropdown);
  if (loading) {
    return "loading";
  }
  console.log("data state 2", roleDropdown.subCategories);
  return (
    <div>
      <Segment attached="top" id="headerbackground">
        <h4>Job Title</h4>
      </Segment>
      <Segment attached>
        <Form onSubmit={handleSubmit} loading={loading}>
          {/* <RawHtml.div>{data.job_description}</RawHtml.div> */}
          <Form.Field error={!!errors.job_description}>
            <label>Job title </label>
            <CreatableSelect
              options={options}
              onChange={onChange}
              value={titleValue}
            />
            {errors.job_title && <InlineError text={errors.job_title} />}
          </Form.Field>

          <Form.Field>
            <label>Role</label>
            <Grid.Column>
              <Dropdown
                placeholder="Role"
                fluid
                selection
                onChange={(e, { value }) =>
                  setData({
                    ...data,
                    job_role: {
                      ...data.job_role,
                      role_type: value,
                    },
                  })
                }
                value={data.job_role.role_type}
                options={roleDropdown.standardRole}
              />
            </Grid.Column>
            <Grid.Column>
              {roleDropdown.subCategories.length > 0 ? (
                <Dropdown
                  fluid
                  selection
                  onChange={(e, { value }) =>
                    setData({
                      ...data,
                      job_role: {
                        ...data.job_role,
                        subCategory: value,
                      },
                    })
                  }
                  value={
                    data.job_role.role_type &&
                    roleDropdown.subCategories
                      .find(
                        (category) =>
                          category.standardizedRole === data.job_role.role_type
                      )
                      .subCategories.find(
                        (item) => item === data.job_role.subCategory
                      )
                  }
                  options={
                    data.job_role.role_type
                      ? roleDropdown.subCategories
                          .find(
                            (category) =>
                              category.standardizedRole ===
                              data.job_role.role_type
                          )
                          .subCategories.map((item) => ({
                            key: item,
                            text: item,
                            value: item,
                          }))
                      : []
                  }
                />
              ) : null}
              {/* {errors.role && <InlineError text={errors.role} />} */}
            </Grid.Column>
          </Form.Field>

          <Form.Field error={!!errors.job_description}>
            <label> Select client Name </label>
            {/* <SelectClient  selectData={newOptions}/> */}
            <Select
              options={allClientOption}
              onChange={onChangeClient}
              value={clientOptionValue}
            />
          </Form.Field>
          {errors.assignedClient && (
            <InlineError text={errors.assignedClient} />
          )}

          <Form.Field error={!errors.job_description}>
            <label>Assign To Pod : </label>

            <Select
              options={allPodGroups}
              value={data.pod_role}
              // onChange={(selectedOptions) =>setAssignedRecruiter(selectedOptions)}
              onChange={(value) => selectPodLead(value)}
              // value={data.podGroup.value}
              // isMulti
            />
          </Form.Field>
          {errors.assignedRecruiter && (
            <InlineError text={errors.assignedRecruiter} />
          )}

          <Form.Field error={!errors.job_description}>
            <label>Assign To Pod Member : </label>

            <Select
              options={allUsersOption}
              onChange={(selectedOptions) => {
                console.log("****", selectedOptions);
                setAssignedRecruiter(selectedOptions);
              }}
              value={assignedRecruiter}
              isMulti
            />
          </Form.Field>
          {errors.assignedRecruiter && (
            <InlineError text={errors.assignedRecruiter} />
          )}

          {/* <Form.Field error={!errors.job_description}>
            <label>Assign To Recruiter : </label>

            <Select
              options={allUsersOption}
              onChange={(selectedOptions) =>setAssignedRecruiter(selectedOptions)}
              value={assignedRecruiter}
              isMulti
            />

          </Form.Field>
          {errors.assignedRecruiter && <InlineError text={errors.assignedRecruiter} />} */}

          <Form.Field error={!!errors.job_description}>
            <label>Job Description</label>
            <ReactQuill
              value={jobDescription}
              onChange={handleChange}
              style={{ backgroundColor: "white" }}
            />
            {errors.job_description && (
              <InlineError text={errors.job_description} />
            )}
          </Form.Field>
          <div style={{ textAlign: "end" }}>
            <Button.Group>
              <Button type="submit" positive style={{ background: "#21c8aa" }}>
                Save and continue
              </Button>
              {current_job && Object.keys(current_job).length > 0 && (
                <Button type="submit" default>
                  Next
                </Button>
              )}
            </Button.Group>
          </div>
        </Form>
      </Segment>
    </div>
  );
};

// class JobDesc extends Component {

//   constructor(props) {
//     super(props)
//     this.state = {
//       data: {},
//       option: [],
//       allUsers: [],
//       errors: {},
//       loading: false,
//       multi: false,
//       multiValue: [],

//       value: undefined
//     }
//     this.handleChange = this.handleChange.bind(this)
//     this.onChange = this.onChange.bind(this)
//     this.onChangeClient = this.onChangeClient.bind(this)
//     this.onChangeUsers = this.onChangeUsers.bind(this)
//   }

//   handleChange(html) {
//     this.setState({ data: { ...this.state.data, job_description: html, user_id: this.props.user_id, company_id: this.props.company_id } });
//   }

//   onChange(value) {

//     console.log("hello");
//     //  console.log("PPPPPPPPPPPPPPPPPPPP CHANGE ",value);
//     if (value) {
//       //  console.log("OOOOOOOOOOOOOOOOOOON CHANGE ",value);
//       var something = value.value
//       const { multi } = this.state;
//       if (multi) {
//         this.setState({ multiValue: value });
//       } else {
//         this.setState({ data: { ...this.state.data, job_title: something, user_id: this.props.user_id, company_id: this.props.company_id } });
//       }
//     }

//     // console.log("data",this.state.data);
//   }

//   onChangeClient(value) {

//     console.log("value", value.id);
//     if (value) {
//       var something = value.value;
//       console.log(something, "00000000000", value);
//       // const { multi } = this.state;
//       // if (multi) {
//       // this.setState({ multiValue: value });
//       // } else {
//       this.setState({ data: { ...this.state.data, client_id: value.id, client_name: value.label } });
//       // }
//     }
//   }

//   onChangeUsers(value) {
//     console.log("value", value.id);
//     if (value) {
//       var something = value.value;
//       console.log(something, "00000000000", value);
//       // const { multi } = this.state;
//       // if (multi) {
//       // this.setState({ multiValue: value });
//       // } else {
//       this.setState({ data: { ...this.state.data, reqruiter_Id: value.id, reqruiter_Name: value.value, reqruiter_Email_Id: value.value } });
//       // }
//     }
//   }

//   handleSubmit = (data) => {
//     const errors = this.validate(this.state.data);
//     this.setState({ errors });

//     if (Object.keys(errors).length === 0) {
//       if (!this.props.jobid) {
//         this.props.createJob(this.state.data).then((res) => {
//           if (res) {
//             this.props.JobDescRedirect(res.data._id);
//           }
//         })
//       } else {
//         this.props.editJobByIdStart(this.state.data, this.props.jobid).then((res) => {
//           if (res) {
//             this.props.JobDescRedirect(res.data._id);
//           }
//         })
//       }

//     }
//   }

//   validate = data => {
//     const errors = {}

//     if (!data.job_title) errors.job_title = "Job title is required"
//     if (!data.job_description) errors.job_description = "Job description is required"
//     if (data.job_description.length < 10) errors.job_description = "Job description should be at least 10 characters long."
//     if (data.job_description.length > 5000) errors.job_description = "Job description is too long"

//     return errors;
//   }

//   // componentDidMount(props){
//   //   const {current_job} = this.props.current_job
//   //
//   //   if(!current_job) return null;
//   //
//   //   if(Object.keys(current_job).length > 0){
//   //     if(this.state.data.job_title !== this.props.current_job.job_title){
//   //       this.setState({data: {...this.state.data, job_title: current_job.job_title, job_description:current_job.job_description}})
//   //     }
//   //   }
//   // }

//   componentWillReceiveProps(nextProps) {
//     console.log("will recierve props state", nextProps);
//     if (nextProps.user_id) {
//       if (this.state.data.user_id !== nextProps.user_id) {
//         this.setState({ data: { ...this.state.data, user_id: nextProps.user_id } })
//       }
//     }

//     if (nextProps.company_id) {
//       if (this.state.data.company_id !== nextProps.company_id) {
//         this.setState({ data: { ...this.state.data, user_id: nextProps.company_id } })
//       }
//       // console.log("next props mounted susesssfully ++++++++++++++++++")
//       // this.props.getAllClient(nextProps.company_id);
//       // let allClient = this.props.getAllClient(nextProps.company_id).then(res=>{
//       //   //  console.log("m     ounted",res);
//       //    this.setState({option:[...this.state.option, res]})
//       //   res.data })

//       let allUsers = this.props.getAllUsers(nextProps.company_id).then(res => {
//         console.log("All users", res);
//         this.setState({ allUsers: [...this.state.allUsers, res] })
//         res.data
//       })

//       console.log("next props mounted susesssfully ", allUsers);
//     }

//     if (nextProps.job_title) {
//       if (this.state.data.job_title !== nextProps.job_title) {
//         this.setState({
//           data: {
//             ...this.state.data,
//             job_title: nextProps.job_title,
//             job_description: nextProps.job_description,
//             // client_id:nextProps.job_description,
//             // client_name:nextProps.job_description,
//             reqruiter_Name: nextProps.current_job.assignedRecruiter.recruiter_email,
//             // reqruiter_Id : nextProps.current_job.assignedRecruiter.recruiter_id,
//             reqruiter_Email_Id: nextProps.current_job.assignedRecruiter.recruiter_email
//           }
//         })
//       }
//     }
//   }

//   componentDidMount(props) {

//     if (this.props.user_id) {
//       if (this.state.data.user_id !== this.props.user_id) {
//         this.setState({ data: { ...this.state.data, user_id: this.props.user_id } })
//       }
//     }

//     if (this.props.company_id) {

//       if (this.state.data.company_id !== this.props.company_id) {
//         this.setState({ data: { ...this.state.data, company_id: this.props.company_id } })

//       }

//     }

//     if (this.props.job_title) {
//       if (this.state.data.job_title !== this.props.job_title) {
//         this.setState({
//           data: {
//             ...this.state.data,
//             job_title: this.props.job_title,
//             job_description: this.props.job_description,
//             // client_id:this.props.job_description,
//             // client_name:this.props.job_description,
//             // reqruiter_Name:this.props.assignedRecruiter.recruiter_email,
//             // reqruiter_Id : this.props.assignedRecruiter.recruiter_id,
//             // reqruiter_Email_Id: this.props.assignedRecruiter.recruiter_email
//           }
//         })
//       }
//     }
//     console.log("did mount props state", this.props);

//   }

//   render() {

//     console.log('popo', this.state.data);
//     console.log('current job', this.props.current_job)
//     console.log('option', this.state.allUsers[0]);
//     console.log('all client data', this.state);
//     // console.log('redux client datat', this.props.client_data)

//     let newOptions;

//     // if (this.state.option[0] !== undefined) {
//     //   // console.log("teheee ee  ",this.state.option[0])
//     //   newOptions = this.state.option[0].map(a => {
//     //     // console.log("new options with a consoled",a);
//     //     return {
//     //       id: a._id,
//     //       value: a.clientName,
//     //       label: a.clientName
//     //     }
//     //   })
//     // }

//     let allUsersOption;

//     if (this.state.allUsers[0] !== undefined) {
//       allUsersOption = this.state.allUsers[0].map(b => {

//         return {
//           id: b._id,
//           value: b.email,
//           label: b.email
//         }

//       })
//     }

//     //   function getFullName(item,index) {
//     //     var fullname = [item.firstname,item.lastname].join(" ");
//     //     return fullname;
//     // }

//     // function myFunction() {
//     //     document.getElementById("demo").innerHTML = persons.map(getFullName);
//     // }

//     // console.log("new options consoled",newOptions)

//     console.log("data----", this.state.data)

//     const { data, errors, multi, multiValue, options, value, loading } = this.state;

//     let some = { label: data.job_title }
//     let clientName = { label: data.client_name }
//     let userName = {
//       value: data.reqruiter_Email_Id,
//       label: data.reqruiter_Email_Id,
//       id: data.reqruiter_Email_Id,
//     }
//     console.log("assigned recruiter", userName)

//     allUsersOption
//     return (
//       <div>
//         <Segment attached="top" style={{ background: 'linear-gradient(to right, rgb(173, 209, 0), rgb(123, 146, 10))', textAlign: 'center', borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }}>
//           <h4>Job Title</h4>
//         </Segment>
//         <Segment attached>
//           <Form onSubmit={this.handleSubmit} loading={loading}>
//             {/* <RawHtml.div>{data.job_description}</RawHtml.div> */}
//             <Form.Field error={!!errors.job_description}>
//               <label>Job title </label>
//               <CreatableSelect
//                 multi={multi}
//                 options={options}
//                 onChange={this.onChange}
//                 value={some}
//               />
//               {errors.job_title && <InlineError text={errors.job_title} />}
//             </Form.Field>

//             <Form.Field error={!!errors.job_description}>
//               <label> Select client Name </label>
//               {/* <SelectClient  selectData={newOptions}/> */}
//               <CreatableSelect
//                 // multi={newOptions}
//                 options={newOptions}
//                 onChange={this.onChangeClient}
//                 value={clientName}
//               />

//             </Form.Field>

//             <Form.Field error={!!errors.job_description}>
//               <label>Assign To Reqruiter : </label>
//               {/* <SelectClient  selectData={newOptions}/> */}
//               <Select
//                 // multi={newOptions}
//                 options={allUsersOption}
//                 onChange={this.onChangeUsers}
//                 value={userName}
//               />

//             </Form.Field>

//             <Form.Field error={!!errors.job_description}>
//               <label>Job Description</label>
//               <ReactQuill
//                 onChange={this.handleChange}
//                 value={data.job_description}
//                 style={{ backgroundColor: 'white' }}
//               />
//               {errors.job_description && <InlineError text={errors.job_description} />}
//             </Form.Field>
//             <Button.Group>
//               <Button type="submit" positive style={{ background: 'linear-gradient(to right, rgb(173, 209, 0), rgb(123, 146, 10))' }}>Save and continue</Button>{this.props.current_job && Object.keys(this.props.current_job).length > 0 && <Button type="submit" default>Next</Button>}
//             </Button.Group>
//           </Form>
//         </Segment>
//       </div>
//     );
//   }
// }
// JobDesc.propTypes = {
//   current_job: PropTypes.object.isRequired,
//   createJob: PropTypes.func.isRequired,
//   job_title: PropTypes.string,
//   job_description: PropTypes.string,
//   jobid: PropTypes.string,
//   user_id: PropTypes.string,
//   company_id: PropTypes.string,
//   editJobByIdStart: PropTypes.func.isRequired,
//   current_job: PropTypes.object.isRequired,
//   company_id: PropTypes.string.isRequired,
//   recruiter_email: PropTypes.isRequired,
//   client_id: PropTypes.string.isRequired,
//   client_data: PropTypes.isRequired
// }

// function mapStateToProps(state) {
//   if (state.job.current_job && state.company.company_basic_detail) {
//     return {
//       job_title: state.job.current_job.job_title,
//       job_description: state.job.current_job.job_description,
//       jobid: state.job.current_job._id,
//       user_id: state.user.id,
//       current_job: state.job.current_job,
//       company_id: state.company.company_basic_detail.company_id,
//       client_data: state,
//     }
//   }
//   return {}
// }

export default JobDesc;
// export default connect(mapStateToProps, { createJob, getAllClient, getAllUsers, editJobByIdStart })(JobDesc);
