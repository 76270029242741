import { combineReducers } from "redux";
import user from "./reducer_auth";
import company from "./reducer_company";
import job from "./reducer_job";
import career from "./reducer_career";
import application from "./reducer_applications";
import dashboard from "./reducer_dashboard";
import talent_pool from "./reducer_talentpool";
import client from "./reducer_client";
import options from "./reducer_option";
// import options from "./reducer_option";
export default combineReducers({
  user,
  company,
  job,
  application,
  career,
  dashboard,
  talent_pool,
  client,
  options,
});
