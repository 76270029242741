import React, { useState } from 'react'
import { Grid, Segment, Button, Message } from 'semantic-ui-react'

export default function job_item({ job, displayMessage, onAddCandidate, loading }) {
    console.log("job_item", job)
    return (
        <Grid.Column width={16}>
            <Segment
                attached="top"
                style={{
                    background: "#21C8AA",
                    color: "white",
                    textAlign: "center",
                    borderTopLeftRadius: "12px",
                    borderTopRightRadius: "12px",
                }}
            >
                <h3>{job.job_title}</h3>
            </Segment>
            <Segment attached>
                <h5 style={{ marginTop: "-5px" }}>
                    Client Name :
                    <span style={{ fontSize: "12px", color: "grey" }}>
                        {job.assignedClient.client_name}
                    </span>
                </h5>
                <h5 style={{ marginTop: "-5px" }}>
                    Recruiter Name :
                    <span style={{ fontSize: "12px", color: "grey" }}>
                        {job.recruiterName}
                    </span>
                </h5>
                <h5 style={{ marginTop: "-5px" }}>
                    Location :
                    <span style={{ fontSize: "12px", color: "grey" }}>
                        {job.job_location}
                    </span>
                </h5>
                <h5 style={{ marginTop: "-8px" }}>
                    Experience :
                    <span style={{ fontSize: "12px", color: "grey" }}>
                        {job.min_experience} - {job.max_experience} Years
                    </span>
                </h5>
                {/* {Object.keys(displayMessage).length > 0 ? ( */}
                {/* ) : null} */}
                {Object.keys(displayMessage).length > 0 ? (
                    displayMessage.job_id === job._id ? (
                        <Message
                            positive
                            content={displayMessage.message}
                        />
                    ) : null
                ) : null}
                <Button
                    style={{
                        background: "#21c8aa",
                        color: "white",
                        textAlign: "right",
                    }}
                    onClick={() => onAddCandidate(job._id)}

                    loading={loading}
                >
                    Add
                </Button>
            </Segment>
            <br />
        </Grid.Column>
    )
}
