import { Button, Modal } from "semantic-ui-react"

const InterviewDetailModal = ({open, close, interviewData}) => {
    return(
        <Modal open={open} onClose={close}>
            <Modal.Header>Interview Detail</Modal.Header>

            <Modal.Content>
                <div style={{fontWeight: 'bold', display: 'flex', gap: '10px', flexWrap: 'wrap'}}>
                    <p style={{marginBottom: '0'}}>Candidate name: {interviewData.talentAttendees[0].name}</p>
                    <p style={{marginBottom: '0'}}>Role: {interviewData.role}</p>
                    <p style={{marginBottom: '0'}}>Company: {interviewData.customerAttendees[0].name}</p>
                </div>

                <p style={{fontWeight: 'bold', margin: '0.7rem 0'}}>Interviewer Email: {interviewData.customerAttendees.map(list => list.email.length > 0 ? list.email.join(', ') : list.email)}</p>

                <div style={{fontWeight: 'bold'}}>
                    <p style={{marginBottom: '0'}}>Round Title: {interviewData.eventTitle}</p>
                    <p style={{marginBottom: '0', marginTop: '0.5rem'}}>Round Description: {interviewData.eventDescription}</p>
                </div>

                <p style={{marginTop: '0.5rem', fontWeight: 'bold'}}>Availability: {interviewData.availability}</p>

            </Modal.Content>

            <Modal.Actions>
                <Button onClick={close}>Close</Button>
            </Modal.Actions>
        </Modal>
    )
}

export default InterviewDetailModal