import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Header,
  Grid,
  Button,
  Icon,
  Input,
  Dropdown,
  Image,
  Divider,
  Container,
} from "semantic-ui-react";
import Template from "../images/svg/adding-template.svg";
// import Preview from "../images/svg/preview-scorcard.svg";
import CurationModal from "../components/candidate/Modals/curation-modal";
import { useDispatch, useSelector } from "react-redux";
import { Getscorecard } from "../actions/action_application";

const Scorecard = () => {
  const options = [ 
    {
      key: "Design",
      text: "Design",
      value: "Design",
    },
    {
      key: "Developer",
      text: "Developer",
      value: "Developer",
    },
  ];

  const [opentemplate, setIsOpenTemplate] = useState(false);
  const [data, setData] = useState();
  const [showeditbtn, setShoweditbtn]=useState(false);
  const dispatch = useDispatch(); 
  
  const formdata = useSelector(
    (state) => state.application.scorecard.Scoredata
    );
  
  useEffect(() => {
      dispatch(Getscorecard());
  }, []);
 
  function showData(score) {
    setData(score), setIsOpenTemplate(true);
  }
 
 
  return (
    <div>
      <Container>
        <Header as="h4">Scorecard Templates</Header>
        <Grid>
          <Grid.Column computer={12} tablet={12} mobile={6}>
            <Input
              style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.22)" }}
              action={{ icon: "search" }}
              placeholder="Search..."
              fluid
            />
          </Grid.Column> 
          <Grid.Column computer={4} tablet={4} mobile={16}>
            <Button
              basic
              color="teal"
              floated="right"
              onClick={() => {
                setIsOpenTemplate(true);
                setShoweditbtn(true)
                setData({  scorecard_name: "",
                avg_compensation: [
                  {
                    min: 0,
                    max: 0,
                  },
                ],
            
                expected_compensation: [
                  {
                    min: 0,
                    max: 0,
                  },
                ],
                engagement_level: "",
                experience_match: "",
                location_match: "",
                notice_period_match: "",
                salary_match: "",});
              }}
            >
              <Icon>
                <Image src={Template} />
              </Icon>
              Add A New Templates
            </Button>
          </Grid.Column>
        </Grid>
        <br />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p>Showing all templates...</p>
          <Dropdown placeholder="Design" selection options={options} />
        </div>
        <Divider />
        <br />
        <div>
          <h4 className="bluecol text-center">
            <Grid>
              <Grid.Row>
                {!formdata
                  ? "Loading.."
                  : formdata.map((score, key) => (
                      <Grid.Column width={5}>
                        <Image
                          // src={Preview}
                          size="medium"
                          onClick={() =>{ showData(score);
                            setShoweditbtn(false)}
                          }
                        />
                        <p style={{ paddingBottom: "20px", paddingTop: "5px" }}>
                          {score.scorecard_name
                            ? score.scorecard_name
                            : "Template"}
                        </p>
                      </Grid.Column>
                    ))}
              </Grid.Row>
            </Grid>
            
          </h4>
          
          <br />
          <br />

          <CurationModal
            open={opentemplate}
            close={() => setIsOpenTemplate(false)}
            datas={data}
            showeditbtn={showeditbtn}
          />
        </div>
      
      </Container>
    </div>
  );
};

export default Scorecard;
