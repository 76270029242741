import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select'
import { locationOptionAction } from '../actions/action_option';

export default function locationDropdown({ onChangeLocation, data }) {

    const dispatch = useDispatch();
    const locationDropdown = useSelector(
        (state) => state.options.locationOptions
    );
    useEffect(() => {
        // console.log(locationDropdown,"plpl")    
        if (locationDropdown.length < 1) {
            dispatch(locationOptionAction());
        }

        return () => {
            
        }
    }, [dispatch]);
    let dropdownValue = "";
    if(data !== "" || data !== undefined || data !== null) {
        let temp = locationDropdown.find((location => location.location === data ));
        if(temp){

            dropdownValue = {
                key: temp._id,
                value: temp.location,
                label: temp.location
            }
        }
    }
    // console.log("location dropdown", data)
    return (
        <React.Fragment>
            <Select
            isMulti
                options={
                    locationDropdown &&
                    locationDropdown.map((location) => {
                        // console.log("locations", location);
                        return {
                            key: location._id,
                            value: location.location,
                            label: location.location,
                        };
                    })
                }
                value={dropdownValue}
                onChange={onChangeLocation}
            />
        </React.Fragment>
  )
}
