import React, { Component, Suspense } from "react";
import PropTypes from "prop-types";
import AllRoutes from "./routes";
import { connect } from "react-redux";
import Loading from "./pages/loadingpage";
import { pdfjs } from "react-pdf";
import { Navigate } from "react-router-dom";
import JwtProvider from "./customhooks/JwtProvider";
import { GlobalDebug } from "./utils/remove-console";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import TooManyRequestModal from "./utils/tooManyRequestModal";
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

// ================================================================================
const checkAuth = () => {
  const token = localStorage.getItem("accessToken");
  // const url = `http://localhost:5002/api/auth/google-login/success`;
  // const { data } = axios.get(url, { withCredentials: true });
  // console.log("user : ", data);
  if (!token) {
    console.log("Check auth returns False");
    return false;
  }
  console.log("token found : ", token);
  console.log("Check auth returns True");
  return true;
};

const AuthRoute = ({ children }) => {
  checkAuth() ? children : <Navigate to="/signin" />;
};
class App extends Component {
  componentDidMount() {
    (process.env.NODE_ENV === "production" ||
     process.env.REACT_APP_ENV === "STAGING") &&
      GlobalDebug(false);
  }
  render() {
    return (
      <React.Fragment>
        <JwtProvider>
          <Suspense
            fallback={
              <div>
                <Loading />
              </div>
            }
          >
            <TooManyRequestModal>
            {<AllRoutes />}
            </TooManyRequestModal>
          </Suspense>
        </JwtProvider>
      </React.Fragment>
    );
  }
}

App.propTypes = {
  user_id: PropTypes.string,
  userRole: PropTypes.string,
};

function mapStateToProps(state) {
  if (state.user) {
    return {
      user_id: state.user.id,
      userRole: state.user.role,
    };
  }
  return {};
}
export default connect(mapStateToProps, {})(App);
