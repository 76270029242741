import React, { useState } from "react";
import { Button, Form, Modal, TextArea } from "semantic-ui-react";
import Template from "../Curation/Template";
import { useEffect } from "react";

export default function CurationModal({ open, close,datas,showeditbtn, showjobcurationbtn,   }) {

  return (
    <Modal
      open={open}
      size="large"
      closeIcon
      onClose={close}
      style={{ margin: "120px" }}
    >
      <Modal.Content>
        <Modal.Description onClose={close}>
          <Template templatedata={datas} handleClose={close} open={open} showeditbtn={showeditbtn} showjobcurationbtn={showjobcurationbtn}/>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}
