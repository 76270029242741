import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Form, TextArea, Icon, Segment } from "semantic-ui-react";
import Select from "react-select";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
import "./editor.css";
import InlineError from "./../../messages/InlineError";
import {
  addQuestionToJob,
  deleteQuestionNow,
} from "./../../actions/action_job";
import "./editor.css";
import "../../App.css";

class Screening extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_question: "",
      questions: [],
      ask_question: true,
    };
    this.addQuestion = this.addQuestion.bind(this);
    this.onChange = this.onChange.bind(this);
    this.addQuestionNow = this.addQuestionNow.bind(this);
    this.deleteQuestion = this.deleteQuestion.bind(this);
  }

  addQuestion() {
    this.setState({ ask_question: false });
  }

  addQuestionNow() {
    if (this.state.current_question) {
      this.props
        .addQuestionToJob(this.props.jobid, this.state.current_question)
        .then((job) => {
          this.setState({ current_question: "" });
        });
    }
  }

  onChange(e) {
    this.setState({ current_question: e.target.value });
  }

  deleteQuestion(id) {
    this.props.deleteQuestionNow(this.props.jobid, id);
  }

  render() {
    console.log("0000000-------", this.props);

    const { current_question } = this.state;

    let question;
    if (Object.keys(this.props.current_job).length > 0) {
      question = this.props.current_job.questions.map((question, i) => {
        return (
          <div key={question._id} style={{ marginTop: "20px" }}>
            <h4>
              {i + 1}) {question.question}{" "}
              <span>
                {" "}
                <Icon
                  name="close"
                  style={{ color: "#b21e1e" }}
                  negative
                  onClick={() => this.deleteQuestion(question._id)}
                />
              </span>
            </h4>
          </div>
        );
      });
    }

    return (
      <div className="job_posting_form">
        <Segment attached="top" id="headerbackground">
          <h4>Screening Questions</h4>
        </Segment>
        <Segment attached>
          {question}
          {this.state.ask_question &&
            Object.keys(this.props.current_job).length > 0 &&
            this.props.current_job.questions.length === 0 && (
              <h3>
                Would you like to ask questions to candidates before they apply?
              </h3>
            )}
          {this.state.ask_question === false &&
            this.props.current_job.questions.length < 3 && (
              <Form>
                <Form.Field>
                  <br />
                  <TextArea
                    onChange={this.onChange}
                    placeholder="add you question"
                    value={current_question}
                  />
                </Form.Field>
              </Form>
            )}
          <br />
          {this.state.ask_question === false &&
            this.props.current_job.questions.length < 3 && (
              <Button
                positive
                style={{
                  background: "#21c8aa",
                }}
                onClick={this.addQuestionNow}
              >
                <Icon name="plus" />
              </Button>
            )}
          <br />
          <br />
          <br />
          <div style={{ textAlign: "center" }}>
            {/* <Button.Group> */}
            <Button
              default
              onClick={() =>
                this.props.goToJobPostingDetail(this.props.current_job._id)
              }
            >
              Previous
            </Button>
            {this.state.ask_question !== false && (
              <Button
                positive
                onClick={this.addQuestion}
                style={{ background: "#21c8aa" }}
              >
                Add questions
              </Button>
            )}
            <Button
              default
              type="submit"
              onClick={() => this.props.goToJobPublish(this.props.jobid)}
            >
              Next
            </Button>
            {/* </Button.Group> */}
          </div>
        </Segment>
      </div>
    );
  }
}

Screening.propTypes = {
  current_job: PropTypes.object.isRequired,
  jobid: PropTypes.string,
  user_id: PropTypes.string,
  company_id: PropTypes.string.isRequired,
  addQuestionToJob: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  if (state.job.current_job && state.company.company_basic_detail) {
    return {
      jobid: state.job.current_job._id,
      user_id: state.user.id,
      current_job: state.job.current_job,
      company_id: state.company.company_basic_detail.company_id,
    };
  }
  return {};
}

export default connect(mapStateToProps, {
  addQuestionToJob,
  deleteQuestionNow,
})(Screening);
