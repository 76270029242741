import React, { useEffect } from "react";
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
  useParams,
  useNavigate,
} from "react-router-dom";

import PropTypes from "prop-types";

import { connect, useDispatch, useSelector } from "react-redux";
import { Grid, Container, Icon, Step } from "semantic-ui-react";
import Navbar from "./../components/navbar/nav.js";

import JobDesc from "./../components/createajob/jobdesc.js";
import JobPosting from "./../components/createajob/jobposting";
import Screening from "./../components/createajob/screeningque";
import JobPublish from "./../components/createajob/jobpublish";
import { getJobById, emptyCurrentJob } from "./../actions/action_job";
import "./createjob.css";
import AccountSetUpCheck from "./../components/central/account_setup_check";
import CreateFirstJob from "./../components/central/create_first_job";
import { locationOptionAction } from "../actions/action_option.js";

const NewJob = () => {
  const dispatch = useDispatch();
  const { basic, id } = useParams();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const locationOption = useSelector((state) => state.options.locationOptions);

  useEffect(() => {
    console.log("params in new job creation", id, basic);
    if (id) {
      dispatch(getJobById(id));
    } else {
      dispatch(emptyCurrentJob());
    }
    dispatch(locationOptionAction());
  }, []);

  // ******** redirect
  const JobDescRedirect = (id) => {
    navigate(`/job/posting-details/${id}`);
  };

  const JobPublishRedirect = (id) => {
    navigate(`/job/screening/${id}`);
  };

  const JobPostedRedirect = () => {
    navigate(`/jobs`);
  };

  // Next previous Button
  const goToJobPostingDetail = (id) => {
    navigate(`/edit/posting-details/${id}`);
  };

  const goToJobTitleDetail = (id) => {
    navigate(`/edit/job-title/${id}`);
  };

  const goToJobPublish = (id) => {
    navigate(`/edit/publish/${id}`);
  };

  const successJobPostedRedirect = () => {
    navigate(`/jobs`);
  };

  return (
    <div>
      <Navbar />
      <Container>
        <Grid>
          <Grid.Row>
            <Grid.Column width={5}>
              <Step.Group vertical>
                <Step className={basic === "job-title" ? "step_active" : ""}>
                  {basic === "job-title" ? (
                    <Icon name="suitcase" />
                  ) : (
                    <Icon style={{ color: "#b2d431" }} name="check" />
                  )}
                  <Step.Content>
                    <Step.Title>Job Title</Step.Title>
                    <Step.Description>
                      Enter job title & job description
                    </Step.Description>
                  </Step.Content>
                </Step>

                <Step
                  className={basic === "posting-details" ? "step_active" : ""}
                >
                  {basic === "screening" ? (
                    <Icon style={{ color: "#b2d431" }} name="check" />
                  ) : (
                    <Icon name="newspaper" />
                  )}
                  <Step.Content>
                    <Step.Title>Job Posting Details</Step.Title>
                    <Step.Description>Enter job description</Step.Description>
                  </Step.Content>
                </Step>

                <Step className={basic === "screening" ? "step_active" : ""}>
                  {basic === "publish" ? (
                    <Icon style={{ color: "#b2d431" }} name="check" />
                  ) : (
                    <Icon name="edit" />
                  )}
                  <Step.Content>
                    <Step.Title>Screening questions</Step.Title>
                    <Step.Description>
                      To be answered by candidates
                    </Step.Description>
                  </Step.Content>
                </Step>

                <Step className={basic === "publish" ? "step_active" : ""}>
                  <Icon name="info" />
                  <Step.Content>
                    <Step.Title>Publish</Step.Title>
                  </Step.Content>
                </Step>
              </Step.Group>
            </Grid.Column>
            <Grid.Column width={10}>
              {basic === "job-title" && (
                <JobDesc
                  company_id={user.company_id}
                  user_id={user.user_id}
                  // history={this.props.history}
                  JobDescRedirect={JobDescRedirect}
                  // redirect next previous
                  goToJobPostingDetail={goToJobPostingDetail}
                />
              )}
              {basic === "posting-details" && (
                <JobPosting
                  JobPublishRedirect={JobPublishRedirect}
                  job_id={id}
                  locationOptions={locationOption}
                  // redirect previous next
                  goToJobTitleDetail={goToJobTitleDetail}
                  goToJobPublish={goToJobPublish}
                />
              )}
              {basic === "screening" && (
                <Screening
                  goToJobPostingDetail={goToJobPostingDetail}
                  job_id={id}
                  goToJobPublish={goToJobPublish}
                />
              )}
              {basic === "publish" && id && (
                <JobPublish
                  JobPostedRedirect={JobPostedRedirect}
                  job_id={id}
                  // redirect previous next
                  goToJobPostingDetail={goToJobPostingDetail}
                />
              )}
            </Grid.Column>
            {/* <Grid.Column width={1}>

              </Grid.Column> */}
          </Grid.Row>
        </Grid>
        {/* {!props.company_id && <AccountSetUpCheck />} */}
        {/* {<CreateFirstJob total_jobs={this.props.total_jobs} total_jobs_length={this.props.jobs_length}/>} */}
        {/* {!this.props.company_id || this.props.total_jobs !== 0 ? (<AccountSetUpCheck />) : (<CreateFirstJob total_jobs={this.props.total_jobs} total_jobs_length={this.props.jobs_length}/>)} */}
      </Container>
    </div>
  );
};

export default NewJob;
