import React, { useEffect, useId, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Grid } from "semantic-ui-react";
import Axios from "../../../api/axiosInstance";

import JobItem from "./job_item";
import { getAllJobs } from "../../../actions/action_job";
import { getHeader } from "../../../types";

const JobListingModal = ({
  open,
  close,
  companyID,
  multipleCandidates,
  data,
}) => {
  const dispatch = useDispatch();
  const [displayMessage, setDisplayMessage] = useState({});
  const job = useSelector((state) => state.job.allJobs);
  const Application = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  // const { clientOptions, designationOptions, locationOptions } = useSelector(state => state.job.filter_values)
  const user = useSelector((state) => state.user);


  const findAllApplications = (jobId) => {
    const apiUrl = `/api/job/applications/${jobId}`;

    Axios.post(apiUrl)
      .then((response) => {
        // if (response.status === 200) {
        const createdApplication = response.data;
        console.log(`applications for job id ${jobId} - ${createdApplication}`);
      })
      .catch((error) => {
        console.log(`error in finding applications : ${error}`);
        errorCallback(error.message);
      });
  };

  const onAddCandidate = (job_id) => {
    setLoading(true);
    Axios.post(
      `/api/talentpool/addMultipleCandidate/${job_id}`,
      {
        candidateIDs: multipleCandidates,
      },
      { headers: getHeader() }
    )
      .then((res) => {
        console.log("add multiple job", res.data);
        setDisplayMessage({
          job_id: job_id,
          message: `Successfully Applied ${res.data.successfullyAddedIds} candidate(s) in job`,
        });
        setTimeout(() => {
          setDisplayMessage({});
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error: fsdfds" + error);
        setDisplayMessage({
          job_id: job_id,
          message: `Error while Applying ${res.data.successfullyAddedIds} candidate(s) in job`,
        });
      });
  };

  useEffect(() => {
    dispatch(getAllJobs({}));
  }, []);



  return (
    <Modal open={open} onClose={close} closeIcon>
      <Modal.Content>
        <Modal.Description>
          <Grid>
            {/* <GridColumn computer={7}>
              <Select
                style={{ width: "50%", marginBottom: "20px" }}
                onChange={(value) =>
                  setFilter({
                    ...filter,
                    filterByJob: value,
                  })
                }
                options={
                  designationOptions &&
                  designationOptions
                }
              />
            </GridColumn>
            <GridColumn computer={7}>
              <Select
                style={{ width: "50%", marginBottom: "20px" }}
                onChange={(value) =>
                  setFilter({
                    ...filter,
                    filterByClient: value,
                  })
                }
                // isMulti
                options={
                 clientOptions &&
                  clientOptions
                }
              />
            </GridColumn> */}
            {/* <GridColumn computer={2}>
              <Button type="submit" id="buttoncolor" onClick={onSearch}>
                Search
              </Button>
            </GridColumn> */}
          </Grid>

          {!job ? (
            <div>loading</div>
          ) : (
            <Grid>
              <Grid.Row>
                {job.length > 0 &&
                  job.map((job) => {
                    return (
                      <>
                        <JobItem
                          job={job}
                          key={job._id}
                          // findAllApplications={() => findAllApplications(job._id)}
                          onAddCandidate={onAddCandidate}
                          displayMessage={displayMessage}
                          loading={loading}
                        />
                      </>
                    );
                  })}
              </Grid.Row>
            </Grid>
          )}
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default React.memo(JobListingModal);
