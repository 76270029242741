import axios from "axios";
import { getHeader } from "./../types.js";

export default {
  options: {
    locationOptionApi: () =>
      axios
        .get("/api/options/location", {
          headers: getHeader(),
        })
        .then((res) => res.data),
    roleOptionApi: () =>
      axios
        .get("/api/options/role", {
          headers: getHeader(),
        })
        .then((res) => res.data),
    tagOptionApi: () =>
      axios
        .get("/api/options/tags", {
          headers: getHeader(),
        })
        .then((res) => res.data[0]),
        
    podGroupOption: (companyId) => 
      axios
        .get(`/api/auth/allPodGroup/${companyId}`, {
          headers: getHeader(),
        })
        .then(res => res.data),
  },

};
