import {USER_SIGN_UP, USER_SIGN_IN, GET_INITIALIZE,SET_SIGNUP_MEMBER, SET_EMAIL, USER_LOGGED_OUT} from './../types.js'
const initialState = {
  user: { 
      auth:false
  },
  loading: true,
  errors: null
}
export default function user (state=initialState, action = {}){
  switch(action.type){
    case 'LOADING':
      return {
        ...state,
        loading: action.data
      }
    case USER_SIGN_IN:
      // const userInfo = action.payload.user
      // console.log("action.client : ", action.client);
      return Object.assign({}, state, {user: action.payload.user
        // loading: false, success: true
        // client : action.client
      });
    case GET_INITIALIZE:
      console.log("GET_INITIALIZE", action.data)
      return Object.assign({}, state, {user: action.data.user, loading: false});
    case USER_LOGGED_OUT:
      return Object.assign({}, state, {user: { auth: false}});
    case 'USER_ERROR':
        return Object.assign({}, state, { errors: action.errors,loading: false, success: false });
    default:
        return state;
  }
}
