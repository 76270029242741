import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getInitialize } from '../actions/action_auth';
import Loadingpage from '../pages/loadingpage';
import { useNavigate } from 'react-router';

export default function JwtProvider({ children }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {loading, errors} = useSelector(state => state.user);
    useEffect(() => {
      // console.log("jwt provider")
        dispatch(getInitialize());
    }, [])
    if(loading) return <Loadingpage />
    // if(errors) return navigate('/signin')
  return (
    <>{ children }</>
  )
}
