import { data } from "jquery";
import {
  SET_CURRENT_JOB_APPLICATIONS,
  SET_MOVED_CANDIDATES_STAGE,
  SET_FETCHED_CANDIDATE_BY_JOB_ID,
  SET_APPLICATIONS,
  SET_JOB_APPLICATIONS,
  SET_CANDIDATE_FILTER,
  SET_SCHEDULE_NOW,
  SET_FETCH_CAND_TALENT_POOL,
  SET_INTERVIEW_SCHEDULE,
  IS_QA_AND_CURATED_PROFILE,
  DELETE_INTERVIEW,
  SET_CANDIDATE_EDIT_BY_ADMIN,
  CREATE_QRATA_NOTES,
  SET_QRATA_NOTES,
  DELETE_QRATA_NOTES,
  ADD_REFERENCE_INFO,
  TAKE_REFERENCE_INFO_QUESTION,
  CREATE_NOTES,
  CREATE_INTERNAL_NOTES,
  DELETE_INTERNAL_NOTES,
  CLIENT_INTERNAL_NOTES,
  DELETE_CLIENT_NOTES,
  EDIT_NOTES,
  DELETE_NOTES,
  LOADING_API,
  CONTENT_LOADING,
  ERROR_UPDATING_DATA,
  ADD_CANDIDATE_FEEDBACK,
  ADD_CANDIDATE_DOCUMENT,
  DELETE_CANDIDATE_DOCUMENT,
  EDITED_WORKEXPERIENCE,
  EDITED_EDUCATION,
  EDITED_RESUME,
  SCORECARD_TEMPLATE,
  GETSCORECARD_TEMPLATE,
  UPDATESCORECARD_TEMPLATE,
  UPDATETEMPLATE_JOB,
  TALENT_GPT,
  CURATION_DATA,
  UPDATE_DATA,
  QRATA_FIT,
  GET_CHATEDUSERS,
  All_Chats,
  STABILITY_RATING,
  INTERVIEW_LIST,
  UNLOCKED_TALENTS,
  GET_MARKETINSIGHTS,
  REQUEST_SUBMIT,
  ADD_TALENT_SCORECARD,
  GET_TALENT_SCORECARD,
  ALL_MAPPED_CANDIDATE,
  GET_MAPPED_CANDIDATE,
} from "./../types.js";
import { actions } from "react-table";
import curation from "../components/candidate/Curation/Compensation.js";

const initialState = {
  applications: null,
  individual_candidate: {
    id: "",
    application_data: {},
    bio: {},
    behaviors: {},
    analytics: {},
    qrataNotes: {},
    referenceInfo: [],
    timeline: {},
    verification: {},
    documents: {},
    interviewSchedule: undefined,
    candidateAnalytics: {},
    internalCandidateNotes: [],
    clientInternalNotes: [],
    potentials: {},
    veriStat: null,
    curationData: [],
    curationdata: {},
    stabilityData: null,
  },
  allInterviewSchedules: [],
  notes: [],
  interviewList: [],
  unlockedTalent: [],
  // martketIntel: [],
  filter_content: {},
  loading: false,
  contentLoading: false,
  error: false,
  errorMessage: "",
  scorecard: {
    scorecard_name: "",
    avg_compensation: [
      {
        min: 0,
        max: 0,
      },
    ],

    expected_compensation: [
      {
        min: 0,
        max: 0,
      },
    ],
    engagement_level: "",
    experience_match: "",
    location_match: "",
    notice_period_match: "",
    salary_match: "",
  },
  talentgpt: "",
  capability: {},
};

export default function career(state = initialState, action = {}) {
  let candidate_data,
    basic_details,
    behaviors,
    clientInternalNotes,
    analytics,
    candidate_schedule,
    candidateAnalytics;
  switch (action.type) {
    case LOADING_API:
      return {
        ...state,
        loading: true,
      };
    case CONTENT_LOADING:
      return {
        ...state,
        contentLoading: true,
      };
    case ERROR_UPDATING_DATA:
      return {
        ...state,
        loading: false,
        contentLoading: false,
        error: true,
        errorMessage: action.error,
      };
    case SET_FETCHED_CANDIDATE_BY_JOB_ID:
      candidate_data = action.data.candidateDetail;
      const job_data = action.data.job[0];
      console.log(
        "reducer from SET_FETCHED_CANDIDATE_BY_JOB_ID",
        candidate_data
      );
      console.log(
        "reducer from SET_FETCHED_CANDIDATE_BY_JOB_ID",
        candidate_data
      );
      console.log("reducerPPPPPP", action);
      console.log("clientNotes", candidate_data.clientNotes);
      console.log("job_data", job_data);

      basic_details = {
        _id: candidate_data._id,
        fullName: candidate_data.firstName + " " + candidate_data.lastName,
        firstName: candidate_data.firstName,
        lastName: candidate_data.lastName,
        email: candidate_data.email,
        contactNumber: candidate_data.contactNumber,
        summary: candidate_data.summary,
        skills: candidate_data.skills,
        currentDesignation: candidate_data.currentDesignation,
        currentCompany: candidate_data.currentCompany,
        currentSalary: candidate_data.currentSalary,
        currentLocation: candidate_data.currentLocation,
        currentCurrencyType: candidate_data.currentCurrencyType,
        preferenceCurrencyType: candidate_data.preferenceCurrencyType,
        preferredDesignation: candidate_data.preferredDesignation,
        preferredLocation: candidate_data.preferredLocation,
        preferredSalary: candidate_data.preferredSalary,
        experience: candidate_data.experience,
        noticePeriod: candidate_data.noticePeriod,
        typeCompanyPreference: candidate_data.typeCompanyPreference,
        typeOfEmployment: candidate_data.typeOfEmployment,
        top3Skills: candidate_data.top3Skills,
        workExperience: candidate_data.workExperience,
        education: candidate_data.education,
        educationResume: candidate_data.parsedResumeData.educationResume,
        workExperienceResume:
          candidate_data.parsedResumeData.workExperienceResume,
        communicationSkills: candidate_data.communicationSkills,
        resumeURL: candidate_data.resumeURL,
        potentials: candidate_data.potentials,
        workSample: candidate_data.workSampleProject,
        workLinks: candidate_data.workLinks,
        certificates: candidate_data.certificates,
        profilePictureURL: candidate_data.profilePictureURL,
        candidateDocuments: candidate_data.candidateDocuments,
        contractMode: candidate_data.contractMode,
        role: candidate_data.role,
        resumeOrignalName: candidate_data.resumeOrignalName,
        referenceInfo: candidate_data.referenceInfo,
        companyType: candidate_data.companyType,
        companySize: candidate_data.companySize,
        gptInsights: candidate_data.gptInsights,
        additionalCompensationNotes: candidate_data.additionalCompensationNotes,
        additonalExpectedCompensationNotes:
          candidate_data.additonalExpectedCompensationNotes,
        feedback: candidate_data.feedback,
        internalCandidateNotes: candidate_data.internalCandidateNotes,
        stabilityData: candidate_data.gptRatings?.stabilityRating,
        requested: candidate_data.requested,
        talentScorecard: candidate_data.scores,
        assignment: candidate_data.assignment,
        // job_questions_answers: candidate_data.job_questions_answers,
        typeCompanyPreference: candidate_data.typeCompanyPreference,
        timeCommitmentType: candidate_data.timeCommitmentType,
        timeCommitment: candidate_data.timeCommitment,
        preferredSalaryPerHour: candidate_data.preferredSalaryPerHour,
        projects: candidate_data.projects,
        videoUrl: candidate_data.video_url,
        veriStat:candidate_data.veriStat
      };

      behaviors = {
        coreValues: candidate_data.coreValues,
        organisationValues: candidate_data.organisationValues,
        personalityAssessment: candidate_data.personalityAssessment,
        strengths: candidate_data.strengths,
        opportunities: candidate_data.opportunities,
        generalAssessment: candidate_data.generalAssessment,
      };

      clientInternalNotes = job_data.notes;

      analytics = candidate_data.parsedResumeData.resumeAnalytics;
      candidateAnalytics = candidate_data.candidate_Analytics_feedback;
      candidate_schedule = job_data.interviewSchedule.find(
        (interview) => interview.candidateEmail === candidate_data.email
      );
      console.log("candidate schedule data reducer", job_data, candidate_data);
      console.log("action.data?.sw : ", action.data?.sw);
      return Object.assign({}, state, {
        individual_candidate: {
          bio: basic_details,
          application_data: job_data,
          veriStat: candidate_data.veriStat,
          behaviors,
          clientInternalNotes,
          analytics,
          candidateAnalytics,
          // internalCandidateNotes,
          curationData: job_data.template,
          qrataNotes: candidate_data.qrataNotes,
          referenceInfo: candidate_data.referenceInfo
            ? candidate_data.referenceInfo
            : [],
          notes: candidate_data.notes ? candidate_data.notes : [],
          documents: candidate_data.documents ? candidate_data.documents : [],
          interviewSchedule: candidate_schedule,
          // internalCandidateNotes: candidate_data?.internalCandidateNotes ? candidate_data?.internalCandidateNotes : [],
        },
        application_stage: {
          applied: job_data.applied,
          added: job_data.applied,
          curated: job_data.curated,
          shortlisted: job_data.shortlisted,
          interviewed: job_data.interviewed,
          offered: job_data.offered,
          hired: job_data.hired,
          rejected: job_data.rejected,
          _id: job_data._id,
          documents: job_data.documents,
        },
        capability: action.data?.sw,
        fetchTalentss: action,
        notes: job_data.notes,
        interviewList: candidate_data.data,
        unlockedTalent: candidate_data.data,
        marketIntel: job_data.data,
        allInterviewSchedules: job_data.interviewSchedule,
        loading: false,
        error: false,
      });

    case SET_CANDIDATE_FILTER:
      return { ...state, filter_content: action.data };

    case SET_CURRENT_JOB_APPLICATIONS:
      return Object.assign(
        {},
        state,
        { applications: action.newData.data },
        { stage: action.newData.stage },
        { stageCounts: action.newData.stageCounts }
      );

    case SET_APPLICATIONS:
      // return  {...state, applications: action.data };
      return Object.assign({}, ...state, { applications: action.data });

    // case SET_JOB_APPLICATIONS:
    //     return  {...state, applications: [action.data] };
      // return Object.assign({}, ...state, { applications: action.data });
      case SET_JOB_APPLICATIONS:
        if(state.applications===null){
          return {
            ...state,
            // applications: [...state.applications, action.data], // action.data is the new application being added
            stageCounts: {
                ...state.stageCounts, // Spread existing stageCounts
                // prospect: state.stageCounts.prospect + 1 // Increment the prospect count
            }
        };
        }
        return {
            ...state,
            applications: [...state.applications, action.data], // action.data is the new application being added
            stageCounts: {
                ...state.stageCounts, // Spread existing stageCounts
                prospect: state.stageCounts.prospect + 1 // Increment the prospect count
            }
        };
   
    case SET_SCHEDULE_NOW:
      candidate_schedule = action.data.find(
        (interview) =>
          interview.candidateEmail === state.individual_candidate.bio.email
      );
      return Object.assign({}, state, {
        individual_candidate: {
          ...state.individual_candidate,
          interviewSchedule: candidate_schedule,
        },
        contentLoading: false,
        error: false,
        allInterviewSchedules: action.data,
      });

    // case CANCEL_INTERVIEW:
    // candidate_schedule = action.data.find(interview => interview.candidateEmail === state.individual_candidate.bio.email);
    // return Object.assign({}, state, {individual_candidate: {
    //   ...state.individual_candidate,
    //   interviewSchedule : candidate_schedule

    // },
    // contentLoading: true,
    // error : false,
    // allInterviewSchedules : action.data})

    case SET_FETCH_CAND_TALENT_POOL:
      candidate_data = action.data;
      console.log("reducer", candidate_data);

      basic_details = {
        _id: candidate_data._id,
        fullName: candidate_data.firstName + " " + candidate_data.lastName,
        firstName: candidate_data.firstName,
        lastName: candidate_data.lastName,
        email: candidate_data.email,
        contactNumber: candidate_data.contactNumber,
        summary: candidate_data.summary,
        skills: candidate_data.skills,
        currentDesignation: candidate_data.currentDesignation,
        currentCompany: candidate_data.currentCompany,
        currentSalary: candidate_data.currentSalary,
        currentLocation: candidate_data.currentLocation,
        currentCurrencyType: candidate_data.currentCurrencyType,
        preferenceCurrencyType: candidate_data.preferenceCurrencyType,
        preferredDesignation: candidate_data.preferredDesignation,
        preferredLocation: candidate_data.preferredLocation,
        preferredSalary: candidate_data.preferredSalary,
        experience: candidate_data.experience,
        noticePeriod: candidate_data.noticePeriod,
        typeCompanyPreference: candidate_data.typeCompanyPreference,
        typeOfEmployment: candidate_data.typeOfEmployment,
        top3Skills: candidate_data.top3Skills,
        workExperience: candidate_data.workExperience,
        education: candidate_data.education,
        educationResume: candidate_data.parsedResumeData.educationResume,
        workExperienceResume:
          candidate_data.parsedResumeData.workExperienceResume,
        communicationSkills: candidate_data.communicationSkills,
        resumeURL: candidate_data.resumeURL,
        searchTag: candidate_data.searchTag,
        potentials: candidate_data.potentials,
        workSample: candidate_data.workSampleProject,
        workLinks: candidate_data.workLinks,
        certificates: candidate_data.certificates,
        profilePictureURL: candidate_data.profilePictureURL,
        candidateDocuments: candidate_data.candidateDocuments,
        contractMode: candidate_data.contractMode,
        role: candidate_data.role,
        resumeOrignalName: candidate_data.resumeOrignalName,
        referenceInfo: candidate_data.referenceInfo,
        companyType: candidate_data.companyType,
        companySize: candidate_data.companySize,
        gptInsights: candidate_data.gptInsights,
        additionalCompensationNotes: candidate_data.additionalCompensationNotes,
        additonalExpectedCompensationNotes:
          candidate_data.additonalExpectedCompensationNotes,
        internalCandidateNotes: candidate_data.internalCandidateNotes,
        stabilityData: candidate_data.gptRatings?.stabilityRating,
        requested: candidate_data.requested,
        talentScorecard: candidate_data.scores,
        timeCommitment: candidate_data.timeCommitment,
        timeCommitmentType: candidate_data.timeCommitmentType,
        preferredSalaryPerHourCurrency:
          candidate_data.preferredSalaryPerHourCurrency,
        preferredSalaryPerHour: candidate_data.preferredSalaryPerHour,
        projects: candidate_data.projects,
        videoUrl: candidate_data.video_url,
        veriStat:candidate_data.veriStat
      };

      behaviors = {
        coreValues: candidate_data.coreValues,
        organisationValues: candidate_data.organisationValues,
        personalityAssessment: candidate_data.personalityAssessment,
        strengths: candidate_data.strengths,
        opportunities: candidate_data.opportunities,
        generalAssessment: candidate_data.generalAssessment,
      };

      analytics = candidate_data.parsedResumeData.resumeAnalytics;
      candidateAnalytics = candidate_data.candidate_Analytics_feedback;

      return Object.assign({}, state, {
        individual_candidate: {
          bio: basic_details,
          veriStat: candidate_data.veriStat,
          behaviors,
          analytics,
          candidateAnalytics,
          qrataNotes: candidate_data.qrataNotes,
          referenceInfo: candidate_data.referenceInfo
            ? candidate_data.referenceInfo
            : [],
          notes: candidate_data.notes ? candidate_data.notes : [],
          documents: candidate_data.documents ? candidate_data.documents : [],
        },
        loading: false,
        error: false,
      });

    case SET_INTERVIEW_SCHEDULE:
      console.log("reducer", action.data);
      return Object.assign({}, state, {
        individual_candidate: [
          { ...state.individual_candidate[0], interviewSchedule: action.data },
        ],
      });

    case SET_CANDIDATE_EDIT_BY_ADMIN:
      console.log("reducer", action.basic_details);
      return Object.assign({}, state, {
        individual_candidate: {
          ...state.individual_candidate,
          bio: {
            ...state.individual_candidate.bio,
            ...action.basic_details,
          },
        },
        contentLoading: false,
        error: false,
      });

    case CREATE_QRATA_NOTES:
      console.log("reducer qrata notes", action.data);
      return Object.assign({}, state, {
        individual_candidate: {
          ...state.individual_candidate,
          qrataNotes: action.data,
        },
        loading: false,
        error: false,
        contentLoading: false,
      });
    case DELETE_QRATA_NOTES:
      let updatedQrataNotes =
        state.individual_candidate.qrataNotes.questionsByRole.filter(
          (qrata_notes) => qrata_notes._id !== action.qrataNotesID
        );
      console.log("dlelte", updatedQrataNotes);
      return Object.assign({}, state, {
        individual_candidate: {
          ...state.individual_candidate,
          qrataNotes: {
            ...state.individual_candidate.qrataNotes,
            questionsByRole: updatedQrataNotes,
          },
        },
      });
    case ADD_REFERENCE_INFO:
      console.log("add refernce incoming data", action.data);
      return Object.assign({}, state, {
        individual_candidate: {
          ...state.individual_candidate,
          referenceInfo: [
            ...state.individual_candidate.referenceInfo,
            action.data,
          ],
        },
        contentLoading: false,
      });

    case TAKE_REFERENCE_INFO_QUESTION:
      console.log("take refernce incoming data", action.data);
      const updateQuestions = state.individual_candidate.referenceInfo.filter(
        (update) => update._id !== action.data.refId
      );
      return Object.assign({}, state, {
        individual_candidate: {
          ...state.individual_candidate,
          referenceInfo: [...updateQuestions, action.data.data],
        },
        contentLoading: false,
      });

    case CREATE_NOTES:
      return {
        ...state,
        notes: action.data,
        contentLoading: false,
      };
    case DELETE_NOTES:
      return {
        ...state,
        notes: action.data,
        contentLoading: false,
      };
    case EDIT_NOTES:
      console.log("edit reducer", action.data);
      let updatedNotes = state.notes.map((note) => {
        if (note._id === action.data._id) return action.data;
        else return note;
      });
      return {
        ...state,
        notes: updatedNotes,
      };
    case SET_MOVED_CANDIDATES_STAGE:
      const updatedStage = action.data;
      console.log("application stage", action.data);
      return {
        ...state,
        application_stage: {
          ...state.application_stage,
          ...updatedStage,
        },
      };

    case ADD_CANDIDATE_DOCUMENT:
      const addDocument = action.data;
      console.log("ADD_CANDIDATE_DOCUMENT", action.data);
      return {
        ...state,
        application_stage: {
          ...state.application_stage,
          documents: [...state.application_stage.documents, ...addDocument],
        },
      };
    case DELETE_CANDIDATE_DOCUMENT:
      const deleteDocument = action.data.data;
      console.log("DELETE_CANDIDATE_DOCUMENT", action.data.data);
      return {
        ...state,
        application_stage: {
          ...state.application_stage,
          documents: [...deleteDocument],
        },
      };

    // case SET_APPLICATIONS:
    //   return Object.assign({}, ...state, {applications : action.data})

    case ADD_CANDIDATE_FEEDBACK:
      const candidateFeedback = action.data;
      console.log("ADD_CANDIDATE_FEEDBACK ", action.data);
      return {
        ...state,
        candidateAnalytics: {
          ...state.candidate_Analytics_feedback,
          ...candidateFeedback,
        },
      };

    case EDITED_WORKEXPERIENCE:
      const editedWorkExperience = action.data;
      console.log("EDITED_WORKEXPERIENCE", action.data);
      // let updatedworkExperience = action.data.workExperience;
      return {
        ...state,
        individual_candidate: {
          ...state.individual_candidate,
          bio: {
            ...state.individual_candidate.bio,
            workExperience: action.data,
          },
        },
      };
    case EDITED_EDUCATION:
      const editedEducation = action.data;
      // let updatedworkExperience = action.data.workExperience;
      return {
        ...state,
        individual_candidate: {
          ...state.individual_candidate,
          bio: {
            ...state.individual_candidate.bio,
            education: action.data,
          },
        },
      };
    case IS_QA_AND_CURATED_PROFILE:
      return {
        ...state,
        individual_candidate: {
          ...state.individual_candidate,
          veriStat: action.data.veriStat,
        },
        contentLoading: false,
      };
    case EDITED_RESUME:
      return {
        ...state,
        individual_candidate: {
          ...state.individual_candidate,
          bio: {
            ...state.individual_candidate.bio,
            resumeURL: action.data.resumeURL,
          },
        },
        contentLoading: false,
      };
    case SCORECARD_TEMPLATE:
      return {
        ...state,
        scorecard: {
          ...state.scorecard,
        },
      };
    case GETSCORECARD_TEMPLATE:
      return {
        ...state,
        scorecard: {
          Scoredata: action.data,
        },
      };
    case UPDATESCORECARD_TEMPLATE:
      console.log("updatescoredata", action.data);
      return {
        ...state,
        scorecard: {
          ...state.scorecard,
          scorecard: action.data,
        },
      };

    case UPDATETEMPLATE_JOB:
      return {
        ...state,
        individual_candidate: {
          ...state.individual_candidate,
          curationData: action.Data.template,
        },
      };

    case TALENT_GPT:
      return {
        ...state,
        talentgpt: {
          ...state.talentgpt,
        },
      };

    // case CURATION_DATA:
    //   console.log("reducer curationaaa  ",action.data.curation)
    //   return{
    //     ...state,
    //     curationdata:{
    //       ...state.curationdata,
    //       curationdata:action.data
    //     }
    //   }

    case CURATION_DATA:
      console.log(action.data, "hello paji");
      return {
        ...state,
        curationdata: action.data?.curation,
        capability: action.data?.sw,
      };

    case QRATA_FIT:
      console.log("reducer curationaaa  ", action.data);
      return {
        ...state,
        curationdata: {
          ...state.curationdata,
          qrataFit: action.data.content,
        },
      };
    case CREATE_INTERNAL_NOTES:
      console.log("internal notes", action.data.notes);
      return {
        ...state,
        individual_candidate: {
          ...state.individual_candidate,
          bio: {
            ...state.individual_candidate.bio,
            internalCandidateNotes: action.data.notes,
          },
        },
      };
    case DELETE_INTERNAL_NOTES:
      return {
        ...state,
        individual_candidate: {
          ...state.individual_candidate,
          bio: {
            ...state.individual_candidate.bio,
            internalCandidateNotes: action.data.notes,
          },
        },
      };
    case CLIENT_INTERNAL_NOTES:
      return {
        ...state,
        individual_candidate: {
          ...state.individual_candidate,
          clientInternalNotes: action.data,
        },
      };

    case DELETE_CLIENT_NOTES:
      return {
        ...state,
        individual_candidate: {
          ...state.individual_candidate,
          clientInternalNotes: action.data,
        },
      };

    case "SCORECARD_UPDATE":
      return {
        ...state,
        curationdata: {
          ...state.curationdata,
          scoreCardLink: action.data,
        },
      };

    case INTERVIEW_LIST:
      return {
        ...state,
        interviewList: action.data.data,
      };

    case UNLOCKED_TALENTS:
      return {
        ...state,
        unlockedTalent: action.data,
      };

    case GET_MARKETINSIGHTS:
      return {
        ...state,
        marketIntel: action.data,
      };

    case ALL_MAPPED_CANDIDATE:
      return {
        ...state,
        mappedCandidates: action.data,
      };

    case GET_MAPPED_CANDIDATE:
      return {
        ...state,
        mappedCandidatesByRole: action.data,
      };

    case "SUB_STATUS_CHANGE":
      let updatedapplications = state.applications.filter((app) => {
        if (app._id === action.data.appId) {
          app.subStatus = action.data.subStatus;
        }

        return app;
      });
      return {
        ...state,
        applications: updatedapplications,
      };
    case GET_CHATEDUSERS:
      return {
        ...state,
        userData: action.data,
        error: action.error,
      };

    case All_Chats:
      return {
        ...state,
        userChats: action.data,
      };

    case STABILITY_RATING:
      console.log("internal notes22", action.data.stabilityRating);
      return {
        ...state,
        individual_candidate: {
          ...state.individual_candidate,
          bio: {
            ...state.individual_candidate.bio,
            stabilityData: action.data.stabilityRating,
          },
        },
      };

    case REQUEST_SUBMIT:
      console.log("from request submit reducer : ", action.data);
      // const modifiedSearch = state.savedSearch.map(savedSearch => savedSearch._id === action.data._id ? action.data : savedSearch);
      console.log(
        "action.data.requested: aaaaaaaaaaaaaaaaaaaaaa",
        action.data.requested
      );
      console.log(
        "state.individual_candidateaaaaaaaaaaaaaaaaaaaaa",
        state.individual_candidate
      );

      return {
        ...state,
        individual_candidate: {
          ...state.individual_candidate,
          bio: {
            ...state.individual_candidate.bio,
            requested: action.data.result,
          },
        },
      };
    case ADD_TALENT_SCORECARD:
      return {
        ...state,
        individual_candidate: {
          ...state.individual_candidate,
          bio: {
            ...state.individual_candidate.bio,
            talentScorecard: action.data.talentScorecard,
          },
        },
      };
    case GET_TALENT_SCORECARD:
      return {
        ...state,
        individual_candidate: {
          ...state.individual_candidate,
          bio: {
            ...state.individual_candidate.bio,
            talentScorecard: action.data.talentScore,
          },
        },
      };

    default:
      return state;
  }
}
