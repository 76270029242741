import {
  USER_SIGN_UP,
  USER_SIGN_IN,
  SET_BASIC_COMPANY_INFO,
  USER_LOGGED_OUT,
  SET_EMAIL,
  SET_SIGNUP_MEMBER,
} from "./../types";
import api from "./../api/auth_api";
import axios from 'axios'
export function SignUp(data) {
  return {
    type: USER_SIGN_UP,
    data,
  };
}

export function errors(data){
  return {
    type: 'USER_ERROR',
    data
  }
}

export function SignIn(user) {
  return {
    type: USER_SIGN_IN,
    payload: user,
    // client: clientData
  };
}

export function LogOut() {
  return {
    type: USER_LOGGED_OUT,
  };
}

export function setEmail(data) {
  return {
    type: SET_EMAIL,
    data,
  };
}

export function setSignupMember(data) {
  return {
    type: SET_SIGNUP_MEMBER,
    data,
  };
}

export const signup = (data) => (dispatch) => {
  return api.user.signup(data).then((user) => {
    return dispatch(SignUp(user));
  });
};

export const signin = (data) => (dispatch) => {
  let authUser = {};
  // console.log("Action data ", api.user.signin());
  return api.user.signin(data).then((user) => {
    console.log("USER", user);
    authUser = user.user.token;
    window.localStorage.setItem("accessToken", authUser);
    axios.defaults.headers.common.Authorization = `Bearer ${authUser}`;
    return dispatch(SignIn(user));
  });
};

export const getInitialize = () => dispatch =>{
  const token = window.localStorage.getItem("accessToken");
  if(!token)console.log("Bhai no token is present here.")
  dispatch({
    type: "LOADING",
    data: true
  })
  return api.user.getInitialize(token).then(user => {
    return dispatch({
      type: 'GET_INITIALIZE',
      data: user
    })
  })
  .catch(err => {
    console.log("error in getInitialize", err);
    return dispatch({
      type: 'USER_ERROR',
      data: err
    })
  })
}

export const logout = () => (dispatch) => {
  console.log("logout");
  return api.user.logoutApi().then(() => {
    delete axios.defaults.headers.common.Authorization;
    localStorage.removeItem("accessToken");
    localStorage.removeItem("authUser");
    localStorage.removeItem("brandingPopUpClosed");
    dispatch(LogOut())
  })
  
};

export const getEmail = (token) => (dispatch) => {
  return api.user.getEmailApi(token).then((data) => {
    return dispatch(setEmail(data));
  });
};

export const signupMember = (data) => (dispatch) => {
  return api.user.signupMemberApi(data).then((data) => {
    return dispatch(setSignupMember(data));
  });
};

export const ChangePass = (data, email) => (dispatch) => {
  return api.user.ChangePassApi(data, email).then((data) => {
    localStorage.removeItem("authUser");
    return data;
  });
};

export const submitForgotPassword = (email) => (dispatch) => {
  return api.user.submitForgotPasswordApi(email).then((data) => {
    return data;
  })
  .catch(err => {
    console.log("error in submitForgotPassword", err);
    return err
  })
};

export const submitSetForgotPassword = (data, token) => (dispatch) => {
  return api.user.submitSetForgotPasswordApi(data, token).then((data) => {
    return data;
  });
};
