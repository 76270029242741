import React from "react";
import { Image, Tab, Menu } from "semantic-ui-react";
import Chats from "./Chats";
import TalentConversation from "./TalentConversation";
import CustomerConversation from "./CustomerConversation";
import CustomerImg from "./customer.svg";
import TalentImg from "./talent.svg";

const panes = [
  {
    // menuItem: 'Customer',
    menuItem: (
      <Menu.Item key="Customer">
        {/* <Image src={CustomerImg} /> */}
        Customer
      </Menu.Item>
    ),
    // menuItem:{ key: 'users', icon: `${<Image src={CustomerImg} />}`, content: 'Users' },
    render: () => (
      <Tab.Pane attached={false}>
        <CustomerConversation />
      </Tab.Pane>
    ),
  },
  {
    // menuItem: 'Talent',
    menuItem: (
      <Menu.Item key="Talent">
        {/* <Image src={TalentImg} /> */}
        Talent
      </Menu.Item>
    ),
    render: () => (
      <Tab.Pane attached={false}>
        <TalentConversation />
      </Tab.Pane>
    ),
  },
];

const Conversation = ({ handleChatSelection }) => (
  <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
);

export default Conversation;
