import React, { Component } from "react";
import { Container, Grid, Form, Button, Message, Loader } from "semantic-ui-react";
import Navbar from "./../components/navbar/nav.js";
import Validator from "validator";
import InlineError from "./../messages/InlineError";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { submitSetForgotPassword } from "./../actions/action_auth";
import { config } from "../config.js";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { applyMiddleware } from "redux";
import auth_api from "../api/auth_api.js";

// class SetForgotPassword extends Component {
//   state = {
//     data: {
//       password: "",
//       repassword: "",
//     },
//     success: false,
//     errors: {},
//   };

//   onChange = (e) => {
//     this.setState({
//       data: { ...this.state.data, [e.target.name]: e.target.value },
//     });
//   };

//   onSubmit = () => {
//     const errors = this.validate(this.state.data);
//     this.setState({ errors });
//     if (Object.keys(errors).length === 0) {
//       this.props
//         .submitSetForgotPassword(this.state.data, this.props.match.params.token)
//         .then((response) => {
//           console.log(response);
//           this.setState({
//             data: {
//               password: "",
//               repassword: "",
//             },
//             success: true,
//             errors: {},
//           });
//           console.log("----------", this.props);
//           setTimeout(function () {
//             window.location.assign(`${window.location.origin}/signin`);
//           }, 2000);
//         })
//         .catch((error) => {
//           console.log(error.response);
//         });
//     }
//   };

//   validate = (data) => {
//     const errors = {};
//     if (!data.password) errors.password = "This field is required.";
//     if (!data.repassword) errors.repassword = "This field is required.";
//     if (data.password !== data.repassword)
//       errors.password = "Password did not match";
//     if (data.password !== data.repassword)
//       errors.repassword = "Password did not match";
//     return errors;
//   };

//   render() {
//     const { data, errors } = this.state;
//     console.log("forgot password", this.props);

//     return (
//       <div>
//         <Navbar />
//         <Container>
//           <Grid centered columns={2}>
//             <Grid.Column>
//               <br />
//               <br />
//               <h1 style={{ color: "#003458" }}>Forgot password</h1>
//               <Form onSubmit={this.onSubmit}>
//                 <Form.Field error={!!errors.email}>
//                   <label style={{ color: "#003458" }}>Password</label>
//                   <input
//                     type="password"
//                     name="password"
//                     id="password"
//                     placeholder="Password"
//                     value={data.password}
//                     onChange={this.onChange}
//                   />
//                   {errors.password && <InlineError text={errors.password} />}
//                 </Form.Field>
//                 <Form.Field error={!!errors.repassword}>
//                   <label style={{ color: "#003458" }}>Re-enter password</label>
//                   <input
//                     type="password"
//                     name="repassword"
//                     id="password"
//                     placeholder="Re-enter password"
//                     value={data.repassword}
//                     onChange={this.onChange}
//                   />
//                   {errors.repassword && (
//                     <InlineError text={errors.repassword} />
//                   )}
//                 </Form.Field>
//                 <Button
//                   floated="right"
//                   style={{ color: "white", background: "#21C8AA" }}
//                 >
//                   Save
//                 </Button>
//               </Form>
//               <br />
//               {this.state.success && (
//                 <Message positive>
//                   <Message.Header>Password reset</Message.Header>
//                   <p>Password has been reset successfully.</p>
//                 </Message>
//               )}
//             </Grid.Column>
//           </Grid>
//         </Container>
//       </div>
//     );
//   }
// }

export default function SetForgotPassword(){
  let success = false;
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const token = params.token;
  console.log("setForgotPassword", params)
  const [data, setData] = useState({
    password:'',
    repassword:'',
  });
  const [ loading, setLoading] = useState(false);
  
  const [errors, setErrors] = useState({});
  const onChange = (e) => {
    setData({
      ...data,
      [e.target.name] : e.target.value
    });
    setErrors({});
  }

  const validate = () => {
    const errors = {};
    if (!data.password) errors.password = "This field is required.";
    if (!data.repassword) errors.repassword = "This field is required.";
    if (data.password !== data.repassword)
      errors.password = "Password did not match";
    if (data.password !== data.repassword)
      errors.repassword = "Password did not match";
    return errors;
  };

  const onSubmit = async() => {
    const errors = validate();
    setLoading(true);
    if(!Object.keys(errors).length > 0){

      auth_api.user.submitSetForgotPasswordApi(data, token)
      .then(response => {
        success = true;
        setTimeout(() => {
          navigate('/signin')
        }, 3000)
      })
      .catch(error => {
        console.log(error);
        setErrors({token: error.response.data.error});
      })
    }
    setLoading(false);
  }

  console.log(errors);
  return (
    <div>
        <Navbar />
        <Container>
          <Grid centered columns={2}>
            <Grid.Column>
              <br />
              <br />
              <h1 style={{ color: "#003458" }}>Forgot password</h1>
              <Form onSubmit={onSubmit}>
                <Form.Field>
                  <label style={{ color: "#003458" }}>Password</label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    value={data.password}
                    onChange={onChange}
                  />
                  {errors.password && <InlineError text={errors.password} />}
                </Form.Field>
                <Form.Field>
                  <label style={{ color: "#003458" }}>Re-enter password</label>
                  <input
                    type="password"
                    name="repassword"
                    id="password"
                    placeholder="Re-enter password"
                    value={data.repassword}
                    onChange={onChange}
                  />
                  {errors.repassword && (
                    <InlineError text={errors.repassword} />
                  )}
                </Form.Field>
                <Button
                  floated="right"
                  style={{ color: "white", background: "#21C8AA" }}
                  
                >
                  {loading ? (
                <Loader size="tiny" active inline="centered" />
              ) : (
                "Save"
              )}
                </Button>
              </Form>
              <br />
              {success && (
                <Message positive>
                  <Message.Header>Password reset</Message.Header>
                  <p>Password has been reset successfully.</p>
                </Message>
              )}
              {errors.token && (
                <Message negative>
                  <Message.Header>Password reset failed</Message.Header>
                  <p>Please go to forgot password</p>
                </Message>
              )}
            </Grid.Column>
          </Grid>
        </Container>
      </div>
  )
}

