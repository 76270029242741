export const roundsOptions = [
    { key: 'Round 1', text: 'Round 1', value: 'Round 1' },
    { key: 'Round 2', text: 'Round 2', value: 'Round 2' },
    { key: 'Round 3', text: 'Round 3', value: 'Round 3' },
    { key: 'Round 4', text: 'Round 4', value: 'Round 4' },
    { key: 'Round 5', text: 'Round 5', value: 'Round 5' },
    { key: 'Round 6', text: 'Round 6', value: 'R' }, 
    { key: 'Round 7', text: 'Round 7', value: 'Round 7' },
    { key: 'Round 8', text: 'Round 8', value: 'Round 8' },
    { key: 'Round 9', text: 'Round 9', value: 'Round 9' },
    { key: 'Round 10', text: 'Round 10', value: 'Round 10' },
];


export const interviewType = ['Video', 'Telephonic', 'In Office']

export const durationOptions = [
    { key: "15", text: "15 minutes", value: 15 },
    { key: "30", text: "30 minutes", value: 30 },
    { key: "45", text: "45 minutes", value: 45 },
    { key: "60", text: "1 hour", value: 60 },
    { key: "90", text: "1.30 hours", value: 90 },
    { key: "120", text: "2 hours", value: 120 },
    { key: "150", text: "2.30 hours", value: 150 },
    { key: "180", text: "3 hours", value: 180 },
    { key: "210", text: "3.30 hours", value: 210 },
    { key: "240", text: "4 hours", value: 240 },
    { key: "270", text: "4.30 hours", value: 270 },
    { key: "300", text: "5 hours", value: 300 },
    // Add more duration options as needed
  ];

export const interviewStatusOption = [
    {key: null, text: "Select status", value: null, disabled: true},
    {key: 'To be scheduled', text: 'To be scheduled', value: 'To be scheduled'},
    {key: 'Scheduled', text: 'Scheduled', value: 'Scheduled'},
    {key: 'Completed', text: 'Completed', value: 'Completed'},
]