import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
  Dropdown,
  Header,
  AccordionTitle,
  AccordionContent,
  Accordion,
  MenuItem,
} from "semantic-ui-react";
import HireLogo from "../../../src/new-logo.svg";
import { logout } from "../../actions/action_auth";
import "./nav.css";
import NeedHelp from "./NeedHelp";

function MobileNav() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const user = useSelector((state) => state.user?.user) || null;
  const { auth: isAuthenticated, role: userRole, clientId } = user;

  const handleLogout = () => {
    dispatch(logout());
    setSidebarVisible(false);
  };

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const { pathname } = useLocation();
  const isClientAboutPage = pathname.endsWith("/client/about");

  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };
  return (
    <>
      <Sidebar
        as={Menu}
        animation="overlay"
        direction="right"
        onHide={() => {
          setSidebarVisible(false), handleClick(null);
        }}
        vertical
        visible={sidebarVisible}
        className="mobile-sidebar"
      >
        {!isAuthenticated ? (
          <></>
        ) : (
          <>
            {userRole === "client" && (
              <>
                <Menu.Item
                  as={NavLink}
                  to={`/job-analytics/${clientId}`}
                  onClick={() => setSidebarVisible(false)}
                >
                  Job Analytics
                </Menu.Item>
                <Menu.Item
                  as={NavLink}
                  to={`/InterviewList`}
                  onClick={() => setSidebarVisible(false)}
                >
                  My Interviews
                </Menu.Item>
              </>
            )}
            {userRole !== "client" && (
              <MenuItem>
                <Accordion fluid>
                  <Accordion.Title
                    active={activeIndex === 0}
                    index={0}
                    onClick={() => handleClick(0)}
                    style={{ color: "white", fontSize: "16px" }}>
                    Analytics
                    <Icon name="dropdown" />
                  </Accordion.Title>
                  <Accordion.Content
                    style={{ borderLeft: "1px solid #21c8aa" }}
                    active={activeIndex === 0}
                  >
                    <Dropdown.Item
                      as={NavLink}
                      to="/"
                      onClick={() => {
                        handleClick(null);
                        setSidebarVisible(false);
                      }}
                    >
                      Dashboard
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={NavLink}
                      to="/daily-report"
                      onClick={() => {
                        handleClick(null);
                        setSidebarVisible(false);
                      }}
                    >
                      Ops Dashboard
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={NavLink}
                      to="/job-analytics"
                      onClick={() => {
                        handleClick(null);
                        setSidebarVisible(false);
                      }}
                    >
                      Job Analytics
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={NavLink}
                      to="/admin-dashboard"
                      onClick={() => {
                        handleClick(null);
                        setSidebarVisible(false);
                      }}
                    >
                      Admin Dashboard
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={NavLink}
                      to="/candidate-report"
                      onClick={() => {
                        handleClick(null);
                        setSidebarVisible(false);
                      }}
                    >
                      Candidate Report
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={NavLink}
                      to="/InterviewList"
                      onClick={() => {
                        handleClick(null);
                        setSidebarVisible(false);
                      }}
                    >
                      Interview List
                    </Dropdown.Item>
                  </Accordion.Content>
                </Accordion>
              </MenuItem>
            )}
            {!isClientAboutPage && (
              <Menu.Item
                as={NavLink}
                to="/jobs"
                onClick={() => setSidebarVisible(false)}
                style={{ fontSize: "16.8px" }}
              >
                Jobs
              </Menu.Item>
            )}

            {userRole == "client" ? <NeedHelp /> : null}

            {userRole !== "client" && (
              <Menu.Item
                as={NavLink}
                to="/talent-pool"
                onClick={() => setSidebarVisible(false)}
                style={{ fontSize: "16.8px" }}
              >
                Talent Pool
              </Menu.Item>
            )}
            <MenuItem>
              <Accordion fluid>
                <Accordion.Title
                  active={activeIndex === 1}
                  index={1}
                  onClick={() => handleClick(1)}
                  style={{ color: "white", fontSize: "16px", padding: "0px" }}
                >
                  {userRole === "Admin" ? "Admin" : user.first_name}
                  <Icon name="dropdown" />
                </Accordion.Title>
                <Accordion.Content
                  style={{ borderLeft: "1px solid #21c8aa" }}
                  active={activeIndex === 1}
                >
                  {userRole === "Admin" && (
                    <>
                      <Dropdown.Item
                        as={NavLink}
                        to="/team-members"
                        onClick={() => {
                          handleClick(null);
                          setSidebarVisible(false);
                        }}
                      >
                        Users
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={NavLink}
                        to="/add-client"
                        onClick={() => {
                          handleClick(null);
                          setSidebarVisible(false);
                        }}
                      >
                        Customer & Branding
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={NavLink}
                        to="/add-pod"
                        onClick={() => {
                          handleClick(null);
                          setSidebarVisible(false);
                        }}
                      >
                        Pods
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={NavLink}
                        to="/curation"
                        onClick={() => {
                          handleClick(null);
                          setSidebarVisible(false);
                        }}
                      >
                        Add Scorecard Template
                      </Dropdown.Item>
                    </>
                  )}
            {userRole === "Admin" &&         

                  <Dropdown.Item
                    as={NavLink}
                    to="/changepassword"
                    onClick={() => {
                      handleClick(null);
                      setSidebarVisible(false);
                    }}
                  >
                    Change Password
                  </Dropdown.Item> }
                  {userRole === "client" && (
                    <>
                      {/* <Dropdown.Divider /> */}
                      <Dropdown.Item
                        as={NavLink}
                        to="/client/settings"
                        onClick={() => {
                          handleClick(null);
                          setSidebarVisible(false);
                        }}
                      >
                        Account Settings
                      </Dropdown.Item>
                      <Dropdown.Divider />
                    </>
                  )}
                  <Dropdown.Item
                    onClick={() => {
                      handleClick(null);
                      setSidebarVisible(false);
                      handleLogout();
                    }}
                  >
                    Logout
                  </Dropdown.Item>
                </Accordion.Content>
              </Accordion>
            </MenuItem>
            {/* <Menu.Item
              as={NavLink}
              to="/inbox"
              onClick={() => setSidebarVisible(false)}
              style={{ display: "flex", justifyContent: "left" }}
            >
              <Icon name="mail" size="big" />
            </Menu.Item> */}
          </>
        )}
        {/* </Sidebar> */}
      </Sidebar>

      <Sidebar.Pusher dimmed={sidebarVisible}>
        {/* <Segment inverted> */}
        <Menu inverted pointing secondary className="side-nav-bar">
          <Menu.Item
            as="a"
            header
            style={{ display: "flex", justifyContent: "left" }}
          >
            <Image src={HireLogo} style={{ width: "70px", height: "auto" }} />
          </Menu.Item>

          {isAuthenticated && (
            <Menu.Menu position="right">
              <Menu.Item onClick={toggleSidebar}>
                {!sidebarVisible ? (
                  <Icon
                    name="sidebar"
                    size="large"
                    color="white"
                    style={{ margin: "0px" }}
                  />
                ) : (
                  <Icon
                    name="close"
                    size="large"
                    color="white"
                    style={{ margin: "0px" }}
                  />
                )}
              </Menu.Item>
              {/* <Menu.Item onClick={handleLogout}>Logout</Menu.Item> */}
            </Menu.Menu>
          )}
        </Menu>
        {/* </Segment> */}
      </Sidebar.Pusher>
    </>
  );
}

export default MobileNav;
