import React, { Component, useState, useEffect } from "react";
import {
  Image,
  Card,
  CardDescription, CardContent
} from "semantic-ui-react";
import SignUpManImg from "../../../../images/svg/signup-man-img.svg"
import "./hirethebest.css";
import ArcanaImg from "../../../../images/svg/arcana-img.svg"
import SignUpFormIcon from "../../../../images/svg/sign-up-form-icon.svg"



const HireTheBest = () => {
  return (
    <>
      <h1 className="hire-text">HIRE THE BEST, 
        <br />WHEREVER THEY ARE</h1>
       <div>
        <p className="icon-wala-text"> <span><img style={{width:'25px', marginRight:'10px', position:'relative', top:'5px'}} src={SignUpFormIcon} /></span>AI powered platform managed by our talent partners</p>
        <p className="icon-wala-text"> <span><img style={{width:'25px', marginRight:'10px', position:'relative', top:'5px'}} src={SignUpFormIcon} /></span>Market intelligence for enhanced hiring decisions</p>
        <p className="icon-wala-text"> <span><img style={{width:'25px', marginRight:'10px', position:'relative', top:'5px'}} src={SignUpFormIcon} /></span>Curated scorecards evaluated across 40+ dimensions</p>
      </div>
      <Card
      className="signup-form-card"
  style={{width:'100%', marginTop:'30px'}}
  >
    <CardDescription className="card-description" style={{margin:'15px'}}>“They are professional, responsive and work round the clock to satisfy the <strong>fast hiring</strong> need of a startup. The team is able to <strong>understand the quality bar and work hard</strong> with you like your inhouse HR team.”</CardDescription>
    <CardContent>
      <div style={{display:'flex', justifyContent:'space-between'}}>
<div>
<div style={{display:'flex'}}>
<Image src={SignUpManImg} style={{width:'20%', margin:'10px'}} />
<div style={{display:'flex', margin:'auto 0px', flexDirection:'column'}}>
  <p className='man-img-text man-img-text-1'>Siva Visakan Sooriyan</p>
  <p className='man-img-text man-img-text-2'>Founder & CTO, Arcana</p>
</div>
</div>
</div>
<div>
  <Image
floated="right"
style={{width:'60%', margin:'auto 0px', display:'flex', alignItems:'center', justifyContent:'center', position:'relative', top:'10px'}}
src={ArcanaImg} /></div>
      </div>
    </CardContent>
  </Card>
  </>
  )
}

export default HireTheBest