import React, { Component } from "react";
import {
  Form,
  Checkbox,
  Button,
  Container,
  Grid,
  Message,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { signin } from "./../actions/action_auth";
import { setBasicCompanyId } from "./../actions/action_company";
import Validator from "validator";
import InlineError from "./../messages/InlineError";
import Navbar from "./../components/navbar/nav.js";
import SiginForm from "../components/candidate/Forms/Sigin-form";
import NewSigninForm from "../components/candidate/Forms/Signin-form-new";
// import "../pages/talentCard.css";
class Signin extends Component {
  // state = {
  //   data: {
  //     email: "",
  //     password: "",
  //   },
  //   loading: false,
  //   errors: {},
  // };

  // onChange = (e) =>
  //   this.setState({
  //     data: { ...this.state.data, [e.target.name]: e.target.value },
  //   });

  // onSubmit = () => {
  //   const errors = this.validate(this.state.data);
  //   console.log("Error valid", errors);

  //   console.log("onSubmit +++", this.state.data);
  //   this.setState({ errors });

  //   if (Object.keys(errors).length === 0) {
  //     console.log("If no errror +++", this.state.data);
  //     this.setState({ loading: true });

  //     this.props
  //       .signin(this.state.data)
  //       .then((res) => {
  //         console.log("after Api +++", res);
  //         if (res.payload.company) {
  //           this.props.setBasicCompanyId(res.payload.company);
  //           this.props.history.push("/jobs");
  //         } else {
  //           this.props.history.push("/dashboard");
  //         }
  //       })

  //       .catch((error) => {
  //         this.setState({ loading: false });
  //         if (error.response.data.error) {
  //           this.setState({
  //             errors: {
  //               ...this.state,
  //               email: error.response.data.error,
  //             },
  //           });
  //         }
  //         if (error.response.data.error_password) {
  //           this.setState({
  //             errors: {
  //               ...this.state,
  //               password: error.response.data.error_password,
  //             },
  //           });
  //         }
  //         if (error.response.data.error_active) {
  //           this.setState({
  //             errors: {
  //               ...this.state,
  //               error_active: error.response.data.error_active,
  //             },
  //           });
  //         }
  //       });
  //   }
  // };

  // validate = (data) => {
  //   const errors = {};
  //   console.log(errors, "Erros");
  //   if (!Validator.isEmail(data.email)) errors.email = "Invalid email";
  //   if (!data.password) errors.password = "Password is required!";

  //   return errors;
  // };

  render() {
    // console.log("data +++", this.state.data);

    // const { data, errors, loading } = this.state;
    return (
      <div className="half-bg-blue">
        {/* <div style={{ paddingBottom: "20px" }}> */}
          <Navbar />
        {/* </div> */}

        <Container
          style={{ height: "100vh", alignItem: "center", display: "flex" }}
        >
          {/* <Grid>
            <Grid.Column computer={4}> */}
          <div className="signinform" style={{ margin: "6% auto" }}>
            <NewSigninForm />
          </div>
          {/* </Grid.Column> */}
          {/* </Grid> */}
          {/* <Grid centered columns={2}>
            <Grid.Column>
              <br />
              {errors.error_active && (
                <Message negative>
                  <Message.Header>Email is not activated</Message.Header>
                  <p>
                    We have send a mail to your registered email. Please check
                    and activate
                  </p>
                </Message>
              )}
              <Form onSubmit={this.onSubmit}>
                <Form.Field error={!!errors.email}>
                  <label style={{ color: "#003458" }}>Email</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    value={data.email}
                    onChange={this.onChange}
                  />
                  {errors.email && <InlineError text={errors.email} />}
                </Form.Field>
                <Form.Field error={!!errors.password}>
                  <label style={{ color: "#003458" }}>Password</label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    value={data.password}
                    onChange={this.onChange}
                  />
                  {errors.password && <InlineError text={errors.password} />}
                </Form.Field>

                <Button
                  floated="right"
                  type="submit"
                  style={{
                    color: "white",
                    background: "#21C8AA",
                    width: "90px",
                    height: "36px",
                  }}
                  disabled={loading}
                >
                  {loading ? (
                    <Loader size="tiny" active inline="centered" />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Form>
              <br />
              <br />
              <center>
                <NavLink to="/forgot-password">
                  <h5 style={{ color: "#003458" }}>Forgot password ?</h5>
                </NavLink>
              </center>
            </Grid.Column>
          </Grid> */}
        </Container>
      </div>
    );
  }
}

Signin.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  signin: PropTypes.func,
  company_id_in_user_obj: PropTypes.string,
  company_id: PropTypes.string,
};

function mapStateToProps(state) {
  if (state.user && state.company.company_basic_detail) {
    return {
      company_id_in_user_obj: state.user.company_id,
      company_id: state.company.company_basic_detail.company_id,
    };
  }
  return {};
}

export default connect(mapStateToProps, { signin, setBasicCompanyId })(Signin);
