import axios from './axiosInstance'
import {getHeader} from './../types.js'
import axios2 from 'axios'

export default {
  company : {
    create : data =>
            axios.post(`/api/company/create/${data.userId}`, data, { headers: getHeader()}).then(res => res.data),
    update : (data, id) =>
            axios.put(`/api/company/update/${id}`, data, { headers: getHeader()}).then(res => res.data),
    findTotalNumberOfJobsApi: id =>
            axios.get(`/api/job/totalnumbersofjobs/${id}`, { headers: getHeader()}).then(res => res.data),
    getCompanyApi : (id) =>
            axios.get(`/api/company/companyDetails/${id}`, { headers: getHeader()}).then(res => res.data),
    AddMemberApi: (companyId, data) =>
            axios.post(`/api/company/member/${companyId}`, data, { headers: getHeader()}).then(res => res?.data),
    getTeamMmbersApi: (id) =>
            axios.get(`/api/company/member/${id}`, { headers: getHeader()}).then(res => res.data),
    deleteMemberApi: (id) => 
            axios.delete(`/api/auth/user/${id}`, { headers : getHeader()}).then(res => res.data),
    editMemberApi: (id, data) =>{
  console.log("id and data from editMemberApi : ", id, data, "headers : ", getHeader())
            return axios.patch(`/api/auth/user/${id}`, data, 
                { headers: getHeader()}
        )
            .then(res => {
                console.log("res from editMemberApi : ", res);
                return res?.data})
    }
  }
}
