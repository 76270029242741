import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link, Navigate, useNavigate } from "react-router-dom";

import {
  Grid,
  Card,
  Header,
  Input,
  Dropdown,
  Button,
  Popup,
} from "semantic-ui-react";
import {
  Addscorecard,
  Getscorecard,
  fetchCandidateByJobId,
} from "../../../actions/action_application";
import { Updatescorecard } from "../../../actions/action_application";
import JobListingModal from "../../jobs/JobListingModal/index";
// import CurationShow from "./Curation-show";
import Axios from "axios";
import { UpdateTemplate } from "../../../actions/action_job";
import curation from "./Compensation";

const Template = ({
  templatedata,
  handleClose,
  showeditbtn,
  curationData,
  showjobcurationbtn,
}) => {
  const [data, setData] = useState(templatedata);

  const { cand_id, job_id } = useParams();

  const [formerror, setFormerror] = useState({});
  const [disableButton, setDisableButton] = useState();
  const [loading, setLoading] = useState(false);

  

  const dispatch = useDispatch();
  const [isJobListingModal, setIsJobListingModal] = useState(false);

  const validate = (values) => {
    const errors = {};
    if (!values.scorecard_name) {
      errors.scorecard_name = "Scorecard name is required";
    }
    if (!values.engagement_level) {
      errors.engagement_level = "Engagement Level is required";
    }
    // if (!values.salary_match) {
    //   errors.salary_match = "salary required";
    // }

    if (values.avg_compensation[0].min > values.avg_compensation[0].max) {
      errors.avg_compensation = "Max value not be less then min";
    }

    if (
      values.expected_compensation[0].min > values.expected_compensation[0].max
    ) {
      errors.expected_compensation = "Max value not be less then min";
    }

    // if (!values.expected_compensation) {
    //   errors.expected_compensation = "expected compensation required";
    // }
    return errors;
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const handleChange_engLevel = (e, value) => {
    setData({ ...data, engagement_level: value.value });
  };

  const avghandleComp = (isMin, value) => {
    if (isMin) {
      setData({
        ...data,
        avg_compensation: [
          {
            ...data.avg_compensation[0],
            min: parseInt(value),
          },
        ],
      });
    } else {
      setData({
        ...data,
        avg_compensation: [
          {
            ...data.avg_compensation[0],
            max: parseInt(value),
          },
        ],
      });
    }
  };

  const exphandleComp = (isMin, value) => {
    if (isMin) {
      setData({
        ...data,
        expected_compensation: [
          {
            ...data.expected_compensation[0],
            min: parseInt(value),
          },
        ],
      });
    } else {
      setData({
        ...data,
        expected_compensation: [
          {
            ...data.expected_compensation[0],
            max: parseInt(value),
          },
        ],
      });
    }
  };

  const submit = (e) => {
    e.preventDefault();
    if (
      !data.scorecard_name ||
      !data.engagement_level ||
      !data.avg_compensation[0].max ||
      !data.expected_compensation
    ) {
      setFormerror(validate(data));
    } else {
      dispatch(Addscorecard(data)).then(setLoading(true));
      dispatch(Getscorecard());
      handleClose();
    }
  };

  const update = (e) => {
    e.preventDefault();
    dispatch(Updatescorecard(data, data._id))
    dispatch(Getscorecard());
    handleClose();
  };
  useEffect(() => {
    if (Object.keys(formerror).length === 0) {
    }
  });


  const updateJobTemplate = (job_id, cand_id, data) => {
    // dispatch(UpdateTemplate(cand_id, job_id, data));
    if (
      !data.scorecard_name ||
      !data.engagement_level ||
      !data.avg_compensation[0].max ||
      !data.expected_compensation
    ) {
      setFormerror(validate(data));
    } else {
      dispatch(UpdateTemplate(cand_id, job_id, data));
      handleClose();
    }
    // dispatch(fetchCandidateByJobId(job_id,cand_id))
  };
  const options = [{ key: 1, text: "LPA", value: 1 }];
  const engagement_levelOptions = [
    {
      key: "Low",
      text: "Low",
      value: "Low",
    },
    {
      key: "Medium",
      text: "Medium",
      value: "Medium",
    },
    {
      key: "High",
      text: "High",
      value: "High",
    },
  ];

  return (
    <div>
      {!curationData ? (
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {showeditbtn == false ? (
              <Button
                style={{
                  border: " 1px solid #21C8AA",
                  background: "none",
                  color: "#21c8aa",
                  fontWeight: "400",
                  textAlign: "center",
                }}
                floated="right"
                onClick={() => {
                  setIsJobListingModal(!isJobListingModal), setData(data);
                }}
              >
                Add To Job 
              </Button>
            ) : null}
            {showeditbtn == false ? (
              <Button onClick={() => setDisableButton(!disableButton)}>
                Edit
              </Button>
            ) : null}
          </div>
          <Card
            fluid
            className="basic-info-dropdown cardui"
            style={{ padding: "4% 2%", height: "100%" }}
          >
            {!curationData ? (
              <Grid>
                <Grid.Column computer={4}>
                  <p>Template Name:-</p>
                </Grid.Column>
                <Grid.Column computer={6}>
                  <Input
                    required
                    fluid
                    type="text"
                    onChange={handleChange}
                    value={data.scorecard_name}
                    name="scorecard_name"
                    placeholder="Designation"
                    disabled={
                      !showeditbtn || !showjobcurationbtn == false ? !disableButton : disableButton
                    }
                    // disabled={showjobcurationbtn == true ? !disableButton : disableButton }
                  />
                  <p color="red">{formerror.scorecard_name}</p>
                </Grid.Column>
              </Grid>
            ) : null}

            <Header as="h4">Compensation Analysis</Header>

            <Card.Content>
              <Grid>
                <Grid.Column computer={8}>
                  <p style={{ paddingBottom: "10px" }}>
                    AVERAGE COMPENSATION RANGE
                  </p>

                  <Grid>
                    <Grid.Column computer={8}>
                      <Grid>
                        <Grid.Column computer={4}>
                          <Dropdown
                            placeholder="Min"
                            compact
                            selection
                            options={options}
                          />
                        </Grid.Column>
                        <Grid.Column computer={12}>
                          <Input
                            style={{ height: "38px" }}
                            size="small"
                            min="0"
                            type="number"
                            onChange={(e, { value }) =>
                              avghandleComp(true, value)
                            }
                            value={data.avg_compensation[0].min}
                            disabled={
                              showeditbtn == false
                                ? !disableButton
                                : disableButton
                            }
                            name="avg_compensation-min"
                          />
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                    <Grid.Column computer={8}>
                      <Grid>
                        <Grid.Column computer={4}>
                          <Dropdown placeholder="Max" compact selection />
                        </Grid.Column>
                        <Grid.Column computer={12}>
                          <Input
                            type="number"
                            min="0"
                            onChange={(e, { value }) =>
                              avghandleComp(false, value)
                            }
                            value={data.avg_compensation[0].max}
                            disabled={
                              showeditbtn == false
                                ? !disableButton
                                : disableButton
                            }
                            name="avg_compensation-max"
                            style={{ height: "38px" }}
                            size="small"
                          />
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                  </Grid>
                  <p>{formerror.avg_compensation}</p>
                </Grid.Column>
                <Grid.Column computer={8}>
                  <p style={{ paddingBottom: "10px" }}>
                    TALENTS EXPECTED COMPENSATION RANGE
                  </p>

                  <Grid>
                    <Grid.Column computer={8}>
                      <Grid>
                        <Grid.Column computer={4}>
                          <Dropdown placeholder="Min" compact selection />
                        </Grid.Column>
                        <Grid.Column computer={12}>
                          <Input
                            onChange={(e, { value }) =>
                              exphandleComp(true, value)
                            }
                            name="expected_compensation"
                            type="number"
                            min="0"
                            value={data.expected_compensation[0].min}
                            disabled={
                              showeditbtn == false
                                ? !disableButton
                                : disableButton
                            }
                            style={{ height: "38px" }}
                            size="small"
                          />
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                    <Grid.Column computer={8}>
                      <Grid>
                        <Grid.Column computer={4}>
                          <Dropdown placeholder="Max" compact selection />
                        </Grid.Column>
                        <Grid.Column computer={12}>
                          <Input
                            onChange={(e, { value }) =>
                              exphandleComp(false, value)
                            }
                            name="expected_compensation"
                            min="0"
                            type="number"
                            value={data.expected_compensation[0].max}
                            disabled={
                              showeditbtn == false
                                ? !disableButton
                                : disableButton
                            }
                            style={{ height: "38px" }}
                            size="small"
                          />
                        </Grid.Column>
                      </Grid>
                      <p>{formerror.expected_compensation}</p>
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Card.Content>
          </Card>

          <Card
            fluid
            className="basic-info-dropdown cardui"
            style={{ padding: "4% 2%", height: "100%" }}
          >
            <Header as="h4">Engagement Level</Header>

            <Card.Content>
              <p style={{ paddingBottom: "10px" }}>
                Talent’s Engagement Level is calculated based on talent’s
                responsiveness, interest in acquiring the role, etc. Please pick
                any one of the three options below..
              </p>

              <Dropdown
                placeholder="Level"
                name="engagement_level"
                type="text"
                openOnFocus
                selection
                // multiple
                onChange={(e, value) => handleChange_engLevel(e, value)}
                options={engagement_levelOptions}
                disabled={showeditbtn == false ? !disableButton : disableButton}
                value={data.engagement_level}
              />
              <p>{formerror.engagement_level}</p>
            </Card.Content>
          </Card>

          <Card
            fluid
            className="basic-info-dropdown cardui"
            style={{ padding: "4% 2%", height: "100%" }}
          >
            <Header as="h4">Bio Fit</Header>

            <Card.Content>
              <Grid columns={2}>
                <Grid.Row stretched>
                  <Grid.Column computer={4}>
                    <p>Salary Match</p>
                    <p>Location Match</p>
                    <p>Notice Period Match</p>
                    <p>Experience Match</p>
                  </Grid.Column>

                  <Grid.Column computer={12}>
                    <Input
                      name="salary_match"
                      type="text"
                      fluid
                      placeholder="Add comment here..."
                      onChange={handleChange}
                      value={data.salary_match}
                      disabled={
                        showeditbtn == false ? !disableButton : disableButton
                      }
                    />
                    <br />
                    <Input
                      name="location_match"
                      type="text"
                      fluid
                      onChange={handleChange}
                      placeholder="Add comment here..."
                      value={data.location_match}
                      disabled={
                        showeditbtn == false ? !disableButton : disableButton
                      }
                    />
                    <br />
                    <Input
                      name="notice_period_match"
                      type="text"
                      fluid
                      onChange={handleChange}
                      placeholder="Add comment here..."
                      value={data.notice_period_match}
                      disabled={
                        showeditbtn == false ? !disableButton : disableButton
                      }
                    />
                    <br />
                    <Input
                      name="experience_match"
                      type="text"
                      fluid
                      onChange={handleChange}
                      placeholder="Add comment here..."
                      value={data.experience_match}
                      disabled={
                        showeditbtn == false ? !disableButton : disableButton
                      }
                    />
                    <br />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Card.Content>
          </Card>

          {!showjobcurationbtn ? (
            <Button
              content={showeditbtn == true ? "add" : " save"}
              labelPosition="right"
              loading={loading}
              onClick={showeditbtn == true ? submit : update}
              icon="checkmark"
              type="submit"
              floated="right"
              positive
            />
          ) : (
            <Button
              floated="right"
              onClick={() => updateJobTemplate(job_id, cand_id, data)}
            >
              Update
            </Button>
          )}

          <JobListingModal
            open={isJobListingModal}
            close={() => setIsJobListingModal(!isJobListingModal)}
            data={data}
          />
        </div>
      ) : (
        <CurationShow data={data} curationData={curationData} />
      )}
    </div>
  );
};

export default Template;
