import axios from "./axiosInstance";
import { getHeader } from "./../types.js";

export default {
  job: {
    createJobApi: (data) =>
      axios
        .post(`/api/job/create`, data, {
          headers: getHeader(),
        })
        .then((res) => res.data),
    getJobByIdApi: (id) =>
      axios
        .get(`/api/career/jobs/${id}`, { headers: getHeader() })
        .then((res) => res.data),
    editJobByIdStart: (data, id) =>
      axios
        .put(`/api/job/edit/job_title/${id}`, data, { headers: getHeader() })
        .then((res) => res.data),
    UpdateCurrentJobApi: (data) =>
      axios
        .put(`/api/job/update/${data.job_id}`, data, { headers: getHeader() })
        .then((res) => res.data),
    PublishCurrentJobApi: (data) =>
      axios
        .put(`/api/job/publish/${data.job_id}`, data, { headers: getHeader() })
        .then((res) => res.data),
    DeleteJobApi: (id) =>
      axios
        .delete(`/api/job/delete/${id}`, { headers: getHeader() })
        .then((res) => res.data),
    GetAllJobsApi: (data, cancelToken) =>
      axios
        .post(
          `/api/job/filter`,
          data,
          { headers: getHeader() }
        )
        .then((res) => res.data),
    editJobByIdApi: (id) =>
      axios
        .get(`/api/job/edit/${id}`, { headers: getHeader() })
        .then((res) => res.data),

    filterJobApi: (page, data) =>
      axios
        .post(
          `/api/job/filter?page=${page}`,
          data,
          { headers: getHeader() }
        )
        .then((res) => {
          console.log("ruk idhar aya hu : page : ", page, "res.data : ", res.data)
          return res.data}),


    getJobApplicationsApi: (id, data) =>
      axios
        .post(`/api/job/applications/${id}`, data, { headers: getHeader() })
        .then((res) => res.data),
    moveCandidateStageApi: (job_id, app_id, stage) =>
      axios
        .put(`/api/job/${job_id}/application/${app_id}`, stage, {
          headers: getHeader(),
        })
        .then((res) => res.data),
    rejectCandApi: (job_id, app_id, data) =>
      axios
        .put(`/api/job/${job_id}/application/reject/${app_id}`, data, {
          headers: getHeader(),
        })
        .then((res) => res.data),
    shouldJobBeClosedApi: (id) =>
      axios
        .put(`/api/job/closeJob/${id}`, id, { headers: getHeader() })
        .then((res) => res.data),
    JobOpenApi: (id) =>
      axios
        .put(`/api/job/openJob/${id}`, id, { headers: getHeader() })
        .then((res) => res.data),
    addQuestionToJobApi: (id, question) =>
      axios
        .put(
          `/api/job/question/${id}`,
          { question: question },
          { headers: getHeader() }
        )
        .then((res) => res.data),
    deleteQuestionNowApi: (id, question_id) =>
      axios
        .delete(`/api/job/question/${id}/${question_id}`, {
          headers: getHeader(),
        })
        .then((res) => res.data),
    addDataToTalentPool: (data) =>
      axios
        .post("/api/talentpool/addtalent", data, { headers: getHeader() })
        .then((res) => res.data),
    candidateDeleteFromJobApi: (applicationId, jobId) =>
      axios
        .delete(`/api/job/${jobId}/candidate/${applicationId}`, {
          headers: getHeader(),
        })
        .then((res) => res.data),
    documentDeleteFromJobApi: (documentId, jobId, applicationId) => {
      //     console.log(documentId,"--------------------------",applicationId)
      return axios
        .delete(`/api/job/${jobId}/document/${applicationId}/${documentId}`, {
          headers: getHeader(),
        })
        .then((res) => res.data);
    },
    addTemplate: ( job_id, data) =>
      axios.post(`/api/talentpool/addTemplate-job/${job_id}`,data, {
          header: getHeader(),
        })
        .then((res) => res.data),
        
     updateTemplate: ( job_id,cand_id, data) =>
        axios.put(`/api/talentpool/updateTemplate/${cand_id}/${job_id}`,data, {
            header: getHeader(),
          })
          .then((res) => res.data),
  },
};
