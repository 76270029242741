import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
// import 'react-select/dist/react-select.css';
import App from "./App";

import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers/index";

import "semantic-ui-css/semantic.css";

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
// if (window.localStorage.getItem("accessToken")) {
//   const user = window.localStorage.getItem("accessToken");
//   axios
//     .post("/api/auth/me", { token: JSON.parse(user) })
//     .then((user) => {
//       store.dispatch(SignIn(user.data));
//       // store.dispatch(setBasicCompanyId(user.data.company));
//     })
//     .catch((error) => {
//       console.log(error.response);
//     });
// }
// *****************

console.warn("store data", store);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      {/* {localStorage.authUser && */}
      <BrowserRouter>
      <React.Profiler>
        <App />
      </React.Profiler>
      </BrowserRouter>
      {/* } */}
    </Provider>
  // </React.StrictMode>
);
