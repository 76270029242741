import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  Form,
  Modal,
  Icon,
  Header,
  Image,
  Grid,
  Column,
  Segment,
} from "semantic-ui-react";
import { publishCurrentJob } from "./../../actions/action_job";
import RawHtml from "react-raw-html";
import SkillItem from "./skillItem";
import "./editor.css";
import "../../App.css";

class JobPublish extends Component {
  state = {
    data: {
      active: "",
      job_id: this.props.job_id,
    },
    open: false,
    errors: {},
    loading: false,
  };

  handleSubmit = (e) => {
    let newData = {
      ...this.state.data,
      active: true,
      job_id: this.props.job_id,
    };
    if (newData) {
      this.props.publishCurrentJob(newData).then((res) => {
        if (res) {
          this.props.successJobPostedRedirect();
        }
      });
    }
  };

  show = (dimmer) => () => this.setState({ dimmer, open: true });
  close = () => this.setState({ open: false });

  render() {
    console.log("job publish ", this.props);
    const { dimmer, open } = this.state;
    const { company } = this.props;

    if (Object.keys(company).length === 0) {
      return null;
    }

    const skill = company.skills.map((skill) => {
      return <SkillItem key={skill.value} skill={skill} />;
    });

    return (
      <div className="job_posting_form">
        <Segment attached="top" id="headerbackground">
          <h4>Publish Job</h4>
        </Segment>
        <Segment attached>
          <h3>Publish job :</h3>
          {/* <center><Button onClick={this.show(true)} style={{marginBottom : '20px', marginTop : '100px'}}>Preview current job</Button></center> */}
          {/* <Form onSubmit={this.handleSubmit}> */}
          <br />
          <div style={{ textAlign: "center" }}>
            <Button.Group>
              <center>
                <Button
                  default
                  onClick={() =>
                    this.props.goToJobPostingDetail(this.props.job_id)
                  }
                >
                  Previous
                </Button>
                <Button
                  type="submit"
                  onClick={this.handleSubmit}
                  style={{
                    background: "#21c8aa",
                  }}
                  positive
                >
                  Publish
                </Button>
                <Button onClick={this.show(true)} default>
                  Preview current job
                </Button>
              </center>
            </Button.Group>
          </div>
          {/* </Form> */}
          <Modal
            dimmer={dimmer}
            open={open}
            onClose={this.close}
            style={{
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
            }}
          >
            <Modal.Header id="headerbackground">Preview</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <Header>
                  <h2>{company.job_title}</h2>
                </Header>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={6}>
                      <h4>
                        Employement type :{" "}
                        <span style={{ fontSize: "13px" }}>
                          {company.employment_type}
                        </span>
                      </h4>
                      <h4>
                        Industry :{" "}
                        <span style={{ fontSize: "13px" }}>
                          {company.industry}
                        </span>
                      </h4>
                      <h4>
                        Experience :{" "}
                        <span style={{ fontSize: "13px" }}>
                          {company.min_experience}-{company.max_experience}{" "}
                          years
                        </span>
                      </h4>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <h4>
                        Location :{" "}
                        <span style={{ fontSize: "13px" }}>
                          {company.job_location}
                        </span>
                      </h4>
                      <h4>
                        Function :{" "}
                        <span style={{ fontSize: "13px" }}>
                          {company.functional_area}
                        </span>
                      </h4>
                      <h4>
                        Salary range :{" "}
                        <span style={{ fontSize: "13px" }}>
                          {company.min_salary}-{company.max_salary} Lakhs
                        </span>
                      </h4>
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <center>
                        <Image
                          src="http://www.personalbrandingblog.com/wp-content/uploads/2017/08/blank-profile-picture-973460_640-300x300.png"
                          style={{ width: "60px" }}
                          circular
                        />
                      </center>
                      <br />
                      <b>
                        Posted by :{" "}
                        <span style={{ fontSize: "12px" }}>
                          {this.props.first_name} {this.state.last_name}
                        </span>
                      </b>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <br />
                <Grid>
                  <Grid.Row>
                    <br />
                    <Grid.Column width={2}>
                      <h4>Skills : </h4>
                    </Grid.Column>
                    <Grid.Column width={14}>{skill}</Grid.Column>
                  </Grid.Row>
                </Grid>

                <Grid>
                  <Grid.Row>
                    <Grid.Column width={12} style={{ marginTop: "20px" }}>
                      <h4>Job Description : </h4>
                      <RawHtml.div>{company.job_description}</RawHtml.div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button
                positive
                onClick={this.close}
                style={{ background: "#21c8aa" }}
              >
                Ok
              </Button>
              {/* <Button positive icon='checkmark' labelPosition='right' content="Yep, that's me" onClick={this.close} /> */}
            </Modal.Actions>
          </Modal>
        </Segment>
      </div>
    );
  }
}

JobPublish.propTypes = {
  publishCurrentJob: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    company: state.job.current_job,
    first_name: state.user.first_name,
    last_name: state.user.last_name,
    job_id: state.job.current_job._id,
  };
}

export default connect(mapStateToProps, { publishCurrentJob })(JobPublish);
