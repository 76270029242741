import React, { Component } from "react";
import "../pages/loadingpage.css";

class Loadingpage extends Component {
  render() {
    return (
      <div id="container" className="contain">
        <div className="divider dividerrr" aria-hidden="true"></div>
        <p className="loading-text" aria-label="Loading">
          <span
            className="letter"
            style={{ lineHeight: "1.6" }}
            aria-hidden="true"
          >
            L
          </span>
          <span className="letter" aria-hidden="true">
            o
          </span>
          <span className="letter" aria-hidden="true">
            a
          </span>
          <span className="letter" aria-hidden="true">
            d
          </span>
          <span className="letter" aria-hidden="true">
            i
          </span>
          <span className="letter" aria-hidden="true">
            n
          </span>
          <span className="letter" aria-hidden="true">
            g
          </span>
        </p>
      </div>
    );
  }
}

export default Loadingpage;
