import { GET_LOCATION_OPTION, GET_ROLE_OPTION, GET_POD_GROUP_OPTION, GET_TAG_OPTION } from "../types";
const initialState = {
  locationOptions: [],
  roleOptions: {
    standardRole: [],
    subCategories: [],
  },
  tags: [],
  podGroupOptions: []
};
export default function options(state = initialState, action = {}) {
  switch (action.type) {
    case GET_LOCATION_OPTION:
      // console.log("action data option", action.data);
      return {
        ...state,
        locationOptions: action.data,
      };
    case GET_ROLE_OPTION:
      // console.log("action data option", action.data);

      //standardized role array
      // return { key, label, text} format
      let standardRole = action.data.map((role) => {
        return {
          key: role._id,
          value: role.standardizedRole,
          text: role.standardizedRole,
        };
      });

      return {
        ...state,
        roleOptions: {
          standardRole,
          subCategories: action.data,
        },
      };

      case GET_TAG_OPTION:
      console.log("action data option", action.data);

      //standardized role array
      // return { key, label, text} format
      let standardTag = action.data.map((tag, key) => {
        return {
          key,
          value: tag,
          label: tag,
        };
      });

      // console.log("standard role", standardRole);

      return {
        ...state,
        tags: standardTag
      };
      case GET_POD_GROUP_OPTION:
        // console.log("action data option", action.data);
        return {
          ...state,
          podGroupOptions: action.data,
        };
      default:
      return state;
      break;
  }
}
