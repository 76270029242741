import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Modal,
  Form,
  Input,
  Dropdown,
  Button,
  TextArea,
} from "semantic-ui-react";
import CreatableSelect from "react-select/creatable";
import {
  roundsOptions,
  interviewType,
  durationOptions,
} from "./interviewRoundData";
// import InterviewRound from "./InterviewRound";
import { googleCalendarEventUrl } from "google-calendar-url";
import "../index.css";
import axios from "axios";
import { useParams } from "react-router";
import { getJobById } from "../../../actions/action_job";
import {
  getInterviewList,
  moveCandidate,
} from "../../../actions/action_application";

const SetupInterviewModal = ({ open, close, interviewData, isEditable }) => {
  const currentJobDetail = useSelector((state) => state.job?.current_job);
  const { job_id, cand_id } = useParams();
  const dispatch = useDispatch();
  const jobLocation = useSelector(
    (state) => state.job?.current_job?.job_location
  );
  const appId = useSelector(
    (state) => state.application?.application_stage?._id
  );
  const companyId = currentJobDetail?.assignedClient?.client_id;
  const candidateEmail = useSelector(
    (state) => state.application?.individual_candidate?.bio.email
  );
  const candidateName = useSelector(
    (state) => state.application?.individual_candidate?.bio.fullName
  );
  const candidateId = useSelector(
    (state) => state.application?.individual_candidate?.bio._id
  );
  const podGroup = useSelector(
    (state) => state.application?.individual_candidate?.veriStat?.pod_group
  );
  const allUser = useSelector((state) => state.client.allCompanyUser);
  const user = useSelector((state) => state.user.user);
  const [loading, setloading] = useState(false);
  const assignedRecuiter = currentJobDetail?.assignedRecruiter;
  const [name, setName] = useState(candidateName);
  const [companyDetail, setCompanyDetail] = useState({
    role: currentJobDetail?.job_title,
    company: currentJobDetail?.assignedClient?.client_name,
  });
  const [interviewDetail, setInterviewDetail] = useState({
    interviewer: [],
    interviewInvites: assignedRecuiter?.map((recruiter) => ({
      key: recruiter.id,
      value: recruiter.value,
      label: recruiter.label,
    })),
  });

  const userName = `${user.first_name} ${user.last_name}`;

  const interviewInvitesEmail = interviewDetail?.interviewInvites?.map(
    (recruiter) => recruiter.value || []
  );
  const [interviewRounds, setInterviewRounds] = useState({
    selectedRound: "",
    roundDetails: "",
  });
  const [roundType, setRoundType] = useState("");
  const [interviewDuration, setInterviewDuration] = useState(null);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState({
    TimeFrom: null,
    TimeTill: null,
  });
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({
    selectedRound: "",
    roundDetails: "",
    date: "",
    startTime: "",
    interviewType: "",
    interviewDuration: "",
  });

  const handleCompanyDetail = (e, { name, value }) => {
    setCompanyDetail((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleInterviewerChange = (e) => {
    const Interview = [];
    e.map((invite) => Interview.push(invite.value));

    setInterviewDetail((prevData) => ({
      ...prevData,
      interviewer: [...Interview],
    }));
  };

  const handleInterviewerInvitesChange = (selectedOptions) => {
    setInterviewDetail((prevData) => ({
      ...prevData,
      interviewInvites: selectedOptions,
    }));
  };

  const handleSelectedRound = (e, { value }) => {
    setInterviewRounds((prevData) => ({
      ...prevData,
      selectedRound: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      selectedRound: "",
    }));
  };

  const handleRoundDetail = (e) => {
    setInterviewRounds((prevData) => ({
      ...prevData,
      roundDetails: e.target.value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      roundDetails: "",
    }));
  };

  const handleInterviewType = (option) => {
    setRoundType(option);

    setErrors((prevErrors) => ({
      ...prevErrors,
      interviewType: "",
    }));
  };

  const handleInterviewDuration = (e, { value }) => {
    setInterviewDuration(value);

    setErrors((prevErrors) => ({
      ...prevErrors,
      interviewDuration: "",
    }));
  };

  const handleDatePicker = (e, { value }) => {
    setDate(value);

    setErrors((prevErrors) => ({
      ...prevErrors,
      date: "",
    }));
  };

  const handleFromTime = (e) => {
    const fromTime = e.target.value;
    setTime((prevTime) => ({
      ...prevTime,
      TimeFrom: fromTime,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      startTime: "",
    }));
  };

  const handleTillTime = (e) => {
    setTime((prevTime) => ({
      ...prevTime,
      TimeTill: e.target.value,
    }));
  };

  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  const interviewerOptions = interviewDetail?.interviewer?.map((value) => ({
    value,
    label: value,
  }));

  const allUserOptions = allUser.map((user) => ({
    key: user._id,
    value: user.email,
    label: user.first_name + " " + user.last_name,
  }));

  useEffect(() => {
    if (isEditable && interviewData) {
      setloading(true);
      dispatch(getJobById(interviewData.jobId)).then((res) => {
        setloading(false);
      });
    } else {
      setloading(true);
      dispatch(getJobById(job_id)).then((res) => {
        setloading(false);
      });
    }
  }, [cand_id, isEditable, interviewData]);

  useEffect(() => {
    if (interviewData && isEditable) {
      setName(interviewData?.talentAttendees[0]?.name);
      setCompanyDetail({
        role: interviewData?.role,
        company: interviewData?.customerAttendees[0].name,
      });
      setInterviewDetail({
        interviewer: interviewData?.customerAttendees[0].email,
        interviewInvites: interviewData?.talentPartnerAttendees?.map(
          (recruiter) => ({
            key: recruiter.id,
            value: recruiter.email,
            label: recruiter.name ? recruiter.name : recruiter.email,
          })
        ),
      });
      setInterviewRounds((prevData) => ({
        ...prevData,
        selectedRound: interviewData?.interviewRound,
        roundDetails: interviewData?.eventTitle,
      }));
      setRoundType(interviewData?.interviewType);
      setMessage(interviewData?.eventDescription);
      setInterviewDuration(null);
      setDate(null);
      setTime({
        TimeFrom: null,
        TimeTill: null,
      });
    }else {
      setName(candidateName);
      setCompanyDetail({
        role: currentJobDetail?.job_title,
        company: currentJobDetail?.assignedClient?.client_name,
      });
      setInterviewDetail({
        interviewer: [],
        interviewInvites: assignedRecuiter?.map((recruiter) => ({
          key: recruiter.id,
          value: recruiter.value,
          label: recruiter.label,
          fixed: true,
        })),
      });
      setInterviewRounds({
        selectedRound: "",
        roundDetails: "",
      });
      setRoundType([]);
      setInterviewDuration(null);
      setDate(null);
      setTime({
        TimeFrom: null,
        TimeTill: null,
      });
      setMessage("");
    }
  }, [
    candidateName,
    currentJobDetail,
    assignedRecuiter,
    interviewData,
    isEditable,
  ]);

  useEffect(() => {
    if (interviewDuration && time.TimeFrom) {
      const [hoursFrom, minutesFrom] = time.TimeFrom.split(":");
      const durationInMinutes = parseInt(interviewDuration, 10);

      const totalMinutesFrom =
        parseInt(hoursFrom, 10) * 60 + parseInt(minutesFrom, 10);
      const totalMinutesTill = totalMinutesFrom + durationInMinutes;

      const hoursTill = Math.floor(totalMinutesTill / 60);
      const minutesTill = totalMinutesTill % 60;

      const formattedHoursTill = hoursTill.toString().padStart(2, "0");
      const formattedMinutesTill = minutesTill.toString().padStart(2, "0");

      setTime((prevTime) => ({
        ...prevTime,
        TimeTill: `${formattedHoursTill}:${formattedMinutesTill}`,
      }));
    }
  }, [interviewDuration, time.TimeFrom]);

  const attendees = interviewInvitesEmail?.concat(
    interviewDetail.interviewer
      ? interviewDetail.interviewer
      : interviewData?.customerAttendees[0].email,
    candidateEmail ? candidateEmail : interviewData?.talentAttendees[0]?.email
  );
  const talentPartnerAttendees = interviewDetail?.interviewInvites?.map(
    (invites) => ({
      id: invites.key,
      email: invites.value,
      name: invites.label,
    })
  );
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const combinedStartDateTime = `${date}T${time.TimeFrom}:00`;
  const combinedEndDateTime = `${date}T${time.TimeTill}:00`;
  const formattedStartTime = combinedStartDateTime.replace(/[-:]/g, "");
  const formattedEndTime = combinedEndDateTime.replace(/[-:]/g, "");

  console.log(`${formattedStartTime}/${formattedEndTime}`, "dates");

  console.log(interviewDetail, "interviewer", interviewData);

  const handleCreateEvent = async () => {
    const validationErrors = {};

    if (!interviewRounds.selectedRound.trim()) {
      validationErrors.selectedRound = "Round selection is required";
    }

    if (!interviewRounds.roundDetails.trim()) {
      validationErrors.roundDetails = "Round details are required";
    }

    if (!date) {
      validationErrors.date = "Date is required";
    }

    if (!time.TimeFrom) {
      validationErrors.startTime = "Start time is required";
    }

    if (roundType.length === 0) {
      validationErrors.interviewType = "Interview type is required";
    }

    if (!interviewDuration) {
      validationErrors.interviewDuration = "Interview duration is required";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setloading(true);
    const event = {
      eventTitle: `${interviewRounds.roundDetails}`,
      eventDescription: `${message}\nDuration: ${interviewDuration} mins\nInterview Type: ${roundType}`,
      companyId: companyId,
      role: companyDetail.role,
      createdBy: userName,
      jobId: job_id,
      talentAttendees: {
        id: candidateId ? candidateId : interviewData?.talentAttendees[0]?.id,
        email: candidateEmail
          ? candidateEmail
          : interviewData?.talentAttendees[0]?.email,
        name: candidateName
          ? candidateName
          : interviewData?.talentAttendees[0]?.name,
      },
      customerAttendees: {
        email: interviewDetail.interviewer,
        name: companyDetail.company,
      },
      talentPartnerAttendees: talentPartnerAttendees,
      pod_group: podGroup ? podGroup : "",
      scheduledDate: date,
      duration: interviewDuration,
      startTime: time.TimeFrom,
      endTime: time.TimeTill,
      interviewType: roundType,
      interviewRound: interviewRounds.selectedRound,
      interviewStatus: "Scheduled",
      jobId: job_id,
      applicationId: appId
    };

    console.log(event, "event details");
    try {
      if (isEditable && interviewData) {
        const response = await axios.post(
          `/api/schedule/updateEvent/${interviewData._id}`,
          event
        );
        console.log("Event successfully created", response.data);
      } else {
        const response = await axios.post("/api/schedule/saveEvent", event);
        dispatch(moveCandidate(job_id, appId, "interviewed"));
        console.log("Event successfully created", response.data);
      }

      const eventDetails = {
        start: formattedStartTime,
        end: formattedEndTime,
        // dates: `${formattedStartTime}/${formattedEndTime}`,
        title: `${interviewRounds.selectedRound}, ${
          candidateName
            ? candidateName
            : interviewData?.talentAttendees[0]?.name
        }, ${companyDetail.role ? companyDetail.role : interviewData?.role}(${
          companyDetail.company
            ? companyDetail.company
            : interviewData.customerAttendees[0]?.name
        })`,
        details: `${interviewRounds.roundDetails}\n\nDuration: ${interviewDuration} mins\n\nInterview Type: ${roundType}\n\nRound Detail: ${message}`,
        location: jobLocation,
        add: attendees,
        ctz: userTimeZone,
      };

      // eventDetails.dates = encodeURIComponent(`${eventDetails.start}/${eventDetails.end}`);

      console.log("Event Details:", eventDetails, event);

      const calendarUrl = googleCalendarEventUrl(eventDetails);

      window.open(calendarUrl, "_blank");

      setErrors({
        selectedRound: "",
        roundDetails: "",
        date: "",
        startTime: "",
        endTime: "",
        interviewType: "",
        interviewDuration: "",
      });

      close();
    } catch (error) {
      console.log(error, "error");
    } finally {
      setloading(false);
      if (isEditable && interviewData) {
        dispatch(getInterviewList());
      }
    }
  };

  return (
    <Modal open={open} onClose={close} closeIcon>
      <Modal.Header>Setup Interview</Modal.Header>

      <Modal.Content>
        <Form>
          <Grid>
            <Grid.Row>
              <Grid.Column computer={6} tablet={8} mobile={16}>
                <Form.Field>
                  <label style={{ fontSize: "1.2rem" }}>Role:</label>
                  <Input
                    type="text"
                    style={{ border: "none" }}
                    name="role"
                    value={companyDetail.role}
                    onChange={handleCompanyDetail}
                  />
                </Form.Field>
              </Grid.Column>

              <Grid.Column computer={6} tablet={8} mobile={16}>
                <Form.Field>
                  <label style={{ fontSize: "1.2rem" }}>Company:</label>
                  <Input
                    type="text"
                    style={{ border: "none" }}
                    name="company"
                    value={companyDetail.company}
                    onChange={handleCompanyDetail}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column computer={6} tablet={8} mobile={16}>
                <Form.Field>
                  <label style={{ fontSize: "1.2rem" }}>Candidate:</label>
                  <Input
                    type="text"
                    style={{ border: "none" }}
                    name="candidateName"
                    value={name}
                    onChange={handleNameChange}
                  />
                </Form.Field>
              </Grid.Column>

              <Grid.Column computer={8} tablet={8} mobile={16}>
                <Form.Field>
                  <label style={{ fontSize: "1.2rem" }}>Interviewer:</label>
                  <CreatableSelect
                    isMulti
                    value={interviewerOptions}
                    onChange={handleInterviewerChange}
                    styles={{
                      container: (provided) => ({
                        ...provided,
                        width: "100%",
                      }),
                    }}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>

            <Grid.Column computer={14} tablet={16} mobile={16}>
              <Form.Field>
                <label style={{ fontSize: "1.2rem" }}>Interview Invites:</label>
                <CreatableSelect
                  isMulti
                  value={interviewDetail.interviewInvites}
                  options={allUserOptions}
                  onChange={handleInterviewerInvitesChange}
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      width: "100%",
                    }),
                  }}
                />
              </Form.Field>
            </Grid.Column>

            <label
              style={{
                fontSize: "1.2rem",
                fontWeight: "bold",
                marginBottom: "-10px",
              }}
            >
              Interview Details:
            </label>

            <Grid.Row>
              <Grid.Column computer={6} tablet={8} mobile={16}>
                <Form.Field>
                  <Dropdown
                    options={roundsOptions}
                    value={interviewRounds.selectedRound}
                    placeholder="select a round"
                    onChange={handleSelectedRound}
                    selection
                    fluid
                  />
                </Form.Field>
                {errors.selectedRound && (
                  <p style={{ color: "red" }}>{errors.selectedRound}</p>
                )}
              </Grid.Column>

              <Grid.Column computer={8} tablet={8} mobile={16}>
                <Form.Field>
                  <Input
                    type="text"
                    value={interviewRounds.roundDetails}
                    onChange={handleRoundDetail}
                    placeholder="Enter round description"
                    fluid
                    style={{ border: "none" }}
                  />
                </Form.Field>
                {errors.roundDetails && (
                  <p style={{ color: "red" }}>{errors.roundDetails}</p>
                )}
              </Grid.Column>
            </Grid.Row>

            <div className="interDuration">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
                className="interType"
              >
                <label style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                  Interview Type:
                </label>
                <div>
                  {interviewType.map((option) => (
                    <Button
                      key={option}
                      onClick={() => handleInterviewType(option)}
                      style={{
                        background: roundType?.includes(option)
                          ? "transparent"
                          : "",
                        border: roundType?.includes(option)
                          ? "1px solid #21C8AA"
                          : "",
                        color: roundType?.includes(option) ? "#21C8AA" : "",
                        marginRight: "9px",
                      }}
                    >
                      {option}
                    </Button>
                  ))}
                </div>
                {errors.interviewType && (
                  <p style={{ color: "red" }}>{errors.interviewType}</p>
                )}
              </div>

              <div
                style={{ display: "flex", flexDirection: "column" }}
                className="duration"
              >
                <label style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                  Duration:
                </label>

                <Dropdown
                  value={interviewDuration}
                  placeholder="select duration"
                  onChange={handleInterviewDuration}
                  selection
                  options={durationOptions}
                />
              </div>
            </div>

            <div
              style={{ marginTop: "1rem", width: "100%" }}
              className="timeDuration"
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                  Date:
                </label>
                <div className="datePicker">
                  <Input
                    type="date"
                    value={date}
                    onChange={handleDatePicker}
                    style={{ border: "none", width: "304px" }}
                  />
                  {errors.date && <p style={{ color: "red" }}>{errors.date}</p>}
                </div>
              </div>

              <div
                style={{ display: "flex", flexDirection: "column" }}
                className="duration"
              >
                <label style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                  Time:
                </label>

                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <Input
                    type="time"
                    value={time.TimeFrom}
                    onChange={handleFromTime}
                    style={{ border: "none" }}
                  />
                  <p style={{ margin: "0" }}>To</p>
                  <Input
                    type="time"
                    value={time.TimeTill}
                    onChange={handleTillTime}
                    style={{ border: "none" }}
                  />
                </div>

                {errors.startTime && (
                  <p style={{ color: "red" }}>{errors.startTime}</p>
                )}
              </div>
            </div>

            <div style={{ width: "100%", margin: "1rem 0 0" }}>
              <label style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                Message
              </label>

              <TextArea
                value={message}
                onChange={handleMessage}
                rows={6}
                placeholder="Enter description...."
              />
            </div>
          </Grid>
        </Form>
      </Modal.Content>

      <Modal.Actions>
        <Button
          style={{ background: "#B3B3B3", color: "#fff" }}
          onClick={close}
        >
          Close
        </Button>
        <Button
          style={{ background: "#21C8AA", color: "#fff" }}
          onClick={handleCreateEvent}
        >
          {loading ? "Setting up Interview" : "Setup Interview"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default SetupInterviewModal;
