import React, { useEffect, useState } from 'react';

const RetryAfterPopup = ({ retryAfter, onClose }) => {
  const [secondsRemaining, setSecondsRemaining] = useState(retryAfter);

  useEffect(() => {
    if (secondsRemaining > 0) {
      const timer = setTimeout(() => {
        setSecondsRemaining(prev => prev - 1);
      }, 1000);

      return () => clearTimeout(timer);  // Cleanup the timer
    } else {
      window.location.reload();  // Refresh the page when the timer hits 0
    }
  }, [secondsRemaining]);

  return (
    <div style={styles.overlay}>
      <div style={styles.popup}>
        <h2>Rate Limit Exceeded</h2>
        <p>You have been rate-limited. Please wait {secondsRemaining} seconds before retrying.</p>
        {/* <button onClick={onClose}>Close</button> */}
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  popup: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    width: '300px',
    textAlign: 'center',
  },
};

export default RetryAfterPopup;
