import React from 'react'
import { Popup, Button, Header, Image, Modal } from 'semantic-ui-react'
import {NavLink} from 'react-router-dom'

class AccountSetUpCheck extends React.Component {


  render () {
    return (
      <Modal dimmer="blurring" open="true" size="tiny" onClose={this.close}>
        <Modal.Header><center>Account setup</center></Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <Header><center>Welcome to Hiresocial!</center></Header>
            <p><center>Please setup your Company to proceed further.</center></p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <NavLink to="/company-setup"><Button positive icon='checkmark' labelPosition='right' content="Setup Company" onClick={this.close} /></NavLink>
        </Modal.Actions>
      </Modal>
    )
  }
}


export default AccountSetUpCheck;
