import React from "react";
import { Label } from "semantic-ui-react";

const SkillItem = ({ skill }) => {
  return (
    <Label color="green" style={{ margin: "5px", background: "#21c8aa" }}>
      {skill.value}
    </Label>
  );
};

export default SkillItem;
