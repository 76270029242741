import React, { useEffect, useState } from 'react'
import axiosInstance, { setupAxiosInterceptors } from '../api/axiosInstance';  // Import Axios and the setup function
import RetryAfterPopup from './RetryAfterPopup';

export default function tooManyRequestModal({ children }) {
    const [retryAfter, setRetryAfter] = useState(null);  // Track the retry time
    const [showPopup, setShowPopup] = useState(false);  // Control the popup visibility
  
    // Function to trigger the popup
    const triggerPopup = (retryAfterSeconds) => {
      setRetryAfter(retryAfterSeconds);  // Set the retry-after value
      setShowPopup(true);  // Show the popup
    };
  
    // Close the popup manually (optional)
    const handleClosePopup = () => {
      setShowPopup(false);
    };
  
    // Set up the Axios interceptor when the component mounts
    useEffect(() => {
      setupAxiosInterceptors(triggerPopup);
    }, []);
  
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get('/your-endpoint');
        console.log('Data:', response.data);
      } catch (error) {
        console.error('API error:', error);
      }
    };
  
    return (
      <div>
        {showPopup && (
          <RetryAfterPopup retryAfter={retryAfter} onClose={handleClosePopup} />
        )}
        {
            !showPopup && children
        }
      </div>
    );
  };
