import React, { useEffect, useState } from "react";
import {
  Card,
  Input,
  Icon,
  CardContent,
  Feed,
  Image,
  Button,
  Header,
} from "semantic-ui-react";
import Pramod from "./pramod.jpg";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getAllchat } from "../../actions/action_application";

const CustomerConversation = ({
  handleChatSelection,
  selectedChat,
  randomCol,
}) => {
  const [data, setData] = useState();
  const email = useSelector((state) => state.user.user.email);
  const AllUser = useSelector((state) => state.application.userData);

  // const [randomColor, setRandomColor] = useState([]);

  const [filteredProducts, setFilteredProducts] = useState();
  const [searchVal, setSearchVal] = useState("");
  const styles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50px",
    width: "50px",
    // background: randomColor,
    color: "white",
    margin: "0px 15px 0px 0px ",
  };
  const userRole = useSelector((state) => state.user.user);
  const chatsss = useSelector((state) => state.application.userChats);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllchat(email));
  }, [email]);

  // const colorgenerator = () => {
  //   return "#" + Math.floor(Math.random() * 16777215).toString(16);
  // };

  // useEffect(() => {
  //   const colors = filteredProducts?.map(() => colorgenerator());
  //   setRandomColor(colors);
  // }, [filteredProducts]);

  const handleSearchClick = () => {
    const filteredContacts = AllUser?.contacts.filter((user) =>
      user.name.toLowerCase().includes(searchVal.toLowerCase())
    );
    setFilteredProducts(filteredContacts);
  };
  useEffect(() => {
    handleSearchClick();
  }, [searchVal, AllUser]);

  return (
    <div>
      <Input
        onChange={(e) => setSearchVal(e.target.value)}
        fluid
        icon="search"
        iconPosition="left"
        placeholder="Search conversation"
      />

      <div style={{ overflowY: "scroll", height: "80vh", marginTop: "20px" }}>
        {filteredProducts?.length === 0 ? (
          <>
            <p style={{ textAlign: "center", color: "grey" }}>
              You have no conversation yet
            </p>
            <p style={{ textAlign: "center" }}>
              Start a talent conversation straight from the talent card!
            </p>
          </>
        ) : (
          <>
            {filteredProducts?.map((userName, i) => (
              <Card
                key={i}
                id="conversation-box"
                fluid
                className={
                  selectedChat?.email == userName?.email ? "conversation" : null
                }
                onClick={() => handleChatSelection(userName)}
              >
                <CardContent
                  style={{
                    marginBottom: "0.5em",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* <Image floated="left" size="mini" src={Pramod} circular /> */}
                  <Image
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "50px",
                      width: "50px",
                      background:
                        randomCol?.length > 0 ? randomCol[i].color : "grey",
                      color: "white",
                      margin: "0px 15px 0px 0px ",
                    }}
                    floated="middle"
                    size="mini"
                    circular
                  >
                    <Header as="h1" style={{ color: "white" }}>
                      {userName.name.charAt(0)}
                    </Header>
                  </Image>

                  <Card.Header>{userName.name}</Card.Header>
                  {/* <Card.Meta>Talent</Card.Meta> */}
                </CardContent>
              </Card>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default CustomerConversation;
