import {CURRENT_COMPANY,SET_BASIC_COMPANY_ID, GET_COMPANY_DETAIL, SET_TOTAL_NUMBERS_OF_JOBS, ADD_TEAM_MEMBER, SET_TEAM_MEMBERS,
DELETE_MEMBER,
EDIT_MEMBER

} from './../types'
import api from './../api/company_api'
import FormData from 'form-data'
import axios from 'axios'

export function currentCompany(data){
  return {
    type: CURRENT_COMPANY,
    data
  }
}

export function setBasicCompanyIdD(data) {
  return {
    type: SET_BASIC_COMPANY_ID,
    data
  }
}

export function getCompanyDetail(data){
  return {
    type: GET_COMPANY_DETAIL,
    data
  }
}

export function addTeamMeber(data) {
  return {
    type: ADD_TEAM_MEMBER,
    data
  }
}

export function setTeamMembers(data) {
  return {
    type: SET_TEAM_MEMBERS,
    data
  }
}


// saveCulture
export const submit = data => dispatch => {

  console.log('data', data)

  const fd = new FormData();

  fd.append('company_name', data.company_name)
  fd.append('company_url', data.company_subdomain)
  fd.append('company_description', data.company_description)
  fd.append('company_logo', data.company_logo_file)
  fd.append('company_header', data.company_header_file)
  fd.append('user_id', data.userId)

console.log("AIOS __ SUBMIT ",data.userId);


  return new Promise((resolve, reject) => {

    return axios.post(`/api/company/create/${data.userId}`,fd)
      .then(
        response => {
          console.log('89898989', response)
          dispatch(setBasicCompanyIdD(response.data))
          resolve(response)
        }, error => {
          console.log(error)
          reject(error)
        }
      )
  })

}


export const saveCulture = (data, id) => dispatch => {
  return api.company.update(data, id).then(data => {
    return dispatch(currentCompany(data))
  })
}

export const setBasicCompanyId = (data) => dispatch => {
  // console.log('setting company',data)
  return dispatch(setBasicCompanyIdD(data))
}


//get whole company detail for company set up page
export const getCompanyData = (id) => dispatch => {
  return api.company.getCompanyApi(id).then(data => {
    console.log('action data', data)
    return dispatch(getCompanyDetail(data))
  })
}

export const BasicCompanyInfo = (data) => dispatch => {
  console.log('set basic comapny', data)
  // return dispatch(setBasicCompany(data))
}

// Adding team member
export const AddMember = (companyId, data) => dispatch => {
  return api.company.AddMemberApi(companyId, data).then(data => {
    return dispatch(addTeamMeber(data))
  })
}

export const getTeamMmbers = (id) => dispatch => {
  return api.company.getTeamMmbersApi(id).then(data => {
    return dispatch(setTeamMembers(data))
  })
}

export const deleteMember = (id) => dispatch => {
  return api.company.deleteMemberApi(id).then(data => {
    return dispatch({
      type : DELETE_MEMBER,
      data
    })
  })
}

export const editMember = (id, data) => dispatch => {
  console.log("id and data from edit member : ", id, data)
  return api.company.editMemberApi(id, data).then(data => {
    return dispatch({
      type : EDIT_MEMBER,
      data
    })
  })
}
