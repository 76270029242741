import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Form,
  Input,
  Button,
  Dropdown,
  Grid,
  Segment,
} from "semantic-ui-react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import InlineError from "./../../messages/InlineError";
import { updateCurrentJob } from "./../../actions/action_job";
import LocationDropdown from "../../customhooks/locationDropdown";

// import AutoComplete from "react-places-autocomplete";
import "./editor.css";
import { CurrencyType, skills } from "../../OptionData";
import "../../App.css";

class JobPosting extends Component {
  state = {
    data: {
      employment_type: "",
      industry: "",
      functional_area: "",
      location: "",
      min_experience: "",
      max_experience: "",
      currentCurrencyType: "",
      min_salary: "",
      position: "",
      max_salary: "",
      skills: [],
      job_id: this.props.job_id,
    },
    errors: "",
    loading: false,
    multi: false,
    multi1: true,
    multiValue: [],
    options: [
      { value: "Full Time", label: "Full Time" },
      { value: "Part Time", label: "Part Time" },
      { value: "Intern", label: "Intern" },
      { value: "Contract", label: "Contract" },
      { value: "Freelance", label: "Freelance" },
    ],
    options2: [
      { value: "Accounting", label: "Accounting" },
      { value: "Banking", label: "Banking" },
      { value: "Biotechnology", label: "Biotechnology" },
      { value: "Education", label: "Education" },
      { value: "Finance", label: "Finance" },
      { value: "Food & Beverage", label: "Food & Beverage" },
      { value: "Gas & Oil", label: "Gas & Oil" },
      { value: "Health", label: "Health" },
      { value: "Insurance", label: "Insurance" },
      { value: "Internet", label: "Internet" },
      { value: "Manufacturing", label: "Manufacturing" },
      { value: "Pharmaceuticals", label: "Pharmaceuticals" },
      { value: "Real estate", label: "Real estate" },
      { value: "Sport", label: "Sport" },
      // { value: 'education', label: 'Education' },
      { value: "Other", label: "Other" },
      // { value: 'education', label: 'Education' },
      // { value: 'education', label: 'Education' },
      // { value: 'education', label: 'Education' },
      // { value: 'education', label: 'Education' },
      // { value: 'education', label: 'Education' },
      // { value: 'education', label: 'Education' },
      // { value: 'education', label: 'Education' },
    ],
    options3: [
      { value: "Accounting/Auditing", label: "Accounting/Auditing" },
      { value: "Administrative", label: "Administrative" },
      { value: "Advertising", label: "Advertising" },
      { value: "Art/Creative", label: "Art/Creative" },
      { value: "Business Development", label: "Business Development" },
      { value: "Customer Service", label: "Customer Service" },
      { value: "Design", label: "Design" },
      { value: "Education", label: "Education" },
      { value: "Engineering", label: "Engineering" },
      { value: "Health Care Provider", label: "Health Care Provider" },
      { value: "Human Resources", label: "Human Resources" },
      { value: "Information Technology", label: "Information Technology" },
      { value: "Legal", label: "Legal" },
      { value: "Manufacturing", label: "Manufacturing" },
      { value: "Product Management", label: "Product Management" },
      { value: "Project Management", label: "Project Management" },
      { value: "Sales", label: "Sales" },
      { value: "Strategy/Planning", label: "Strategy/Planning" },
      { value: "Writing/Editing", label: "Writing/Editing" },
      { value: "Other", label: "Other" },
    ],
    options4: [
      { value: "0", label: "0" },
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "5", label: "5" },
      { value: "6", label: "6" },
      { value: "7", label: "7" },
      { value: "8", label: "8" },
      { value: "9", label: "9" },
      { value: "10", label: "10" },
      { value: "11", label: "11" },
      { value: "12", label: "12" },
      { value: "13", label: "13" },
      { value: "14", label: "14" },
      { value: "15", label: "15" },
      { value: "17", label: "17" },
      { value: "20", label: "20" },
      { value: "25", label: "25" },
      { value: "30", label: "30" },
      { value: "35", label: "35" },
    ],
    postionsArr: [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "5", label: "5 +" },
    ],
    value: "",
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  onChange1 = (value) => {
    console.log("sddddddddddd", value);
    if (value === null) {
      this.setState(
        {
          data: {
            ...this.state.data,
            employment_type: "",
            job_id: this.props.job_id,
          },
        },
        () => console.log(this.state.data)
      );
    }
    if (value) {
      this.setState(
        {
          data: {
            ...this.state.data,
            employment_type: value,
            job_id: this.props.job_id,
          },
        },
        () => console.log(this.state.data)
      );
    }
  };

  onChange2 = (value) => {
    if (value === null) {
      this.setState(
        {
          data: { ...this.state.data, industry: "", job_id: this.props.job_id },
        },
        () => console.log(this.state.data)
      );
    }

    if (value) {
      this.setState(
        {
          data: {
            ...this.state.data,
            industry: value,
            job_id: this.props.job_id,
          },
        },
        () => console.log(this.state.data)
      );
    }
  };

  onChange3 = (value) => {
    if (value === null) {
      this.setState(
        {
          data: {
            ...this.state.data,
            functional_area: "",
            job_id: this.props.job_id,
          },
        },
        () => console.log(this.state.data)
      );
    }
    console.log("sddddddddddd", value);
    if (value) {
      this.setState(
        {
          data: {
            ...this.state.data,
            functional_area: value,
            job_id: this.props.job_id,
          },
        },
        () => console.log(this.state.data)
      );
    }
  };

  onChange4 = (value) => {
    if (value || value === "") {
      this.setState(
        {
          data: {
            ...this.state.data,
            location: value.value,
            job_id: this.props.job_id,
          },
        },
        () => console.log(this.state.data)
      );
    }
  };

  onChange5 = (value) => {
    if (value) {
      this.setState(
        {
          data: {
            ...this.state.data,
            min_experience: value,
            job_id: this.props.job_id,
          },
        },
        () => console.log(this.state.data)
      );
    }
  };
  onChange6 = (value) => {
    if (value) {
      this.setState(
        {
          data: {
            ...this.state.data,
            max_experience: value,
            job_id: this.props.job_id,
          },
        },
        () => console.log(this.state.data)
      );
    }
  };
  onChange7 = (value) => {
    if (value) {
      this.setState(
        {
          data: {
            ...this.state.data,
            min_salary: value,
            job_id: this.props.job_id,
          },
        },
        () => console.log(this.state.data)
      );
    }
  };
  onChange8 = (value) => {
    if (value) {
      this.setState(
        {
          data: {
            ...this.state.data,
            max_salary: value,
            job_id: this.props.job_id,
          },
        },
        () => console.log(this.state.data)
      );
    }
  };

  onChangePosition = (value) => {
    if (value) {
      this.setState(
        {
          data: {
            ...this.state.data,
            position: value,
            job_id: this.props.job_id,
          },
        },
        () => console.log(this.state.data)
      );
    }
  };

  onChangeSkills = (value) => {
    this.setState({
      data: {
        ...this.state.data,
        skills: value,
      },
    });
  };

  submitJobPostingDet = (data) => {
    const errors = this.validate(this.state.data);
    this.setState({ ...this.state, errors: errors });
    console.log("error field validation while submitting", this.state);
    if (Object.keys(errors).length === 0) {
      console.log(this.state.data);
      this.setState({ loading: true });
      this.props.updateCurrentJob(this.state.data).then((res) => {
        if (res) {
          this.props.JobPublishRedirect(res.data._id);
        }
      });
    }
  };

  componentDidMount(props) {
    console.log("componentDidMount job posting detail", this.props);
    if (Object.keys(this.props.current_job).length > 0) {
      this.setState({
        data: {
          ...this.state.data,
          employment_type: {
            value: this.props.current_job.employment_type,
            label: this.props.current_job.employment_type,
          },
          industry: this.props.current_job.industry,
          functional_area: this.props.current_job.functional_area,
          location: this.props.current_job.job_location,
          min_experience: this.props.current_job.min_experience,
          max_experience: this.props.current_job.max_experience,
          min_salary: this.props.current_job.min_salary,
          max_salary: this.props.current_job.max_salary,
          skills: this.props.current_job.skills,
          position: this.props.current_job.position,
          currentCurrencyType: this.props.current_job.currencyType,
          job_id: this.props.job_id,
        },
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log("job posting details - ", nextProps);
    if (Object.keys(nextProps.current_job).length > 0) {
      this.setState({
        data: {
          ...this.state.data,
          employment_type: {
            value: nextProps.current_job.employment_type,
            label: nextProps.current_job.employment_type,
          },
          industry: {
            value: nextProps.current_job.industry,
            label: nextProps.current_job.industry,
          },
          functional_area: {
            value: nextProps.current_job.functional_area,
            label: nextProps.current_job.functional_area,
          },
          location: nextProps.current_job.job_location,
          min_experience: nextProps.current_job.min_experience,
          max_experience: nextProps.current_job.max_experience,
          min_salary: nextProps.current_job.min_salary,
          max_salary: nextProps.current_job.max_salary,
          skills: nextProps.current_job.skills,
          position: nextProps.current_job.position,
          currentCurrencyType: nextProps.current_job.currencyType,
          job_id: nextProps.job_id,
        },
      });
    }
  }

  validate = (data) => {
    const errors = {};
    console.log("data to validate error", data);
    if (!data.employment_type)
      errors.employment_type = "Employment type is required";
    if (!data.industry) errors.industry = "Industry is required";
    if (!data.functional_area)
      errors.functional_area = "Functional area is required";
    if (!data.location) errors.location = "Location is required";
    if (!data.min_experience)
      errors.min_experience = "Minimum experience is required";
    if (!data.max_experience)
      errors.max_experience = "Maximum experience is required";
    if (!data.min_salary) errors.min_salary = "Minimum experience is required";
    if (!data.max_salary) errors.max_salary = "Maximum experience is required";
    if (data.position < 1 || !data.position)
      errors.position = "Position is required";
    if (data.min_salary || data.max_salary) {
      if (!data.currentCurrencyType) {
        errors.currentCurrencyType = "Required";
      }
    }
    // if(data.min_experience > data.max_experience) {
    //   errors.min_experience = "Minimum experience should not be greater than maximum experience"
    //   errors.max_experience = "Minimum experience should not be greater than maximum experience"
    // }
    if (data.min_salary > data.max_salary) {
      errors.min_salary =
        "Minimum salary should not be greater than maximum salary";
      errors.max_salary =
        "Minimum salary should not be greater than maximum salary";
    }

    console.log("error in validate", errors);

    return errors;
  };

  handleChangeCurrency = (e, { value }) => {
    e.preventDefault();
    this.setState({
      data: {
        ...this.state.data,
        currentCurrencyType: value,
      },
    });
  };

  render() {
    const {
      data,
      errors,
      multi,
      multi1,
      multiValue,
      options,
      options2,
      options3,
      options4,
      postionsArr,
      value,
      loading,
    } = this.state;

    console.log("0000000000000000000", data);
    const optionssa = [
      { key: 1, text: "INR", value: 1, icon: "rupee" },
      { key: 2, text: "USD", value: 2, icon: "usd" },
      { key: 3, text: "EUROS", value: 3, icon: "euro" },
      { key: 4, text: "POUNDS", value: 4, icon: "pound" },
      { key: 4, text: "AED", value: 5 },

    ];

    let relativeInput;
    if (data.currentCurrencyType === 1) {
      relativeInput = <span>Lakhs</span>;
    }
    if (data.currentCurrencyType === 2) {
      relativeInput = <span>,000</span>;
    }
    if (data.currentCurrencyType === 3) {
      relativeInput = <span>,000</span>;
    }
    if (data.currentCurrencyType > 3) {
      relativeInput = <span>,000</span>;
    }

    const inputProps = {
      name: "location",
      placeholder: "Pune, India",
      onChange: this.onChange4,
      value: data.location,
    };
    return (
      <div className="job_posting_form">
        <Segment attached="top" id="headerbackground">
          <h4>Job Posting Details</h4>
        </Segment>

        <Segment attached>
          <Form onSubmit={this.submitJobPostingDet} loading={loading}>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Employment type : </label>
                <Select
                  options={options}
                  onChange={this.onChange1}
                  value={data.employment_type}
                />
                {errors.employment_type && (
                  <InlineError text={errors.employment_type} />
                )}
              </Form.Field>
              <Form.Field>
                <label>Industry : </label>
                <Select
                  options={options2}
                  name="industry"
                  onChange={this.onChange2}
                  value={data.industry}
                />
                {errors.industry && <InlineError text={errors.industry} />}
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Min Experience (years) : </label>
                <Input
                  type="number"
                  name="min_experience"
                  min="0"
                  value={data.min_experience}
                  onChange={this.handleChange}
                />
                {errors.min_experience && (
                  <InlineError text={errors.min_experience} />
                )}
              </Form.Field>
              <Form.Field>
                <label>Max Experience (years) : </label>
                <Input
                  type="number"
                  name="max_experience"
                  min={data.min_experience}
                  value={data.max_experience}
                  onChange={this.handleChange}
                />
                {errors.max_experience && (
                  <InlineError text={errors.max_experience} />
                )}
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={6}>
                      <Form.Field>
                        <label>
                          Currency
                          {!data.currentCurrencyType && "Currency"}
                        </label>
                        <Dropdown
                          onChange={this.handleChangeCurrency}
                          options={CurrencyType}
                          value={data.currentCurrencyType}
                          selection
                        />
                        {errors.currentCurrencyType && (
                          <InlineError text={errors.currentCurrencyType} />
                        )}
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={10}>
                      <Form.Field>
                        <label>Min Salary :</label>
                        <Input
                          name="min_salary"
                          min="0"
                          type="number"
                          onChange={this.handleChange}
                          value={data.min_salary}
                        />
                        {errors.min_salary && (
                          <InlineError text={errors.min_salary} />
                        )}
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>
              <Form.Field>
                <label>Max Salary : </label>
                <Input
                  name="max_salary"
                  type="number"
                  min={data.min_salary}
                  onChange={this.handleChange}
                  value={data.max_salary}
                />
                {errors.max_salary && <InlineError text={errors.max_salary} />}
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <label>Skills: </label>
              <CreatableSelect
                isMulti
                name="skills"
                options={skills}
                onChange={this.onChangeSkills}
                value={data.skills}
              />
            </Form.Field>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Functional area : </label>
                <Select
                  name="functional_area"
                  onChange={this.onChange3}
                  value={data.functional_area}
                  options={options3}
                />
                {errors.functional_area && (
                  <InlineError text={errors.functional_area} />
                )}
              </Form.Field>
              <Form.Field>
                <label>Position : </label>
                <Input
                  type="number"
                  min="0"
                  name="position"
                  onChange={this.handleChange}
                  value={data.position}
                />
                {errors.position && <InlineError text={errors.position} />}
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <label>Location : </label>
              <LocationDropdown onChangeLocation={this.onChange4} />
              {errors.location && <InlineError text={errors.location} />}
            </Form.Field>
            {/* <Button.Group> */}
            <div>
              <Button
                default
                onClick={() =>
                  this.props.goToJobTitleDetail(this.props.current_job._id)
                }
              >
                Previous
              </Button>
              {/* <Button type="submit" positive>Save and Continue</Button> */}
              <Button
                default
                type="submit"
                floated="right"
                style={{ background: "#21c8aa" }}
                positive
              >
                Next
              </Button>
            </div>
            {/* </Button.Group> */}
          </Form>
        </Segment>
      </div>
    );
  }
}

JobPosting.propTypes = {
  job_id: PropTypes.string.isRequired,
  updateCurrentJob: PropTypes.func.isRequired,
  current_job: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    job_id: state.job.current_job._id,
    current_job: state.job.current_job,
  };
}

export default connect(mapStateToProps, { updateCurrentJob })(JobPosting);
