import {SET_CAREER_BRANDING,SET_CAREER_JOBS, SET_CAREER_JOB_DETAIL} from './../types.js'

export default function career (state = {branding : {}, jobs : [], job_detail : {}}, action = {}){
  switch(action.type){
    case SET_CAREER_BRANDING:
      return Object.assign({}, state, {branding : action.data});

    case SET_CAREER_JOBS:
      return {...state , jobs : action.data};

    case SET_CAREER_JOB_DETAIL:
      return {...state, job_detail : action.data}

    default:
        return state;
  }
}
