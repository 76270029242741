import axios from './axiosInstance'
import {getHeader} from './../types.js'

export default {
  client : {
    create : (data, company_id) =>
            axios.post(`/api/client/create/${company_id}`, data, { headers: getHeader()}).then(res => res.data),
    update : (data, id) =>
            axios.put(`/api/company/update/${id}`, data, { headers: getHeader()}).then(res => res.data),
    findTotalNumberOfJobsApi: id =>
            axios.get(`/api/job/totalnumbersofjobs/${id}`, { headers: getHeader()}).then(res => res.data),
    getCompanyApi : (id) =>
            axios.get(`/api/company/get/company/detail/${id}`, { headers: getHeader()}).then(res => res.data),
    AddMemberApi: (data) =>
            axios.post('/api/company/member', data, { headers: getHeader()}).then(res => res.data),
    mailCvToClient: (data) =>
            axios.post('/api/client/mailcvtoclient', data, { headers: getHeader()}).then(res => res.data),
    getAllClientApi: (company_id) =>
            axios.get(`/api/client/allClients`, { headers: getHeader()}).then(res => res?.data),
    deleteClientApi: (client_id) =>
            axios.delete(`/api/client/${client_id}`, { headers: getHeader()}).then(res => res.data),
    editClientApi: (client_id, data) =>
            axios.put(`/api/client/${client_id}`, data, { headers: getHeader() }).then(res => res.data),
    getAllPodRoleUsersApi: (company_id) =>
            axios.get(`/api/auth/allUsersPod/${company_id}`, { headers: getHeader()}).then(res => res.data),                
  }
}
///get/client/detail/