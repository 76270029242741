import React, { Component } from "react";
import {
  Form,
  Checkbox,
  Button,
  Container,
  Grid,
  Message,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import Validator from "validator";
import { connect } from "react-redux";
import { signup } from "./../actions/action_auth";
import InlineError from "./../messages/InlineError";
import Navbar from "./../components/navbar/nav.js";
import NewSignupForm from "../components/candidate/Forms/Signup-form-new";

class Signup extends Component {
  state = {
    data: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
    },
    loading: false,
    errors: {},
    success: false,
  };

  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });

  onSubmit = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ ...this.state, loading: true });
      this.props
        .signup(this.state.data)
        .then((res) => {
          if (res) {
            this.setState({
              success: true,
              loading: false,
              errors: {},
              data: {
                first_name: "",
                last_name: "",
                email: "",
                password: "",
              },
            });
          }
        })
        .catch((error) => {
          console.log("----", error.response);
          this.setState({
            errors: {
              email: error.response.data.error,
            },
          });
        });
    }
  };

  validate = (data) => {
    const errors = {};
    if (!data.first_name) errors.first_name = "First name is required!";
    if (!data.last_name) errors.last_name = "Last name is required!";
    if (!Validator.isEmail(data.email)) errors.email = "Invalid email";
    if (!data.password) errors.password = "Password is required!";
    return errors;
  };

  render() {
    const { data, errors, success, loading } = this.state;
    return (
      <div className="half-bg-blue">
        <Navbar />
        <Container loading={loading}>
          <div
            className="signupform"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "6%",
            }}
          >
            {success && (
              <Message positive>
                <Message.Header>Registered successfully.</Message.Header>
                <p>
                  We have sent you a mail, Please click and activate your
                  account
                </p>
              </Message>
            )}
            <NewSignupForm />
          </div>
          <Grid centered columns={2}>
            <br />
            <Grid.Column>
              <br />
              {success && (
                <Message positive>
                  <Message.Header>Registered successfully.</Message.Header>
                  <p>
                    We have sent you a mail, Please click and activate your
                    account
                  </p>
                </Message>
              )}
              {/* <Form onSubmit={this.onSubmit}>
                <Form.Field error={!!errors.first_name}>
                  <label>First Name</label>
                  <input
                    type="text"
                    name="first_name"
                    id="first_name"
                    value={data.first_name}
                    placeholder="First Name"
                    onChange={this.onChange}
                  />
                  {errors.first_name && (
                    <InlineError text={errors.first_name} />
                  )}
                </Form.Field>
                <Form.Field error={!!errors.last_name}>
                  <label>Last Name</label>
                  <input
                    type="text"
                    name="last_name"
                    id="last_name"
                    placeholder="Last Name"
                    value={data.last_name}
                    onChange={this.onChange}
                  />
                  {errors.last_name && <InlineError text={errors.last_name} />}
                </Form.Field>
                <Form.Field error={!!errors.email}>
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    value={data.email}
                    onChange={this.onChange}
                  />
                  {errors.email && <InlineError text={errors.email} />}
                </Form.Field>
                <Form.Field error={!!errors.password}>
                  <label style={{ color: "#003458" }}>Password</label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    value={data.password}
                    onChange={this.onChange}
                  />
                  {errors.password && <InlineError text={errors.password} />}
                </Form.Field>
                <Form.Field>
                  <Checkbox label="I agree to the Terms and Conditions" />
                </Form.Field>
                <Button type="submit" primary>
                  Submit
                </Button>
              </Form> */}
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    );
  }
}

Signup.propTypes = {
  signup: PropTypes.func.isRequired,
};

export default connect(null, { signup })(Signup);
