import api from "../api/options_api";
import { GET_LOCATION_OPTION, GET_ROLE_OPTION, GET_POD_GROUP_OPTION, GET_TAG_OPTION } from "../types";

export const locationOptionAction = () => (dispatch) => {
  let authUser = {};
  // console.log("Action data ",api.user.signin());
  return api.options.locationOptionApi().then((response) => {
    // console.log("option response ", response);
    return dispatch({
      type: GET_LOCATION_OPTION,
      data: response,
    });
  });
};

export const roleOptionAction = () => (dispatch) => {
  return api.options.roleOptionApi().then((response) => {
    // console.log("option response ", response);
    return dispatch({
      type: GET_ROLE_OPTION,
      data: response,
    });
  });
};

export const tagOptionAction = () => (dispatch) => {
  return api.options.tagOptionApi().then((response) => {
    console.log("option response ", response);
    return dispatch({
      type: GET_TAG_OPTION,
      data: response,
    });
  });
};

export const podGroupListAction = (companyId) => (dispatch) => {
  return api.options.podGroupOption(companyId).then((response) => {
    // console.log("option response ", response);
    return dispatch({
      type: GET_POD_GROUP_OPTION,
      data: response,
    });
  });
};