import { useState, useEffect } from "react";
import { Container, Table, Pagination, Button } from "semantic-ui-react";
import moment from "moment";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SetupInterviewModal from "../components/Talent-Card/Modal/SetupInterviewModal";
import { getJobById } from "../actions/action_job";
import { getAllUsers } from "../actions/action_client";
import InterviewDetailModal from "./InterviewDetailModal";

const InterviewList = ({
  interviewList,
  selectedClient,
  selectedDate,
  selectedRole,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const client_id = useSelector((state) => state.user.user.clientId);
  const role = useSelector((state) => state.user.user.role);
  const dispatch = useDispatch();
  const [selectedInterview, setSelectedInterview] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const toScheduleStatus = interviewList?.filter((list) => {
    if (list.interviewStatus === "To be scheduled") {
      return list;
    }
  });

  const isEditable = true;

  const isAdmin = role === "Admin" || role === "Recruiter";

  const clientInterviewList = isAdmin
    ? toScheduleStatus
    : toScheduleStatus?.filter((list) => list.companyId === client_id);

  const filteredClientInterviewList = clientInterviewList?.filter(
    (list) =>
      (!selectedClient || list.customerAttendees[0]?.name === selectedClient) &&
      (!selectedDate ||
        moment(list.scheduledDate).format("YYYY-MM-DD") === selectedDate) &&
      (!selectedRole || list?.role?.includes(selectedRole))
  );

  const currentList = filteredClientInterviewList?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const length = filteredClientInterviewList?.length;

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleOpenModal = (interview) => {
    setSelectedInterview(interview);
    setModalOpen(true);
  };

  const handleDetailModal = (interview) => {
    setSelectedInterview(interview);
    setDetailModal(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setDetailModal(false);
    setSelectedInterview(null);
  };

  useEffect(() => {
    dispatch(getAllUsers(interviewList?.jobId));
  }, [interviewList?.jobId]);

  console.log(selectedInterview, "selectedInterview");

  return (
    // <div>
    <Container>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Created At</Table.HeaderCell>
            <Table.HeaderCell>Candidate Name</Table.HeaderCell>
            <Table.HeaderCell>Role</Table.HeaderCell>
            {isAdmin && <Table.HeaderCell>Customer</Table.HeaderCell>}
            <Table.HeaderCell>Interview Round</Table.HeaderCell>
            {/* {role !== 'client' ? <Table.HeaderCell>Date - Time</Table.HeaderCell> : null} */}
            {/* <Table.HeaderCell>Interview Type</Table.HeaderCell> */}
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Assigned Recruiter</Table.HeaderCell>
            <Table.HeaderCell>Requested by</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            {isAdmin && <Table.HeaderCell></Table.HeaderCell>}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {currentList && currentList.length > 0 ? (
            currentList.map((list) => (
              <Table.Row key={list._id}>
                <Table.Cell>{moment(list.createdAt).format("LL")}</Table.Cell>
                <Table.Cell>
                  {list.jobId ? (
                    <Link
                      to={`/job/${list?.jobId}/candidate/${list.talentAttendees[0].id}/bio`}
                      target="_blank"
                      rel="noopener"
                    >
                      {!list.talentAttendees[0]?.name
                        ? "NA"
                        : list.talentAttendees[0]?.name}
                    </Link>
                  ) : !list.talentAttendees[0]?.name ? (
                    "NA"
                  ) : (
                    list.talentAttendees[0]?.name
                  )}
                </Table.Cell>
                <Table.Cell>{!list.role ? "NA" : list.role}</Table.Cell>
                {isAdmin && (
                  <Table.Cell>
                    {!list.customerAttendees[0].name
                      ? "NA"
                      : list.customerAttendees[0].name}
                  </Table.Cell>
                )}
                <Table.Cell>
                  {!list.interviewRound ? "NA" : list.interviewRound}
                </Table.Cell>
                {/* {role !== 'client' ? <Table.Cell>
                                    {moment(list.scheduledDate).format("LL")} - {list.startTime}
                                </Table.Cell> : null} */}
                {/* <Table.Cell>
                                    {list.interviewType}
                                </Table.Cell> */}
                <Table.Cell>{list.interviewStatus}</Table.Cell>
                <Table.Cell>
                  {list.talentPartnerAttendees?.map((partner) => (
                    <p style={{ marginBottom: "0" }}>{partner.name}</p>
                  ))}
                </Table.Cell>
                <Table.Cell>{list.createdBy}</Table.Cell>

                <Table.Cell>
                  <Button onClick={() => handleDetailModal(list)}>View</Button>
                </Table.Cell>

                {isAdmin && (
                  <Table.Cell>
                    <Button
                      style={{ color: "white", background: "#21C8AA" }}
                      onClick={() => handleOpenModal(list)}
                    >
                      Setup interview 
                    </Button>
                  </Table.Cell>
                )}
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan="7" style={{ textAlign: "center" }}>
                No data available
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="7">
              <Pagination
                totalPages={Math.ceil(length / itemsPerPage)}
                activePage={currentPage}
                onPageChange={(e, { activePage }) => paginate(activePage)}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>

      {modalOpen && (
        <SetupInterviewModal
          open={modalOpen}
          close={handleCloseModal}
          interviewData={selectedInterview}
          isEditable={isEditable}
        />
      )}
      {detailModal && (
        <InterviewDetailModal
          open={detailModal}
          close={handleCloseModal}
          interviewData={selectedInterview}
        />
      )}
    </Container>
    // </div>
  );
};

export default InterviewList;
